import { Form, Switch } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CSSProperties, useEffect, useState } from "react";
import { ExistingCriInstruction, ExistingMedInstruction } from "utils/dataTypes";
import { EnrichedCriSearchOption, MedicineSearchOption } from "utils/types/InstructionOrderTypes";
import { CriEstimate } from "./CriEstimate";
import { MedicineEstimate } from "./MedicineEstimate";
import { ToGoMedEstimate } from "./ToGoMedEstimate";


const formItemStyle: CSSProperties = {
	width: '100%',
};

interface WrapperOrderProps {
	options: MedicineSearchOption;
	existingInstruction?: Partial<ExistingMedInstruction> | Partial<ExistingCriInstruction>;
	patientWeight?: number;
	onFormChange?: Function; //not actually optional -- always sent via FormWrapper
	getFormData?: Function; //not actually optional -- always sent via FormWrapper
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	defaultCri?: boolean
    durationHours?: number;
}

export const MedCriEstimate = (props: WrapperOrderProps) => {
	const { options, defaultCri, durationHours, ...passThroughProps } = props;
	const [isCRI, setIsCRI] = useState(defaultCri ?? false)

    const forcedToGO = options.calculator_type === 'prepackaged';
    const [isToGo, setIsToGo] = useState(forcedToGO)

	const [medForm] = useForm();
	const [criForm] = useForm();
	const CriOptions: EnrichedCriSearchOption = {
		...options,
		fluid: undefined,
		medication: options,
		default_rate_ml_per_hr: null,
		fluids_id: null,
		fluids_volume_ml: 0,
		medication_id: options.id,
		medication_name: options.name,
		type_id: "C"
	}
	const { calculator_type } = options
    const [displayForm, setDisplayForm] = useState(<></>);
    useEffect(() => {
        if (isCRI) {
            setDisplayForm(
                <CriEstimate {...passThroughProps}
                    options={CriOptions}
                    form={criForm}
                />
            )

        } else if (isToGo){
            setDisplayForm(
            <ToGoMedEstimate  {...passThroughProps}
                dataSource={options}
                form={medForm}
            />)

        } else {
            setDisplayForm(
                <MedicineEstimate {...passThroughProps}
                    dataSource={options}
                    form={medForm}
                    medicine={options}
                    durationHours={durationHours}
                    
                />
            )

        }
    }, [isCRI, isToGo])
	return (
		<>
			{calculator_type !== 'simple' && calculator_type !== 'prepackaged' && defaultCri === undefined && (
				<Form.Item
					label={'CRI'}
					style={formItemStyle}
					labelCol={props.labelCol}
					wrapperCol={props.wrapperCol}
					data-cy={'criSwitch'}
				>
					<Switch 
                        checked={isCRI}
						data-cy={'criSwitchButton'}
                        disabled={isToGo || forcedToGO}
                        onChange={(val) => {
                            setIsCRI(val)
                            setIsToGo(false)
                            }} 
                    />
				</Form.Item>
			)}
            	<Form.Item
					label={'To Go Home'}
					style={formItemStyle}
					labelCol={props.labelCol}
					wrapperCol={props.wrapperCol}
					data-cy={'toGoHomeSwitch'}
				>
					<Switch 
                        checked={isToGo}
                        disabled={isCRI || forcedToGO}
						data-cy={'toGoHomeSwitchButton'}
                        onChange={(val) => {
                            setIsCRI(false)
                            setIsToGo(val)
                            }}
                    />
				</Form.Item>

			{
				displayForm
			}
		</>
	)
}