import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, FormInstance, Menu } from 'antd';
import { useAppDispatch } from 'hooks/useRedux';
import moment from 'moment';
import {
	setBonusActionData,
	setInstructionToReschedule,
	setIsBonusActionModalVisible,
} from 'store/slices/treatmentSheetSlice';
import { FormName, INITIAL_CONTEXT_MENU } from 'utils/constants';
import {
	BaseExistingInstruction,
	ExistingCriInstruction,
	ExistingDiagInstruction,
	ExistingFluidInstruction,
	ExistingMedInstruction,
	ExistingOxygenTherapyInstruction,
	ExistingTaskInstruction,
	PimsUser,
	TsGroupType,
	isInstanceOfCRIInstruction,
	isInstanceOfExistingCriInstruction,
	isInstanceOfExistingDiagInstruction,
	isInstanceOfExistingFluidInstruction,
    isInstanceOfExistingOxygenTherapyInstruction,
    isInstanceOfExistingTaskInstruction,
    isInstanceOfMedicineInstruction,
} from 'utils/dataTypes';
import { isDiscontinuedOrOver } from 'utils/filterFuncs';
import { BaseSearchOption } from 'utils/types/InstructionOrderTypes';
import {
	generateAdjustFluidRateModalState,
} from '../generateAdjustFluidRateModalState';
import { generateDiscontinueModalState } from '../generateDiscontinueModalState';
import { getFormConfigurationData } from '../getFormConfigurationData';
import './OrderContextMenu.css';
import { next30Min } from 'utils/formFuncs';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { generateFluidAdditiveModalState } from '../generateFluidAdditiveModalState';
import { useDiscontinueFluidAdditiveMutation } from 'services/instructionService';
import { showNoChargeModal } from 'views/visit/DischargeTab/utils';

interface IOrderContextMenuProps {
	group: TsGroupType;
	modalForm: FormInstance<any>;
	onModalFinish: (values: any, formName: FormName) => void;
	patientWeight: number;
	setContextMenuState: Function;
	setModalState: Function;
	user?: PimsUser;
	baseSearchOption?: BaseSearchOption[];
	updateIsInvoiceLocked: () => boolean;
}

const OrderContextMenu = ({
	group,
	modalForm,
	onModalFinish,
	patientWeight,
	setContextMenuState,
	setModalState,
	user,
	baseSearchOption,
	updateIsInvoiceLocked,
}: IOrderContextMenuProps) => {
	const dispatch = useAppDispatch();
	const {addComposeBox} = useComposeBoxContext();
	const [discontinueFluidAdditive] = useDiscontinueFluidAdditiveMutation();
	const handleDiscontinue = () => {
		setModalState(
			generateDiscontinueModalState(
				group as BaseExistingInstruction,
				user!,
				modalForm,
				onModalFinish,
				setModalState,
			),
		);
	};
	const handleAdjustRate = (group: ExistingFluidInstruction | ExistingCriInstruction | ExistingOxygenTherapyInstruction) => {
		setModalState(
			generateAdjustFluidRateModalState(
				group,
				user!,
				modalForm,
				onModalFinish,
				setModalState,
				patientWeight,
			),
		);
	};
	const showFluidAdditiveModal = (group: ExistingFluidInstruction) => {
		setModalState(
			generateFluidAdditiveModalState(
				group,
				modalForm,
				setModalState,
			),
		);
	};
	const handleAddAnAdditive = (group: ExistingFluidInstruction) => {
		const isInvoiceLocked = updateIsInvoiceLocked();
		const isFluidStarted = group.actions.some((action) => action.status === 'complete');
		if (isFluidStarted && isInvoiceLocked) {
			showNoChargeModal(() => {
				showFluidAdditiveModal(group);
			});
		}
		else {
			showFluidAdditiveModal(group);
		}
	};
	const handleRewriteReorder = () => {
		const now = moment();

		if (isInstanceOfCRIInstruction(group) && group.default_cri_unit === 'mcg/kg/min') {
			group.dose = group.dose / 60 * 1000;
			group.dose_unit = 'mcg/kg/min';
		}

		const { newInstructionForm, newInstructionFormName } =
			getFormConfigurationData({
				group: { ...group, start_time: next30Min(now).unix() },
				baseSearchOption,
				patientWeight,
				modalForm,
			});
			
		addComposeBox({
			formName: newInstructionFormName,
			content: newInstructionForm,
		});
	};
    const handleRescheduleInstruction = (group: ExistingDiagInstruction | ExistingTaskInstruction | ExistingMedInstruction) => {
        dispatch(setInstructionToReschedule(group))
    };

	const showBonusActionOption = () => {
		if ('type_id' in group) {
			return (
				group.type_id === 'M' ||
				group.type_id === 'D' ||
				group.type_id === 'T'
			);
		}
	};

	const allowDiscontinue = ('name' in group && group.name === 'Weight') ? false : true;

	const showBonusActionModal = () => {
		setContextMenuState(INITIAL_CONTEXT_MENU);
		dispatch(setIsBonusActionModalVisible(true));
		dispatch(setBonusActionData(group as any));
	};

	const menu = () => {
		if (isDiscontinuedOrOver(group as BaseExistingInstruction)) {
			return (
				<Menu>
					<Menu.Item
						onClick={(e) => {
							e.domEvent.stopPropagation();
							setContextMenuState(INITIAL_CONTEXT_MENU);
							handleRewriteReorder();
						}}
					>
						Re-order
					</Menu.Item>
				</Menu>
			);
		} else {
			return (
				<Menu>
					{showBonusActionOption() && (
						<Menu.Item
							onClick={(e) => {
								e.domEvent.stopPropagation();
								const isInvoiceLocked = updateIsInvoiceLocked();
								if (isInvoiceLocked) {
									showNoChargeModal(showBonusActionModal);
								} else {
									showBonusActionModal();
								}
							}}
						>
							Add Bonus Action
						</Menu.Item>
					)}
					{group && 
						(isInstanceOfExistingFluidInstruction(group) ||
						isInstanceOfExistingCriInstruction(group) ||
						isInstanceOfExistingOxygenTherapyInstruction(group)) &&
							<Menu.Item
								onClick={(e) => {
									e.domEvent.stopPropagation();
									setContextMenuState(INITIAL_CONTEXT_MENU);
									handleAdjustRate(group);
								}}
							>
								Adjust Rate
							</Menu.Item>	
					}
					{group && isInstanceOfExistingFluidInstruction(group) &&
							<Menu.Item
								onClick={(e) => {
									e.domEvent.stopPropagation();
									setContextMenuState(INITIAL_CONTEXT_MENU);
									handleAddAnAdditive(group);
								}}
							>
								Add an Additive
							</Menu.Item>
					}
					{group && 
						(isInstanceOfExistingFluidInstruction(group) ||
						isInstanceOfExistingCriInstruction(group)) &&
							<Menu.Divider />
					}
					{group && isInstanceOfExistingFluidInstruction(group) &&
							<Menu.SubMenu
								title='Discontinue Additive'
								disabled={group.fluid_additives.filter(item => item.discontinued_at === null).length === 0}
							>
								{group.fluid_additives.filter(item => item.discontinued_at === null).map(additive => {
									const totalAdditivesWithSameId = group.fluid_additives.reduce((total, otherAdditive) => {
										return otherAdditive.additive_id === additive.additive_id ? total + 1 : total
									}, 0);
									return (
										<Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation();
												discontinueFluidAdditive({
													visitId: group.visit_id,
													instructionId: group.id,
													fluidAdditiveId: additive.id,
												});
											}}
										>
											{additive.short_name || additive.name}
											{totalAdditivesWithSameId > 1
												? ' ' + moment.unix(additive.created_at).format('h:mma')
												: ''
											}
										</Menu.Item>
									);
								})}
							</Menu.SubMenu>
					}
                    {group && 
						(
                            isInstanceOfExistingDiagInstruction(group) ||
						    isInstanceOfExistingTaskInstruction(group) ||
                            isInstanceOfMedicineInstruction(group)
                        ) &&
							<Menu.Item
								onClick={(e) => {
									e.domEvent.stopPropagation();
									setContextMenuState(INITIAL_CONTEXT_MENU);
									handleRescheduleInstruction(group);
								}}
							>
								Reschedule
							</Menu.Item>	
					}
					{allowDiscontinue && (
							<>
							<Menu.Item
								onClick={(e) => {
									e.domEvent.stopPropagation();
									setContextMenuState(INITIAL_CONTEXT_MENU);
									handleDiscontinue();
									handleRewriteReorder();
								}}
							>
								Discontinue + Rewrite
							</Menu.Item>
							<Menu.Item
								onClick={(e) => {
									e.domEvent.stopPropagation();
									setContextMenuState(INITIAL_CONTEXT_MENU);
									handleDiscontinue();
								}}
							>
								Discontinue
							</Menu.Item>
							</>
						)
					}
					

				</Menu>
			);
		}
	};

	return (
		<Dropdown
			trigger={['hover']}
			overlay={menu}
			placement='bottomRight'
			className='order-context-menu'
		>
			<Button onClick={(e) => e.stopPropagation()}>
				<EllipsisOutlined key='ellipsis' />
			</Button>
		</Dropdown>
	);
};

export default OrderContextMenu;
