import { Form, FormInstance, Input, Select, Tooltip, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyGetSupplementalByIdQuery } from 'services/supplementalService';
import { HiddenInput } from '../forms/fields/HiddenInput';
import EstimateInstructionFormItem from './EstimateMedicationForm/EstimateInstructionFormItem';
import EstimateStartEndTimeFormItems from './EstimateMedicationForm/EstimateStartEndTimeFormItems';
import { EstimateFormInstance } from './index';
import EditLineButton from '../forms/PackageFormModal/EditLineButton';
import { FluidEstimateItemNew } from 'utils/types/estimateTypesNew';

interface EstimateFluidsFormProps {
    fluid: FluidEstimateItemNew;
    form: FormInstance<EstimateFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const EstimateFluidsForm: React.FC<EstimateFluidsFormProps> = ({
    fluid,
    form,
    index,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [trigger, { data: supplementalData }] = useLazyGetSupplementalByIdQuery();

    const [hasError, setHasError] = useState(false);
    const lineId = `F-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const fluidFields = [
        ['fluids', index, 'volume'],
        ['fluids', index, 'rate'],
        ['fluids', index, 'route_id'],
        ['fluids', index, 'end_time'],
        ['fluids', index, 'start_time'],
        ['fluids', index, 'instruction'],
    ];
    const fluidsErrorArray = form.getFieldsError(fluidFields);

    const handleShouldUpdate = (prev: EstimateFormInstance, curr: EstimateFormInstance) => {
        return (
            prev.fluids[index].start_time !== curr.fluids[index].start_time ||
            prev.fluids[index].end_time !== curr.fluids[index].end_time ||
            prev.fluids[index].rate !== curr.fluids[index].rate ||
            prev.fluids[index].action !== curr.fluids[index].action
        );
    };

    const setFluidsFieldValue = (value: any) => {
        const fieldsValue = form.getFieldsValue();

        if (fieldsValue.fluids) {
            form.setFieldsValue({
                ...fieldsValue,
                fluids: fieldsValue.fluids.map((fluid: any, indx: number) => {
                    return indx === index ? { ...fluid, ...value } : fluid;
                }),
            });
        }
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['fluids', index, 'volume'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (fluidsErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, fluidsErrorArray]);

    useEffect(() => {
        if (fluid.supplemental_id) {
            trigger({ supplemental_id: fluid.supplemental_id });
        }
    }, [fluid.supplemental_id]);

    useEffect(() => {
        if (supplementalData) {
            setFluidsFieldValue({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const fluidRate = getFieldValue(['fluids', index, 'rate']);
                const initialError = !getFieldValue(['fluids', index, 'rate']) || !getFieldValue(['fluids', index, 'volume']);

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing && getFieldValue(['fluids', index, 'action']) === 0) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['fluids', index, 'id']} initialValue={fluid.fluids_id} />
                            <HiddenInput fieldName={['fluids', index, 'name']} initialValue={fluid.name} />
                            <HiddenInput fieldName={['fluids', index, 'supplemental_cost_cents']} initialValue={fluid.supplemental_cost_cents} />
                            <HiddenInput fieldName={['fluids', index, 'is_recurring_supplemental']} initialValue={false} />

                            <HiddenInput fieldName={['fluids', index, 'type_id']} initialValue={fluid.type_id} />
                            <HiddenInput fieldName={['fluids', index, 'instruction_id']} initialValue={fluid.instruction_id} />
                            <HiddenInput fieldName={['fluids', index, 'is_prn']} initialValue={fluid.is_prn} />
                            <HiddenInput fieldName={['fluids', index, 'prn_condition']} initialValue={fluid.prn_condition} />
                            <HiddenInput fieldName={['fluids', index, 'priority']} initialValue={fluid.priority} />
                        </div>

                        <div className='grid-table__sticky-column'>
                            <Tooltip title={fluid.name.length > 45 ? fluid.name : null}>
                                <span className='treatment-sheet-side-column__title'>{fluid.name}</span>
                            </Tooltip>

                            {fluidRate && <div className='instructions-centered-container'>{fluidRate} mL/hr</div>}
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Transfer Options
                        </div>

                        <div className='package-medication-dose__inputs'>
                            <Form.Item name={['fluids', index, 'action']} initialValue={0}>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Transfer</Radio.Button>
                                    <Radio.Button value={1}>Exclude</Radio.Button>
                                    <Radio.Button value={2}>Save for Later</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        {(
                            <>
                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Dose
                                </div>
                                <div className='fluid-dose-container'>
                                    <Form.Item
                                        name={['fluids', index, 'volume']}
                                        initialValue={fluid.fluids_volume_ml}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Cannot be smaller or equal to 0');
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input suffix='mL' type='number' min={0} disabled={getFieldValue(['fluids', index, 'action']) !== 0} />
                                        ) : (
                                            <span>
                                                {getFieldValue(['fluids', index, 'volume'])
                                                    ? `${getFieldValue(['fluids', index, 'volume'])}`
                                                    : '-'}
                                                mL
                                            </span>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        name={['fluids', index, 'rate']}
                                        initialValue={fluid.rate_ml_per_hr}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Cannot be smaller or equal to 0');
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input suffix='mL/hr' type='number' min={0} disabled={getFieldValue(['fluids', index, 'action']) !== 0} />
                                        ) : (
                                            <span>
                                                {getFieldValue(['fluids', index, 'rate'])
                                                    ? `${getFieldValue(['fluids', index, 'rate'])}`
                                                    : '-'}{' '}
                                                mL/hr
                                            </span>
                                        )}
                                    </Form.Item>
                                </div>

                                <div>
                                    <div className='package-modal__mobile-header'>
                                        <span style={{ color: 'var(--veg-red)' }}>* </span>
                                        Route
                                    </div>
                                    <Form.Item name={['fluids', index, 'route_id']} initialValue={fluid.route_id}>
                                        {isEditing ? (
                                            <Select
                                                options={[
                                                    {
                                                        value: 'IV',
                                                        label: 'IV',
                                                    },
                                                    {
                                                        value: 'IO',
                                                        label: 'IO',
                                                    },
                                                ]}
                                                disabled={getFieldValue(['fluids', index, 'action']) !== 0}
                                            />
                                        ) : (
                                            <span>{getFieldValue(['fluids', index, 'route_id'])}</span>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className='empty-element' />


                                <EstimateStartEndTimeFormItems
                                    endTimeFormFieldName={['fluids', index, 'end_time']}
                                    endTimeFormFieldValue={getFieldValue(['fluids', index, 'end_time'])}
                                    isEditing={isEditing}
                                    startTimeFormFieldName={['fluids', index, 'start_time']}
                                    startTimeFormFieldValue={getFieldValue(['fluids', index, 'start_time'])}
                                    disabled={getFieldValue(['fluids', index, 'action']) !== 0}
                                />

                                <EstimateInstructionFormItem
                                    formFieldName={['fluids', index, 'instruction']}
                                    initialValue={null}
                                    isEditing={isEditing}
                                    formFieldValue={getFieldValue(['fluids', index, 'instruction'])}
                                    disabled={getFieldValue(['fluids', index, 'action']) !== 0}
                                />

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />

                            </>
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default EstimateFluidsForm;
