import { Form, FormInstance, Radio, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { DiagnosticEstimateItemNew } from 'utils/types/estimateTypesNew';
import OrderFrequency from 'views/visit/TreatmentSheet/OrderFrequency';
import EditLineButton from '../forms/PackageFormModal/EditLineButton';
import { HiddenInput } from '../forms/fields/HiddenInput';
import EstimateFrequencyFormItem from './EstimateMedicationForm/EstimateFrequencyFormItem';
import EstimateInstructionFormItem from './EstimateMedicationForm/EstimateInstructionFormItem';
import EstimateStartEndTimeFormItems from './EstimateMedicationForm/EstimateStartEndTimeFormItems';
import { EstimateFormInstance } from './index';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import { BaseSearchOption, DiagnosticSearchOption, isInstanceOfDiagnosticOption } from 'utils/types/InstructionOrderTypes';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';
import { useGetInstructionOptionsQuery } from 'services/instructionService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';


const getDiagnosticOption = (diagnostic: DiagnosticEstimateItemNew, searchOptions?: BaseSearchOption[]): DiagnosticSearchOption | undefined => {
    if (!searchOptions) {
        return undefined;
    }

    return searchOptions?.find((option) => {
        return option.id === diagnostic.diagnostic_id && isInstanceOfDiagnosticOption(option);
    }) as DiagnosticSearchOption | undefined;
};

interface EstimateDiagnosticsFormProps {
    diagnostic: DiagnosticEstimateItemNew;
    form: FormInstance<EstimateFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const EstimateDiagnosticsForm: React.FC<EstimateDiagnosticsFormProps> = ({
    diagnostic,
    form,
    hiddenSectionClass,
    index,
    activeLineItem,
    setActiveLineItem,
}) => {
    const { data: searchOptions } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);
    const diagnosticOption = getDiagnosticOption(diagnostic, searchOptions);


    const [hasError, setHasError] = useState(false);
    const lineId = `D-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const diagFields = [
        ['diagnostics', index, 'frequency'],
        ['diagnostics', index, 'end_time'],
        ['diagnostics', index, 'start_time'],
        ['diagnostics', index, 'instruction'],
    ];
    const diagsErrorArray = form.getFieldsError(diagFields);

    const [isSerialCheaper, setIsSerialCheaper] = useState(diagnostic.serial)


    const setSerial = (changes: {[key in string]: string}) => {  
        if (!diagnosticOption){
            return;
        }
        const fieldsValue = form.getFieldsValue();
        const diagnosticFields = {
            ...fieldsValue.diagnostics[index],
            ...changes
        }
        const startTime = (diagnosticFields?.start_time ?? fieldsValue.start_time)?.unix()
        const endTime = (diagnosticFields?.end_time ?? fieldsValue.end_time)?.unix()  
        
        const serial = checkIfSerialCheaper(diagnosticOption, diagnosticFields.frequency, startTime,endTime)
        const unit_cost_cents = serial ? diagnosticOption?.price_cents_serial ?? 0 : diagnosticOption?.cents ?? 0;

        setIsSerialCheaper(serial)

        form.setFieldsValue({
            ...fieldsValue,
            diagnostics: fieldsValue.diagnostics.map((diag: any, indx: number) => {
                return indx === index ? { ...diag, ...changes, serial, unit_cost_cents } : diag;
            }),
        });

    }

    const handleShouldUpdate = (prev: EstimateFormInstance, curr: EstimateFormInstance) => {
        return (
            prev.diagnostics[index].frequency !== curr.diagnostics[index].frequency ||
            prev.diagnostics[index].start_time !== curr.diagnostics[index].start_time ||
            prev.diagnostics[index].end_time !== curr.diagnostics[index].end_time ||
            prev.diagnostics[index].action !== curr.diagnostics[index].action
        );
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['diagnostics', index, 'frequency'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (diagsErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, diagsErrorArray]);

    useEffect(() => { 
        setSerial({})
    }, [])


    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const initialError = !(getFieldValue(['diagnostics', index, 'frequency']) ?? diagnostic.frequency);
                const serialName = isSerialCheaper ? `${diagnostic.name} - Serial ${diagnosticOption?.serial_hours} Hours` :   diagnostic.name;

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass}`}
                        onClick={() => {
                            if (!isEditing && getFieldValue(['diagnostics', index, 'action']) === 0) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['diagnostics', index, 'id']} initialValue={diagnostic.diagnostic_id} />
                            <HiddenInput fieldName={['diagnostics', index, 'name']} initialValue={diagnostic.name} />

                            <HiddenInput fieldName={['diagnostics', index, 'type_id']} initialValue={diagnostic.type_id} />
                            <HiddenInput fieldName={['diagnostics', index, 'instruction_id']} initialValue={diagnostic.instruction_id} />
                            <HiddenInput fieldName={['diagnostics', index, 'is_prn']} initialValue={diagnostic.is_prn} />
                            <HiddenInput fieldName={['diagnostics', index, 'prn_condition']} initialValue={diagnostic.prn_condition} />
                            <HiddenInput fieldName={['diagnostics', index, 'priority']} initialValue={diagnostic.priority} />
                            <HiddenInput fieldName={['diagnostics', index, 'serial']} initialValue={isSerialCheaper} />
                            <HiddenInput fieldName={['diagnostics', index, 'serial_hours']}  initialValue={diagnostic.serial_hours} />
                        </div>

                        <div className='grid-table__sticky-column'>
                            <Tooltip title={serialName.length > 45 ? serialName  : null}>
                                <span className='treatment-sheet-side-column__title'>{serialName}</span>
                            </Tooltip>

                            <div className='diagnostic-data'>
                                <OrderFrequency>
                                    {getFieldValue(['diagnostics', index, 'frequency']) ?? diagnostic.frequency}
                                </OrderFrequency>
                            </div>
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Transfer Options
                        </div>

                        <div className='package-medication-dose__inputs'>
                            <Form.Item name={['diagnostics', index, 'action']} initialValue={0}>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Transfer</Radio.Button>
                                    <Radio.Button value={1}>Exclude</Radio.Button>
                                    <Radio.Button value={2}>Save for Later</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className='empty-element' />
                        <div className='empty-element' />

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Frequency
                        </div>
                        <EstimateFrequencyFormItem
                            formFieldName={['diagnostics', index, 'frequency']}
                            initialValue={diagnostic.frequency}
                            isEditing={isEditing}
                            formFieldValue={form.getFieldValue(['diagnostics', index, 'frequency']) ?? diagnostic.frequency}
                            disabled={getFieldValue(['diagnostics', index, 'action']) !== 0}
                            onFormChange={(changes) => {
                                setSerial(changes)
                            }}

                        />

                        <EstimateStartEndTimeFormItems
                            endTimeFormFieldName={['diagnostics', index, 'end_time']}
                            endTimeFormFieldValue={getFieldValue(['diagnostics', index, 'end_time'])}
                            isEditing={isEditing}
                            startTimeFormFieldName={['diagnostics', index, 'start_time']}
                            startTimeFormFieldValue={getFieldValue(['diagnostics', index, 'start_time'])}
                            disabled={getFieldValue(['diagnostics', index, 'action']) !== 0}
                        />

                        <EstimateInstructionFormItem
                            formFieldName={['diagnostics', index, 'instruction']}
                            initialValue={null}
                            isEditing={isEditing}
                            formFieldValue={form.getFieldValue(['diagnostics', index, 'instruction'])}
                            disabled={getFieldValue(['diagnostics', index, 'action']) !== 0}
                        />
                        {isEditing && isSerialCheaper && 
                            <div
                                className={`serial-alert-column-transfer ${!isEditing || !isSerialCheaper ? 'serial-alert-column-transfer--hide' : ''}`}
                            >
                                <AlertSerialPrice serialHours={diagnosticOption?.serial_hours ?? 0} />
                            </div>
                        }


                        <EditLineButton
                            disabled={isEditing}
                            hasError={hasError || initialError}
                            onClick={() => {
                                if (!hasError) {
                                    setActiveLineItem(lineId);
                                }
                            }}
                        />
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default EstimateDiagnosticsForm;
