import { Form, Input, InputNumber, Radio } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';
import { initializeForm } from 'hooks/useOnFormInitialize';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { BASE_QUERY_OPTIONS, MAX_FLUID_ADDITIVES } from 'utils/constants';
import { ExistingFluidInstruction, PimsUser, isInstanceOfExistingFluidInstruction } from 'utils/dataTypes';
import { next30Min } from 'utils/formFuncs';
import { getFullAdditiveName } from 'utils/formatFuncs';
import { FluidSearchOption, OutgoingFluidOrder, isInstanceOfOutgoingFluidOrder } from 'utils/types/InstructionOrderTypes';
import { FluidOrderRules } from 'utils/types/validations';
import { endTimeGreaterThanStartTime } from 'utils/validationFuncs';
import { FALLBACK_FLUID_ROUTES } from '../../utils/constants';
import { Additives } from './Additives';
import { MedicationEnd, MedicationNote, MedicationStart } from './MedicineOrder';
import { HiddenInput } from './fields/HiddenInput';

const formItemStyle: CSSProperties = {
    width: '100%',
};
const formWithUnitStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
};

const getDefaults = (
    options: FluidSearchOption,
    route: number,
    existingInstruction?: Partial<ExistingFluidInstruction> | Partial<OutgoingFluidOrder>,
    user?: PimsUser,
): Partial<ExistingFluidInstruction> | Partial<OutgoingFluidOrder> => {
    if (existingInstruction) {
        const initialFluidAdditives = isInstanceOfExistingFluidInstruction(existingInstruction)
            ? existingInstruction.fluid_additives
                  ?.filter((additive) => additive.initial_order)
                  .map((additive) => {
                      return {
                          ...additive,
                          name: getFullAdditiveName(additive),
                      };
                  })
            : [];
        return {
            ...existingInstruction,
            ordered_by: user?.user_id || 0,
            fluid_additives: initialFluidAdditives,
        };
    } else {
        const now = moment();
        const additionalTime = options.default_duration_mins;
        const startTime = next30Min(now).unix();
        const endTime = additionalTime ? moment.unix(startTime).add(additionalTime, 'minutes').unix() : undefined;
        const defaultInstruction: Partial<ExistingFluidInstruction> | Partial<OutgoingFluidOrder> = {
            id: options.id,
            type_id: options.type_id,
            name: options.name,
            rate_ml_per_hr: options?.default_rate_ml_per_hr || undefined,
            fluids_volume_ml: options?.default_volume_ml || undefined,
            start_time: startTime,
            end_time: endTime,
            route_id: FALLBACK_FLUID_ROUTES[route],
            ordered_by: user?.user_id || 0,
        };
        return defaultInstruction;
    }
};

interface FluidOrderProps {
    options: FluidSearchOption;
    existingInstruction?: Partial<ExistingFluidInstruction> | Partial<OutgoingFluidOrder>;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12},
    fromEstimate?: boolean;
    form: FormInstance<any>;
}

export const FluidOrder = (props: FluidOrderProps) => {
    const { options, existingInstruction, fromEstimate } = props;
    const labelCol = props.labelCol || { span: 6 };
    const wrapperCol = props.wrapperCol || { span: 18 };
    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const [rate, setRate] = useState<number | undefined>(undefined);
    const [route, setRoute] = useState<string>(FALLBACK_FLUID_ROUTES[0]);
    const onFormChange = props.onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

    useEffect(() => {
        const defaultFluid = getDefaults(options, 0, existingInstruction, loggedInUserData);
        if (isInstanceOfOutgoingFluidOrder(defaultFluid)) {
            setRoute(defaultFluid.route_id);
        }
        initializeForm(defaultFluid, onFormChange);
    }, [options, existingInstruction]);

    const fluidOrderRules: FluidOrderRules = {
        fluids_volume_ml: [{ required: true }],
        rate_ml_per_hr: [
            {
                required: true,
            },
            {
                validator: (_, value) => {
                    if (value <= 0) {
                        return Promise.reject(new Error('Fluid rate must be greater than 0'));
                    }

                    if (value < 0.01) {
                        return Promise.reject('The value cannot be smaller than 0.01');
                    }

                    return Promise.resolve();
                },
            },
        ],
        start_time: [{ required: true, type: 'date' }],
        end_time: [{ type: 'date' }, endTimeGreaterThanStartTime],
        notes: [],
        route_id: [{ required: true }],
        is_prn: [{ required: false, type: 'boolean' }],
        prn_condition: [],
    };

    return (
        <>
            <Form.Item<number>
                rules={fluidOrderRules.fluids_volume_ml}
                label='Bag Volume (mL)'
                preserve={false}
                name='fluids_volume_ml'
                style={formWithUnitStyle}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
            >
                <InputNumber<number>
                    disabled={false}
                    type='number'
                    style={formItemStyle}
                    min={0}
                    onChange={(value) => {
                        onFormChange({
                            fluids_volume_ml: value,
                        });
                    }}
                />
            </Form.Item>
            <FluidRoute
                rules={fluidOrderRules.route_id}
                disabled={false}
                setRoute={setRoute}
                route={route}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFormChange={onFormChange}
            />
            <Form.Item
                rules={fluidOrderRules.rate_ml_per_hr}
                label='Fluid rate (mL/hr)'
                preserve={false}
                name='rate_ml_per_hr'
                style={formItemStyle}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
            >
                <InputNumber<number>
                    min={0}
                    value={rate}
                    style={formItemStyle}
                    onChange={(value) => {
                        setRate(value);
                        onFormChange({
                            rate_ml_per_hr: value,
                        });
                    }}
                />
            </Form.Item>

            <MedicationStart
                rules={fluidOrderRules.start_time}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                onFormChange={onFormChange} // Only needed because we default a value for this field
            />
            <MedicationEnd
                rules={fluidOrderRules.end_time}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
                onFormChange={onFormChange} // Only needed because we default a value for this field
            />
            <MedicationNote
                rules={fluidOrderRules.notes}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
            />
            <Form.Item
                label='Fluid rate'
                preserve={false}
                hidden
                name='fluids_id'
                style={formItemStyle}
                labelCol={labelCol} //Width of LABEL, in "columns" (like <Col span={4})
                wrapperCol={wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
            >
                <Input />
            </Form.Item>
            <HiddenInput fieldName='is_prn' />
            <HiddenInput fieldName='prn_condition' />
            <HiddenInput fieldName={'ordered_by'}></HiddenInput>
            <HiddenInput fieldName={'id'}></HiddenInput>
            <HiddenInput fieldName={'priority'}></HiddenInput>
            <HiddenInput fieldName='userId' initialValue={loggedInUserData?.user_id} />
            <HiddenInput fieldName='fromEstimate' initialValue={fromEstimate} />
            {/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
            <Additives initialOrder={true} form={props.form} maxAdditives={MAX_FLUID_ADDITIVES} />
        </>
    );
};

interface FluidRouteProps {
    setRoute: React.Dispatch<React.SetStateAction<string>>;
    route: string;
    // setIsVolumeAllowed: React.Dispatch<React.SetStateAction<boolean>>;
    onFormChange: Function;
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    disabled?: boolean;
    rules?: Rule[];
}

export const FluidRoute = (props: FluidRouteProps) => {
    const { onFormChange, setRoute, route: routeProp, disabled, rules } = props;
    const routeOptions = FALLBACK_FLUID_ROUTES; //TODO eventually, some items will come with a list of routes to sort to the top
    const defaultStyle: CSSProperties = {
        flex: 1,
    };
    const normalStyle: CSSProperties = {
        backgroundColor: '#73c64c33',
        ...defaultStyle,
    };

    return (
        <Form.Item
            preserve={false}
            name='route_id'
            label='Route'
            style={formItemStyle}
            labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
            wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
            rules={rules}
        >
            <Radio.Group
                disabled={disabled}
                style={{
                    width: '100%',
                    display: 'flex',
                }}
                onChange={(event) => {
                    const update: any = {
                        route_id: event.target.value,
                    };
                    setRoute(event.target.value);
                    onFormChange(update);
                }}
            >
                {routeOptions.map((route, index) => (
                    <Radio.Button key={`med_route_${route}`} value={route} style={routeProp === route ? normalStyle : defaultStyle}>
                        {route}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </Form.Item>
    );
};
