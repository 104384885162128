import { CustomerInfo, ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { PatientSection } from './PatientSection';
import { shortPetId } from 'utils/formatFuncs';
import { AssignedVetSection } from './AssignedVetSection';
import { VisitSection } from './VisitSection';
import { Alert, Button, Col, FormInstance, message, Modal, Row, Tag } from 'antd';
import heartbreak from 'assets/img/heartbreak_icon.svg';
import { useFormSubmission } from 'hooks';
import moment from 'moment';
import { UserNameWrapper } from 'utils/componentWrappers';
import { FormName } from 'utils/constants';
import { AssignedNurseSection } from './AssignedNurseSection';
import VisitDischargeAlert from './VisitDischargeAlert';
import { reviewAndFinalizeFlowRelock } from '../DischargeTab/utils';
import {
    useLazyGetBillingDataQuery, useLazyGetLedgerByVisitIdQuery,
    useLazyGetVisitDiscountQuery,
} from 'services/billingService';
import {
    useFinalizeInvoiceMutation, useUndoFinalizeInvoiceMutation,
} from 'services/visitService';
import _ from 'lodash';
import { useState } from 'react';
import { twelveHourWindowHasPassed } from 'utils/miscFuncs';
import {
    useDischargePatientInstructionsMutation,
    useGetInstructionsByVisitIdQuery,
} from '../../../services/instructionService';
import { useGetTaxForMultipleEstimatesQuery } from '../../../services/estimateService';
import { filterFluidsCri } from '../../../utils/filterFuncs';


interface VisitHeaderProps {
    currentVisit: PatientRecordVisit;
    customerInfo: CustomerInfo | undefined;
    patientData: ExpandedPatient | undefined;
    patientWeight: number | undefined;
    modalForm: FormInstance<any>;
    setModalstate: Function;
    isInvoiceLocked: boolean;
    canUnlockInvoice: boolean;
}

export const VisitHeader = ({ currentVisit, customerInfo, patientData, patientWeight, modalForm, setModalstate, isInvoiceLocked, canUnlockInvoice }: VisitHeaderProps) => {
    const [onFinishHook] = useFormSubmission();
    const { data: instData } = useGetInstructionsByVisitIdQuery({ visitId: currentVisit.id });
    const criAndFluidInstructions = instData?.filter(filterFluidsCri) ?? [];
    const ongoingInstructions = instData?.filter(
        (instruction) =>
            !instruction.discontinued_at && instruction.type_id !== 'TGH',
    );
    const [isRelocking, setIsRelocking] = useState(false);
    const visitClosedMessage = (finalized_at: number, finalized_by: number | null) => (
        <>
            <span>Visit closed by </span>
            <UserNameWrapper>{finalized_by}</UserNameWrapper>
            <span> on </span>
            <span>{moment.unix(finalized_at).format('MM/DD/YYYY HH:mm A')}</span>
        </>
    );
    const reopenChart = () => {
        if (canReopenChart) {
            onFinishHook({}, FormName.reopen_chart);
        }
    };
    const [discontinuePatientInstructions] = useDischargePatientInstructionsMutation();
    const [getBillingData] = useLazyGetBillingDataQuery();
    const [getLedgerByVisitId] = useLazyGetLedgerByVisitIdQuery();
    const {refetch: refetchTaxes } = useGetTaxForMultipleEstimatesQuery(currentVisit.id, { pollingInterval: 120000 });
    const discontinueCriAndFluidInstructions = () => {
        if (currentVisit.id) {
            return discontinuePatientInstructions({
                visitId: currentVisit.id,
                ids: criAndFluidInstructions?.filter(instruction => instruction.discontinued_at === null).map((instruction) => instruction.id) ?? [],
            }).then((response) => {
                if ('data' in response) {
                    getBillingData({ visitId: currentVisit.id });
                    getLedgerByVisitId(currentVisit.id);
                    refetchTaxes();
                }
            });
        }
    };

    const [getBillingDataLazy] = useLazyGetBillingDataQuery();
    const [getVisitDiscountLazy] = useLazyGetVisitDiscountQuery();
    const [undoFinalizeInvoice] = useUndoFinalizeInvoiceMutation();

    const unlockInvoice = () => {
        undoFinalizeInvoice({ visitId: currentVisit.id }).unwrap().catch(
            () => message.error("Unable to unlock, more than 12 hours have passed.")
        );
    };

    const onFinishRelock = (ids: number[]) => {
        return discontinuePatientInstructions({
            visitId: currentVisit.id, ids: ids,
        })
            .unwrap()
            .then((resp) => {
                finalizeInvoice({ visitId: currentVisit.id })
            });
    };

    const relockInvoice = () => {
        if (isRelocking) {
            return;
        }
        reviewAndFinalizeFlowRelock(
            ongoingInstructions,
            discontinueCriAndFluidInstructions,
            onFinishRelock,
            getBillingDataLazy,
            getVisitDiscountLazy,
            currentVisit.id,
            setIsRelocking,
            patientData!.name,
        );
    };

    const [finalizeInvoice] = useFinalizeInvoiceMutation();

    const isInvoiceUnlocked = currentVisit.invoice_unlocked_at && !twelveHourWindowHasPassed(currentVisit?.invoice_finalized_at);
    const canReopenChart = currentVisit.finalized_at ? moment().unix() < currentVisit.finalized_at + 24 * 60 * 60 : false;

    return (
        <>
            {isInvoiceUnlocked &&
                <Alert
                    className={"alert-with-icon-aligned-to-top"}
                    data-cy='unlockedInvoiceAlert'
                    message={'This invoice has been unlocked. The visit will relock 12 hrs from finalization. Any remaining orders will be discontinued. This may occur up to an hour after final lock.'}
                    banner
                    action={
                        <Button data-cy='relockInvoiceButton' size='small' onClick={relockInvoice}>
                            Relock Invoice
                        </Button>
                    }
                />
            }
            {!currentVisit.finalized_at && currentVisit.invoice_finalized_at && !isInvoiceUnlocked && !canUnlockInvoice &&
                <Alert
                    data-cy='unlockedInvoiceAlert'
                    type='info'
                    message={'This invoice has been finalized and locked. No invoice adjustments can be made.'}
                    banner
                />
            }
            {!currentVisit.finalized_at && currentVisit.invoice_finalized_at && canUnlockInvoice &&
                <Alert
                    data-cy='unlockedInvoiceAlert'
                    type='info'
                    message={'This invoice has been finalized and locked. No invoice adjustments can be made.'}
                    banner
                    action={
                        <Button
                            data-cy='relockInvoiceButton'
                            size='small'
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Unlock Invoice',
                                    content: (
                                        <>
                                            <p>
                                                Are you sure you want to unlock {patientData?.name}’s invoice and allow adjustments?  
                                            </p>
                                        </>
                                    ),
                                    okText: 'Unlock Invoice',
                                    centered: true,
                                    maskClosable: true,
                                    onOk: unlockInvoice,
                                    okButtonProps: {style: {color: 'var(--gray-9)', background: 'var(--gold-6)', borderColor: 'var(--gold-6)'}}
                                });
                            }}
                        >
                            Unlock Invoice
                        </Button>
                    }
                />
            }
            {currentVisit?.status === 'checked_out' && !currentVisit?.finalized_at && (
                <VisitDischargeAlert
                    visitId={currentVisit.id}
                    modalForm={modalForm}
                    setModalState={setModalstate}
                />
            )}
            {currentVisit?.finalized_at && (
                <Alert
                    data-cy='closedVisitAlert'
                    className='closed-visit-message'
                    message={visitClosedMessage(currentVisit.finalized_at, currentVisit.finalized_by)}
                    banner
                    action={
                        canReopenChart ? (
                            <Button data-cy='reopenChartButton' size='small' onClick={reopenChart}>
                                Reopen Chart
                            </Button>
                        ) : (
                            <span style={{ color: 'var(--gray-7)' }}>Visits closed over 24 hrs cannot be reopened.</span>
                        )
                    }
                />
            )}
            <Row className='visit-header'>
                <Col span={24}>
                    <Row align='bottom'>
                        <Col xs={{ span: 14 }} xl={{ span: 16 }} xxl={{ span: 16 }}>
                            <Row align='middle'>
                                <Col style={{ color: '#858383' }} data-cy='petId'>
                                    #{shortPetId(currentVisit.pet_id)}
                                </Col>
                                {!!currentVisit.triage_level && (
                                    <Col style={{ marginLeft: '14px' }}>
                                        <Tag
                                            style={{
                                                backgroundColor:
                                                    currentVisit.triage_level === 'critical'
                                                        ? 'var(--veg-red)'
                                                        : currentVisit.triage_level === 'urgent'
                                                        ? 'var(--veg-tint-gold)'
                                                        : 'var(--gray-8)',
                                                color: currentVisit.triage_level === 'urgent' ? 'var(--gray-8)' : 'white',
                                                border:
                                                    '1px solid ' +
                                                    (currentVisit.triage_level === 'critical'
                                                        ? 'var(--veg-secondary-red)'
                                                        : currentVisit.triage_level === 'urgent'
                                                        ? 'var(--veg-secondary-gold)'
                                                        : 'var(--gray-9)'),
                                                fontSize: '12px',
                                                padding: '1px 8px',
                                            }}
                                        >
                                            {currentVisit.triage_level.toUpperCase()}
                                        </Tag>
                                    </Col>
                                )}

                                {!!patientData?.deceased_at && (
                                    <Col style={{ marginLeft: '4px' }}>
                                        <Tag
                                            icon={<img src={heartbreak} width='16' style={{ margin: '0 5px 2px 0' }} />}
                                            style={{
                                                backgroundColor: '#D32027',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                border: 'none',
                                                fontSize: '12px',
                                                padding: '2px 7px',
                                            }}
                                        >
                                            Deceased
                                        </Tag>
                                    </Col>
                                )}
                            </Row>
                            <PatientSection currentVisit={currentVisit} customerInfo={customerInfo} patientWeight={patientWeight} />
                        </Col>
                        <Col xs={{ span: 10 }} xl={{ span: 8 }} xxl={{ span: 6, offset: 2 }}>
                            <VisitSection currentVisit={currentVisit} modalForm={modalForm} setModalstate={setModalstate} patient={patientData!} />
                            <AssignedVetSection currentVisit={currentVisit} />
                            <AssignedNurseSection currentVisit={currentVisit} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
