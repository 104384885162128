import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
	useApproveEuthanasiaFormMutation,
	useGetEuthanasiaFormQuery,
} from 'services/consentService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/stringFormatting';
import { IFormProps } from './ConsentForms';
import PrintableForm from './PrintableForm';
import { ConsentFormSuccess } from './Result';

const EuthanasiaForm: React.FC<IFormProps> = ({ formId }) => {
	const { data: euthanasiaData } = useGetEuthanasiaFormQuery(
		formId,
		BASE_QUERY_OPTIONS,
	);

	const [approveEuthanasia] = useApproveEuthanasiaFormMutation();
	const [approved, setApproved] = useState(false);
	const onApprove = () => {
		approveEuthanasia({ FormId: formId });
		setApproved(true);
	};

	useEffect(() => {
        if (euthanasiaData?.is_approved) {
            setApproved(true);
        }
    }, [euthanasiaData]);

	if (approved) {
		return (
			<ConsentFormSuccess subTitle='We have successfully received your signed consent form.' />
		);
	}

	if (!euthanasiaData) {
		return <Spin size='large' />;
	}

	const { pet_name } = euthanasiaData;
	const petNameUppercase = capitalizeFirstLetter(pet_name);

	return (
		<PrintableForm
			title='Euthanasia Consent'
			headerData={{ ...euthanasiaData, pet_name: petNameUppercase }}
			onApprove={onApprove}
			approved={approved}
			approveText='I acknowledge that I have read the terms of this agreement.'
			acceptButtonText='I agree'
			printMessage='For a copy of this agreement, please print prior to accepting the terms.'
		>
			<div className='single-page-element document-text'>
				<p>
					I, the undersigned, do hereby certify that I am the owner or
					the authorized agent for the owner of the pet described
					above, that I am 18 years of age or older, that I have the
					authority to execute this consent, and that I do hereby give
					complete authority to humanely euthanize{' '}
					{petNameUppercase + ' '}
					and release the veterinary facility and its owners,
					employees and staff from any and all claims and liability
					(including legal fees and other costs and expenses) for so
					euthanizing said pet. The nature of the euthanasia process
					has been explained to me, and I understand what will be done
					and that it is an irreversible and final process.
				</p>
				<p>
					I do also certify that to the best of my knowledge said pet
					has not bitten any person or animal during the last fifteen
					(15) days and has not been exposed to rabies.
				</p>
			</div>
		</PrintableForm>
	);
};

export default EuthanasiaForm;
