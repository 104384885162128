import { useParams } from 'react-router-dom';
import ApprovedMedicationConsentForm from './ApprovedMedicationConsentForm';
import './ViewApprovedConsentForm.css';

const ViewApprovedConsentForm = () => {
    const { urlFormId } = useParams<any>();

    if (urlFormId.charAt(0) === 'f') {
        return <ApprovedMedicationConsentForm />;
    }

    return null;
};

export default ViewApprovedConsentForm;
