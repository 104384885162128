import { useState } from 'react'
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { Button, Table, Tag, Tooltip, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { displayUser, formatCurrency } from "utils/formatFuncs"
import { LedgerItem, StripePaymentErrorItem } from "utils/types/billingTypes"
import moment from 'moment'
import './billing_panel.css'
import { PaymentType, PAYMENT_TYPE_NAME } from "utils/types/paymentTypes"
import { RefundPaymentModal } from "./Payment/RefundPaymentModal"

export const generateLedgerDisplayData = (ledgerItems: LedgerItem[], stripePaymentErrorItems: StripePaymentErrorItem[]): {
    key: string | number,
    date: string,
    form_of_payment: PaymentType,
    card_brand: string | null,
    last_4_digits: string | null,
    amount_cents: number,
    children: {
        key: string | number,
        id: number,
        date: string,
        payment_type_display: string,
        form_of_payment: PaymentType,
        card_brand: string | null,
        last_4_digits: string | null,
        amount_cents: number,
    }[]
}[] => {
    
    const formattedPaymentErrorItems = stripePaymentErrorItems.map((item, index) => {
        const paymentErrorRow = {
            key: index,
            id: -1,
            date: moment.unix(item.updated_at).format('MM/DD/YYYY hh:mm a'),
            payment_type_display: 'Error',
            form_of_payment: item.form_of_payment,
            card_brand: item.card_brand,
            last_4_digits: item.last_4_digits,
            amount_cents: item.amount_cents,
            last_error_code: item.last_error_code,
            last_error_message: item.last_error_message,
        };
        
        return {
            key: 'payment_error_' + index,
            id: undefined,
            date: '',
            form_of_payment: item.form_of_payment,
            card_brand: item.card_brand,
            last_4_digits: item.last_4_digits,
            amount_cents: item.amount_cents,
            children: [paymentErrorRow],
        }
    });

    const formattedLedgerItems = ledgerItems.filter(item => item.refunded_from === null).map((parentItem, index) => {
        
        const refundItems = ledgerItems.filter(item => item.refunded_from === parentItem.id);
        const totalRefunded = refundItems.reduce((a, b) => a + b.amount_cents, 0);
        const totalAmount = parentItem.amount_cents + totalRefunded;
        const paymentRow = {
            key: index,
            id: parentItem.id,
            date: moment.unix(parentItem.created_at).format('MM/DD/YYYY hh:mm a'),
            payment_type_display: 'Payment',
            form_of_payment: parentItem.form_of_payment,
            processed_by: displayUser({
                first_name: parentItem.created_by_first_name ?? '',
                last_name: parentItem.created_by_last_name ?? ''
            }),
            card_brand: parentItem.last_4_digits,
            last_4_digits: parentItem.last_4_digits,
            amount_cents: parentItem.amount_cents,
        };
        const refundRows = refundItems.map((item, index) => {
            return {
                key: index,
                id: item.id,
                date: moment.unix(item.created_at).format('MM/DD/YYYY hh:mm a'),
                payment_type_display: 'Refund',
            processed_by: displayUser({
                first_name: item.created_by_first_name ?? '',
                last_name: item.created_by_last_name ?? ''
            }),
                form_of_payment: item.form_of_payment,
                card_brand: item.card_brand,
                last_4_digits: item.last_4_digits,
                amount_cents: item.amount_cents,
            }
        });
        return {
            key: index,
            id: undefined,
            date: '',
            form_of_payment: parentItem.form_of_payment,
            card_brand: parentItem.card_brand,
            last_4_digits: parentItem.last_4_digits,
            amount_cents: totalAmount,
            children: [paymentRow, ...refundRows]
        }
    });

    return [...formattedLedgerItems, ...formattedPaymentErrorItems];
}

const generateLedgerColumns = (
    openModal: (refundFrom: number, maxAmountCents: number) => void,
    balanceDue: number,
    isInvoiceFinalized: boolean,
): ColumnsType<{
    key: string | number,
    date: string,
    form_of_payment: PaymentType,
    card_brand: string | null,
    last_4_digits: string | null,
    amount_cents: number,
    last_error_code?: string;
    children: {
        key: string | number,
        id: number,
        date: string,
        payment_type_display: string,
        form_of_payment: PaymentType,
        card_brand: string | null,
        last_4_digits: string | null,
        amount_cents: number,
        last_error_code?: string;
    }[]
}> => {
    return [
        {
            title: '',
            width: '5%',
            key: 'select',
            render: (_, record) => <></>
        },
        {
            title: 'Payment Type',
            width: '25%',
            key: 'payment_type_display',
            dataIndex: 'payment_type_display',
            render(value, record, index) {
                if (value) {
                    return value;
                }
                if (record.form_of_payment === "stripe_terminal" || record.form_of_payment === "stripe_link") {
                    return (
                        <>
                            <span style={{textTransform: "uppercase"}}>{record.card_brand || "Stripe"}</span> ...{record.last_4_digits}
                            <Typography.Text type='secondary' style={{fontWeight: "normal"}}> ({record.form_of_payment === "stripe_terminal" ? "Terminal" : "Link"})</Typography.Text>
                        </>
                    )
                }
                return PAYMENT_TYPE_NAME[record.form_of_payment];
            }
        },
        {
            title: 'Date/Time',
            width: '35%',
            key: 'date',
            dataIndex: 'date',
            render(value, record) {
                if (!value && record.children && record.children[0].last_error_code) {
                    return (
                        <>
                            <Tag className='stripe-payment-error-tag'>
                                ERROR WHEN PROCESSING: {record.children[0].last_error_code}
                            </Tag>
                            <div>{value}</div>
                        </>
                    )
                }
                return value;
            }
        },
        {
            title: 'Processed by',
            width: '20%',
            key: 'processed_by',
            dataIndex: 'processed_by'
        },
        {
            title: 'Amount',
            width: '10%',
            key: 'amount_cents',
            dataIndex: 'amount_cents',
            align: 'right',
            render(value, record) {
                if (record.children && record.children[0].last_error_code) {
                    return null;
                }
                return formatCurrency(value)
            }
        },
        {
            title: '',
            width: '5%',
            key: 'refund',
            dataIndex: 'refund',
            align: 'center',
            render(value, record) {
                if (!record.children || record.amount_cents <= 0 || (record.children && record.children[0].last_error_code)) {
                    return null;
                }

                const getAmountToRefund = () => {
                    if (isInvoiceFinalized) {
                        if (Math.abs(balanceDue) > record.amount_cents) {
                            return record.amount_cents;
                        } else {
                            return Math.abs(balanceDue);
                        }
                    } else {
                        return record.amount_cents;
                    }
                }

                return (
                    <Tooltip
                        title={
                            isInvoiceFinalized && balanceDue >= 0
                                ? 'You can only refund a payment if there is credit on the account. Please no charge line items before processing a refund.'
                                : null
                        }
                    >
                        <Button
                            size='small'
                            disabled={isInvoiceFinalized && balanceDue >= 0}
                            onClick={() => {
                                openModal(record.children[0].id, getAmountToRefund());
                            }}
                        >
                            Refund
                        </Button>
                    </Tooltip>
                )
            }
        },
        
    ]
}

interface PaymentsTableProps {
    visitId: number;
    ledgerItems: LedgerItem[];
    stripePaymentErrorItems: StripePaymentErrorItem[];
    isVisibleRefundModal: boolean;
    setIsVisibleRefundModal: React.Dispatch<React.SetStateAction<boolean>>;
    isFetchingBillingData: boolean;
    balanceDue: number;
    isInvoiceFinalized: boolean;
}

export const PaymentsTable = ({
    visitId,
    ledgerItems,
    stripePaymentErrorItems,
    isVisibleRefundModal,
    setIsVisibleRefundModal,
    isFetchingBillingData,
    balanceDue,
    isInvoiceFinalized,
}: PaymentsTableProps) => {
    const [refundFrom, setRefundFrom] = useState<number | undefined>();
    const [maxAmountCents, setMaxAmountCents] = useState(0);
    const openModal = (refundFrom: number, maxAmountCents: number) => {
        setRefundFrom(refundFrom);
        setMaxAmountCents(maxAmountCents);
        setIsVisibleRefundModal(true);
    };
    const columns = generateLedgerColumns(openModal, balanceDue, isInvoiceFinalized);
    const dataSource = generateLedgerDisplayData(ledgerItems, stripePaymentErrorItems);

    return (
        <>
            <Table
                className="billing-table"
                columns={columns}
                dataSource={dataSource}
                scroll={{
                    x: true,
                }}
                pagination={false}
                expandIcon={({ expanded, onExpand, record }) => {
                    if (!record.children) {
                        return <></>
                    }
                    if (expanded) {
                        return <UpOutlined
                            onClick={(e) => onExpand(record, e)}
                        />
                    }
                    else {
                        return <DownOutlined
                            onClick={(e) => onExpand(record, e)}
                        />
                    }
                }}
            >
            </Table>
            <RefundPaymentModal
                isVisible={isVisibleRefundModal}
                setIsVisible={setIsVisibleRefundModal}
                maxAmountCents={maxAmountCents}
                visitId={visitId}
                refundFrom={refundFrom}
                isFetchingBillingData={isFetchingBillingData}
            />
        </>
    )
}
