import { Form, FormInstance, Radio, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { TaskEstimateItemNew } from 'utils/types/estimateTypesNew';
import OrderFrequency from 'views/visit/TreatmentSheet/OrderFrequency';
import EditLineButton from '../forms/PackageFormModal/EditLineButton';
import { HiddenInput } from '../forms/fields/HiddenInput';
import EstimateFrequencyFormItem from './EstimateMedicationForm/EstimateFrequencyFormItem';
import EstimateInstructionFormItem from './EstimateMedicationForm/EstimateInstructionFormItem';
import EstimateStartEndTimeFormItems from './EstimateMedicationForm/EstimateStartEndTimeFormItems';
import { EstimateFormInstance } from './index';
import { BaseSearchOption, TaskSearchOption, isInstanceOfTaskOption } from 'utils/types/InstructionOrderTypes';
import { useGetInstructionOptionsQuery } from 'services/instructionService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';
import { checkIfSerialCheaper } from 'utils/formFuncs';


const getTaskOption = (task: TaskEstimateItemNew, searchOptions?: BaseSearchOption[]): TaskSearchOption | undefined => {
    if (!searchOptions) {
        return undefined;
    }

    return searchOptions?.find((option) => {
        return option.id === task.task_id && isInstanceOfTaskOption(option);
    }) as TaskSearchOption | undefined;
};

interface EstimateTasksFormProps {
    task: TaskEstimateItemNew;
    form: FormInstance<EstimateFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const EstimateTasksForm: React.FC<EstimateTasksFormProps> = ({
    task,
    form,
    hiddenSectionClass,
    index,
    activeLineItem,
    setActiveLineItem,
}) => {
    const { data: searchOptions } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);
    const taskOption = getTaskOption(task, searchOptions);


    const [hasError, setHasError] = useState(false);
    const lineId = `T-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const tasksFields = [
        ['tasks', index, 'frequency'],
        ['tasks', index, 'end_time'],
        ['tasks', index, 'start_time'],
        ['tasks', index, 'instruction'],
    ];
    const tasksErrorArray = form.getFieldsError(tasksFields);

    const [isSerialCheaper, setIsSerialCheaper] = useState(task.serial)


    const setSerial = (changes: {[key in string]: string}) => {  
        if (!taskOption){
            return;
        }
        const fieldsValue = form.getFieldsValue();
        const taskFields = {
            ...fieldsValue.tasks[index],
            ...changes
        }
        const startTime = (taskFields?.start_time ?? fieldsValue.start_time)?.unix()
        const endTime = (taskFields?.end_time ?? fieldsValue.end_time)?.unix()  
        
        const serial = checkIfSerialCheaper(taskOption, taskFields.frequency, startTime,endTime)
        const unit_cost_cents = serial ? taskOption?.price_cents_serial ?? 0 : taskOption?.cents ?? 0;

        setIsSerialCheaper(serial)

        form.setFieldsValue({
            ...fieldsValue,
            tasks: fieldsValue.tasks.map((diag: any, indx: number) => {
                return indx === index ? { ...diag, ...changes, serial, unit_cost_cents } : diag;
            }),
        });

    }



    const handleShouldUpdate = (prev: EstimateFormInstance, curr: EstimateFormInstance) => {
        return (
            prev.tasks[index].frequency !== curr.tasks[index].frequency ||
            prev.tasks[index].start_time !== curr.tasks[index].start_time ||
            prev.tasks[index].end_time !== curr.tasks[index].end_time ||
            prev.tasks[index].action !== curr.tasks[index].action
        );
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['tasks', index, 'frequency'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (tasksErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, tasksErrorArray]);

    useEffect(() => { 
        setSerial({})
    }, [])


    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const initialError = !(getFieldValue(['tasks', index, 'frequency']) ?? task.frequency);
                const serialName = isSerialCheaper ? `${task.name} - Serial ${taskOption?.serial_hours} Hours` :   task.name;

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing && getFieldValue(['tasks', index, 'action']) === 0) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['tasks', index, 'id']} initialValue={task.task_id} />
                            <HiddenInput fieldName={['tasks', index, 'name']} initialValue={task.name} />

                            <HiddenInput fieldName={['tasks', index, 'type_id']} initialValue={task.type_id} />
                            <HiddenInput fieldName={['tasks', index, 'instruction_id']} initialValue={task.instruction_id} />
                            <HiddenInput fieldName={['tasks', index, 'is_prn']} initialValue={task.is_prn} />
                            <HiddenInput fieldName={['tasks', index, 'prn_condition']} initialValue={task.prn_condition} />
                            <HiddenInput fieldName={['tasks', index, 'priority']} initialValue={task.priority} />
                            <HiddenInput fieldName={['tasks', index, 'serial']} initialValue={isSerialCheaper} />
                            <HiddenInput fieldName={['tasks', index, 'serial_hours']}  initialValue={task.serial_hours} />

                        </div>

                        <div className='grid-table__sticky-column'>
                            <Tooltip title={serialName.length > 45 ? serialName : null}>
                                <span className='treatment-sheet-side-column__title'>{serialName}</span>
                            </Tooltip>

                            <div className='diagnostic-data'>
                                <OrderFrequency>{getFieldValue(['tasks', index, 'frequency']) ?? task.frequency}</OrderFrequency>
                            </div>
                        </div>

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Transfer Options
                        </div>

                        <div className='package-medication-dose__inputs'>
                            <Form.Item name={['tasks', index, 'action']} initialValue={0}>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Transfer</Radio.Button>
                                    <Radio.Button value={1}>Exclude</Radio.Button>
                                    <Radio.Button value={2}>Save for Later</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className='empty-element' />
                        <div className='empty-element' />

                        <div className='package-modal__mobile-header'>
                            <span style={{ color: 'var(--veg-red)' }}>* </span>
                            Frequency
                        </div>
                        <EstimateFrequencyFormItem
                            formFieldName={['tasks', index, 'frequency']}
                            initialValue={task.frequency}
                            isEditing={isEditing}
                            formFieldValue={form.getFieldValue(['tasks', index, 'frequency']) ?? task.frequency}
                            disabled={getFieldValue(['tasks', index, 'action']) !== 0}
                            onFormChange={(changes) => {
                                setSerial(changes)
                            }}

                        />

                        <EstimateStartEndTimeFormItems
                            endTimeFormFieldName={['tasks', index, 'end_time']}
                            endTimeFormFieldValue={form.getFieldValue(['tasks', index, 'end_time'])}
                            isEditing={isEditing}
                            startTimeFormFieldName={['tasks', index, 'start_time']}
                            startTimeFormFieldValue={form.getFieldValue(['tasks', index, 'start_time'])}
                            disabled={getFieldValue(['tasks', index, 'action']) !== 0}
                        />

                        <EstimateInstructionFormItem
                            formFieldName={['tasks', index, 'instruction']}
                            initialValue={null}
                            isEditing={isEditing}
                            formFieldValue={form.getFieldValue(['tasks', index, 'instruction'])}
                            disabled={getFieldValue(['tasks', index, 'action']) !== 0}
                        />
                        {isEditing && isSerialCheaper && 
                            <div
                                className={`serial-alert-column-transfer ${!isEditing || !isSerialCheaper ? 'serial-alert-column-transfer--hide' : ''}`}
                            >
                                <AlertSerialPrice serialHours={taskOption?.serial_hours ?? 0} />
                            </div>
                        }

                        <EditLineButton
                            disabled={isEditing}
                            hasError={hasError || initialError}
                            onClick={() => {
                                if (!hasError) setActiveLineItem(lineId);
                            }}
                        />
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default EstimateTasksForm;
