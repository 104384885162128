import { Col, Divider, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import moment from 'moment';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useGetUserDataQuery } from 'services/authService';
import { next30Min } from 'utils/formFuncs';
import { initializeForm } from '../../hooks/useOnFormInitialize';
import { useGetFluidOptionsQuery } from '../../services/instructionService';
import { BASE_QUERY_OPTIONS } from '../../utils/constants';
import {
	ExistingCriInstruction,
	PimsUser,
} from '../../utils/dataTypes';
import {
	EnrichedCriSearchOption,
	FluidSearchOption,
} from '../../utils/types/InstructionOrderTypes';
import { HiddenInput } from './fields/HiddenInput';
import {
	MedicationEnd,
	MedicationNote,
	MedicationStart,
	MedicationType,
	MedicationWeight,
} from './MedicineOrder';
import { roundTo, weightMultiplier } from 'utils/formatFuncs';
import { endTimeGreaterThanStartTime, validateFieldIsGreaterThan } from 'utils/validationFuncs';
import { CriOrderRules } from 'utils/types/validations';

const { Option } = Select;

const formItemStyle: CSSProperties = {
	width: '100%',
};

export const BLANK_FLUID_OPTION: FluidSearchOption = {
	id: -1,
	name: ' ',
	type_id: 'F',
	abbreviation: null,
	default_duration_mins: null,
	default_rate_ml_per_hr: null,
	default_volume_ml: null,
	cents: null,
	price_id: 0,
	price_type: 'unit',
	supplemental_id: null,
	supplemental_name: null,
	supplemental_cents: null
};

const getDefaults = (
	options: EnrichedCriSearchOption,
	existingInstruction?: Partial<ExistingCriInstruction>,
	approxPatientWeight?: number,
	patientWeight?: number,
	user?: PimsUser
): Partial<ExistingCriInstruction> => {
	if (existingInstruction) {
        const approximateWeight = existingInstruction.approx_patient_weight_kg ? roundTo(existingInstruction.approx_patient_weight_kg, 3) : null 
		return {
			...existingInstruction,
			ordered_by: user?.user_id || 0,
			latest_patient_weight_kg: patientWeight ?? existingInstruction.latest_patient_weight_kg,
            approx_patient_weight_kg: approximateWeight
		};
	} else {
		const now = moment();
		const additionalTime = options.default_duration_mins;
		const startTime = next30Min(now).unix();
		const endTime = additionalTime
			? moment.unix(startTime).add(additionalTime, 'minutes').unix()
			: undefined;
		let fluidRate: number | undefined;
		const localWeight = patientWeight ?? approxPatientWeight;
		if (localWeight && options.default_dose && options.medication.numerator_value) {
			fluidRate =
				roundTo(
					(options.default_dose * (localWeight)) /
					options.medication.numerator_value
					, 2);
		}
		let dose: number | null | undefined = roundTo(options.default_dose, 3);

		if (options.default_cri_unit === 'mcg/kg/min' && options.default_dose) {
			dose = roundTo(options.default_dose * 1000 / 60 , 3);
		}

		return {
			medication_id: options.medication_id,
			fluids_id: options.fluids_id,
			type_id: options.type_id,
			fluids_volume_ml: options.fluids_volume_ml,
			name: options.name,
			dose: dose || undefined,
			rate_ml_per_hr: roundTo(options.default_rate_ml_per_hr, 2) || fluidRate,
			start_time: startTime,
			end_time: endTime,
			approx_patient_weight_kg: approxPatientWeight,
			is_prn: false,
			prn_condition: undefined,
			ordered_by: user?.user_id || 0,
			dose_unit: options.default_cri_unit ?? `${options.medication.numerator_unit}/kg/hr`,
		};
	}
};


interface CriOrderProps {
	options: EnrichedCriSearchOption;
	existingInstruction?: Partial<ExistingCriInstruction>;
	patientWeight?: number;
	onFormChange?: Function; //not actually optional -- always sent via FormWrapper
	getFormData?: Function; //not actually optional -- always sent via FormWrapper
	labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
	wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
	form?: FormInstance<any>;
	fromEstimate?: boolean;
}
export const CriOrder = (props: CriOrderProps) => {
	const { options, existingInstruction, patientWeight, fromEstimate } = props;

	const { data: apiFluidOptions } = useGetFluidOptionsQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);



	let fluidOptions = apiFluidOptions ? [...apiFluidOptions] : [];
	fluidOptions?.unshift(BLANK_FLUID_OPTION);
    const approxPatientWeightRounded = existingInstruction?.approx_patient_weight_kg ? roundTo(existingInstruction?.approx_patient_weight_kg, 3) : null 
	const [approxPatientWeight, setApproxPatientWeight] = useState(approxPatientWeightRounded || 0);

	const [fluidRate, setFluidRate] = useState(
		existingInstruction?.rate_ml_per_hr ??
		(options.default_rate_ml_per_hr || 0),
	);
	const [dose, setDose] = useState(
		existingInstruction?.dose ?? (options.default_dose || 0),
	);
	const [doseUnit, setDoseUnit] = useState(options.default_cri_unit || existingInstruction?.default_cri_unit || null);

	const updateDose = (newDose: number) => {
		setDose(newDose);
		onFormChange({ dose: newDose })
	}

	const [fluidsId, setFluidsId] = useState(
		existingInstruction?.fluids_id ??
		(options.fluids_id || null),
	);
	const [fluidVolume, setFluidVolume] = useState(
		existingInstruction?.fluids_volume_ml ??
		(options.fluids_volume_ml || null),
	);

	useEffect(() => {
		//this means fluid rate is LOCKED to dosage
		//Change rate to match new dose
		if (
			options.medication.numerator_value &&
			(!fluidsId ||
				fluidsId === BLANK_FLUID_OPTION.id)
		) {
			let localDose = dose || 0;

			if (doseUnit === 'mcg/kg/min') {
				localDose = dose / 1000 * 60;
			}

			const localWeight =
				patientWeight ??
				approxPatientWeight ??
				existingInstruction?.latest_patient_weight_kg ??
				existingInstruction?.approx_patient_weight_kg ??
				null;
			const newFluidRate =
				roundTo(
					((localDose * localWeight) /
					options.medication.numerator_value) *
					options.medication.denominator_value
					, 2);

			setFluidRate(newFluidRate);
			setFluidVolume(null);
			onFormChange({
				rate_ml_per_hr: newFluidRate,
				fluids_volume_ml: null,
			});
		}

	}, [dose, approxPatientWeight, fluidsId])

	const [bagLabel, setBagLabel] = useState(
		`${options.medication_name} ${fluidVolume ? 'qs ' + fluidVolume + 'mL' : ''
		} ${options.fluid?.abbreviation ?? ''}`,
	);
	const updateBagLabel = (newLabel: string) => {
		setBagLabel(newLabel);
		onFormChange({ label: newLabel })
	}

	const onFormChange = useMemo(
		() =>
			props.onFormChange ??
			(() => console.error('ERROR, onFormChange NOT PASSED THROUGH')),
		[props.onFormChange],
	);

	const getFormData =
		props.getFormData ??
		(() => console.error('ERROR, getFormData NOT PASSED THROUGH'));

	//If we have a PatientWeight on file, LOCK that in
	//Otherwise, we are beholden to the "approx weight"

	useEffect(() => {
		const defaultsMedicines = getDefaults(options, existingInstruction, approxPatientWeight, patientWeight, loggedInUserData);
		if (props.form) {
			props.form.resetFields(Object.keys(defaultsMedicines))
		}
		let dose = defaultsMedicines.dose ?? 0;

		if (existingInstruction) {
			dose = dose * weightMultiplier(existingInstruction as ExistingCriInstruction, patientWeight);
		}

		initializeForm(
			{
				...defaultsMedicines,
				label: bagLabel,
				latest_patient_weight_kg: patientWeight ?? null,
				approx_patient_weight_kg: patientWeight
					? null
					: approxPatientWeight,
				dose,
				dose_unit: options.default_cri_unit ?? `${options.medication.numerator_unit}/kg/hr`,
			},
			onFormChange,
		);
		updateDose(dose)

	}, [options, existingInstruction]);

	const [startTime, setStartTime] = useState<moment.Moment | undefined>(getFormData().start_time)
	const [endTime, setEndTime] = useState<moment.Moment | undefined>(getFormData().end_time)

	const isFluidIdSet = fluidsId !== null && fluidsId >= 0;
	const fluidVolumeRules = isFluidIdSet
		? [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('diluent volume'),
			}
		]
		: [];

	const criOrderRules: CriOrderRules = {
		fluids_id: [],
		fluids_volume_ml: fluidVolumeRules,
		rate_ml_per_hr: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('fluid rate'),
			}
		],
		label: [{ required: true }],
		start_time: [{ required: true, type: "date" }],
		end_time: [
			{ type: "date" },
			endTimeGreaterThanStartTime
		],
		notes: [{ required: true }],
		approx_patient_weight_kg: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('patient weight'),
			}
		],
		dose: [
			{
				required: true,
			},
			{
				validator: validateFieldIsGreaterThan('dose'),
			}
		],
		route_id: [{ required: true }],
		is_prn: [{ required: false, type: 'boolean' }],
		prn_condition: []
	}
	const showWeight = patientWeight === undefined

	const getReviewDose = () => {
		if (options.default_cri_unit === doseUnit) {
			return dose;
		}

		return doseUnit === 'mcg/kg/min' ? dose / 60 * 1000 : dose;
	}
	

	return (
		<>
			<MedicationWeight
				autoFocus={showWeight}
				disabled={false}
				fromEstimate={fromEstimate}
				existingInstruction={existingInstruction}
				rules={criOrderRules.approx_patient_weight_kg}
				onChange={(value) => {
					onFormChange({
						approx_patient_weight_kg: value,
					});
					setApproxPatientWeight(value ?? undefined);
				}}
				patientWeight={patientWeight}
			/>
			<MedicationType
				type="cri"
				onFormChange={onFormChange} // Needed to set initial value
			/>

			<Row style={{ width: '100%' }} align='middle'>
				<Col span={6}>Concentration:</Col>
				<Col>
					<b>
						{roundTo(options.medication.numerator_value, 3)}
						{options.medication.numerator_unit}/
						{options.medication.denominator_value}
						{options.medication.denominator_unit}
					</b>
				</Col>
			</Row>
			<>
				<Row
					justify='start'
					align='middle'
					style={{ width: '100%' }}
					gutter={[8, 8]}
				>
					<Col span={6}>Dose:</Col>
					<Col span={8}>
						<Form.Item
							rules={criOrderRules.dose}
							name='dose'
							preserve={false}
							style={formItemStyle}
							wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
						>
							<InputNumber
								autoFocus={!showWeight}
								disabled={false}
								style={formItemStyle}
								onChange={(value) => {
									const zeroedValue = value || 0;
									updateDose(zeroedValue)
								}}
								min={0}
								precision={doseUnit === 'mcg/kg/min' ? 1 : 3}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item name='dose_unit'>
							{doseUnit ? (
								<Select
									disabled={false}
									options={[
										{ label: 'mg/kg/hour (Standard)', value: 'mg/kg/hr' },
										{ label: 'mcg/kg/min', value: 'mcg/kg/min' },
									]}
									value={doseUnit}
									onChange={(value) => {
										setDoseUnit(oldState => {
											if (dose) {
												if (oldState === 'mcg/kg/min') {
													updateDose(dose / 1000 * 60);
												} else {
													updateDose(dose * 1000 / 60);
												}
											}
											
											return value
										});
									}}
								/>
							) : (
								`${options.medication.numerator_unit}/kg/hour`
							)}
						</Form.Item>
					</Col>
				</Row>
			</>

			<Form.Item
				rules={criOrderRules.fluids_id}
				preserve={false}
				name='fluids_id'
				label='Diluent'
				style={formItemStyle}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 10 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Select
					disabled={false}
					onSelect={(value: number) => {
						setFluidsId(value);
						const newLabel = `${options.medication_name} ${fluidVolume ? 'qs ' + fluidVolume + 'mL' : ''
							}  ${fluidOptions.find((fluid) => fluid.id === value)
								?.abbreviation ??
							fluidOptions.find((fluid) => fluid.id === value)
								?.name
							}`;

						updateBagLabel(newLabel);
						onFormChange({})
					}}
				>
					{fluidOptions?.filter(option => !option.name.includes('Oxygen')).map((fluidOption) => (
						<Option
							className='rdvm-select'
							value={fluidOption.id}
							key={`complaint_${fluidOption.id}`}
						>
							{fluidOption.name}
						</Option>
					))}
				</Select>
			</Form.Item>

			<>
				<Row
					justify='start'
					align='middle'
					style={{ width: '100%' }}
					gutter={[8, 8]}
				>
					<Col span={6}>CRI Volume:</Col>
					<Col>
						<Form.Item
							rules={criOrderRules.fluids_volume_ml}
							preserve={false}
							name='fluids_volume_ml'
							style={formItemStyle}
							wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
						>
							<InputNumber
								style={formItemStyle}
								onChange={(value) => {
									const zeroedValue = value || 0;
									setFluidVolume(zeroedValue);

									const newLabel = `${options.medication_name
										} ${value ? 'qs ' + value + 'mL' : ''}  ${fluidOptions.find(
											(fluid) => fluid.id === fluidsId,
										)?.abbreviation ??
										fluidOptions.find(
											(fluid) => fluid.id === fluidsId,
										)?.name
										}`;

									updateBagLabel(newLabel);
								}}
								disabled={
									!fluidsId ||
									fluidsId === BLANK_FLUID_OPTION.id
								}
								min={0}
							/>
						</Form.Item>
					</Col>
					<Col>mL</Col>
				</Row>
			</>

			<>
				<Row
					justify='start'
					align='middle'
					style={{ width: '100%' }}
					gutter={[8, 8]}
				>
					<Col span={6}>Fluid rate:</Col>
					<Col>
						<Form.Item
							rules={criOrderRules.rate_ml_per_hr}
							preserve={false}
							name='rate_ml_per_hr'
							style={formItemStyle}
							wrapperCol={{ span: 24 }} //Width of INPUT, in "columns" (like <Col span={12})
						>
							<InputNumber
								style={formItemStyle}
								onChange={(value) => {
									const zeroedValue = value || 0;
									setFluidRate(zeroedValue);
								}}
								min={0}
								disabled={
									!fluidsId ||
									fluidsId === BLANK_FLUID_OPTION.id
								}
							/>
						</Form.Item>
					</Col>
					<Col>mL/hour</Col>
				</Row>
			</>

			<Form.Item
				rules={criOrderRules.label}
				preserve={false}
				name='label'
				label='Label'
				style={formItemStyle}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input
					disabled={false}
					onChange={(e) => {
						updateBagLabel(e.target.value);
					}}
				/>
			</Form.Item>
			<MedicationStart
				rules={criOrderRules.start_time}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={12})
				onFormChange={onFormChange} // Only needed because we default a value for this field
				setStartTime={setStartTime}
			/>
			<MedicationEnd
				rules={criOrderRules.end_time}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={12})
				onFormChange={onFormChange} // Only needed because we default a value for this field
				setEndTime={setEndTime}
			/>

			<MedicationNote
				rules={criOrderRules.notes}
				labelCol={{ span: 6 }} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={{ span: 18 }} //Width of INPUT, in "columns" (like <Col span={12})
			/>

			<Divider className='cri-form-divider' />
			<h3>Review:</h3>
			<CriOrderReview
				numerator_unit={options.medication.numerator_unit}
				numerator_value={options.medication.numerator_value}
				denominator_unit={options.medication.denominator_unit}
				fluidRate={fluidRate}
				dose={getReviewDose()}
				dose_unit={doseUnit}
				patientWeight={patientWeight ?? approxPatientWeight}
				startTime={startTime ?? getFormData().start_time}
				endTime={endTime ?? getFormData().end_time}
				fluidVolume={fluidVolume}
				fluidsId={fluidsId}
			/>
			<Form.Item
				preserve={false}
				hidden
				name={
					patientWeight
						? 'latest_patient_weight_kg'
						: 'approx_patient_weight_kg'
				}
				style={formItemStyle}
				labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<InputNumber min={0} disabled />
			</Form.Item>

			<Form.Item
				preserve={false}
				hidden
				name='id'
				label='id'
				style={formItemStyle}
				labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input disabled />
			</Form.Item>
			<Form.Item
				preserve={false}
				hidden
				name='medication_id'
				label='medication_id'
				style={formItemStyle}
				labelCol={props.labelCol} //Width of LABEL, in "columns" (like <Col span={4})
				wrapperCol={props.wrapperCol} //Width of INPUT, in "columns" (like <Col span={12})
			>
				<Input disabled />
			</Form.Item>

			<HiddenInput fieldName='is_prn' />
			<HiddenInput fieldName='prn_condition' />
			<HiddenInput fieldName={'ordered_by'}></HiddenInput>
			<HiddenInput fieldName={'id'}></HiddenInput>
			<HiddenInput fieldName={'priority'}></HiddenInput>
			<HiddenInput fieldName="userId" initialValue={loggedInUserData?.user_id} />
			<HiddenInput fieldName="fromEstimate" initialValue={fromEstimate} />
			{/* TODO Look at a simpler way of doing this, this code is copied is CriOrder, MedicineOrder, FluidOrder, and DiagnosticOrder */}
		</>
	);
};

interface CalculateCriNumbersProps {
	patientWeight: number;
	dose: number;
	instructionDuration: number;
	fluid: {
		fluidsId: number | null;
		fluidVolume: number | null;
		fluidRate: number | null;
	};
	doseUnit?: string | null;
}
export const calculateCriNumbers = ({
	patientWeight,
	dose,
	instructionDuration,
	fluid,
	doseUnit
}: CalculateCriNumbersProps) => {
	const { fluidVolume, fluidsId, fluidRate } = fluid;

	if (doseUnit === 'mcg/kg/min') {
		dose = dose * 60 / 1000;
	}

	const hoursRemainInBag =
		fluidVolume &&
			fluidsId &&
			fluidsId !== BLANK_FLUID_OPTION.id &&
			fluidRate
			? fluidVolume / fluidRate
			: instructionDuration;

	const hourlyMassDose = patientWeight * dose;

	const numPreparations = instructionDuration === Infinity
		? Infinity
		: instructionDuration / hoursRemainInBag;

	const massOfDrug = hourlyMassDose * hoursRemainInBag;

	return {
		hoursRemainInBag,
		hourlyMassDose,
		numPreparations,
		massOfDrug,
	};
};

interface CriOrderReviewProps {
	numerator_unit: string | null;
	numerator_value: number | null;
	denominator_unit: string;
	fluidRate: number;
	dose: number;
	dose_unit?: string | null;
	patientWeight: number;
	endTime?: moment.Moment;
	startTime?: moment.Moment;
	fluidsId: number | null;
	fluidVolume: number | null;
}
export const CriOrderReview = ({
	numerator_unit,
	numerator_value,
	denominator_unit,
	fluidRate,
	dose,
	dose_unit,
	patientWeight,
	endTime,
	startTime,
	fluidsId,
	fluidVolume
}: CriOrderReviewProps) => {
	const instructionDuration = endTime
			? endTime.diff(startTime, 'hours')
			: Infinity;
	const { hourlyMassDose, hoursRemainInBag, numPreparations, massOfDrug } =
		calculateCriNumbers({
			patientWeight,
			dose,
			instructionDuration,
			fluid: {
				fluidsId,
				fluidVolume,
				fluidRate,
			},
			doseUnit: dose_unit,
		});
	const getDiluentVolume = (fluidVolume: number | null, numerator_value: number | null, massOfDrug: number) => {
		const finalFluidVolume = fluidVolume ?? 0
		let finalMassOfDrug = 0
		if (numerator_value && massOfDrug > 0 && massOfDrug !== Infinity) {
			finalMassOfDrug = roundTo(massOfDrug / numerator_value, 3)
		}
		const result = finalFluidVolume - finalMassOfDrug
		if (result > 0) {
			return `${result} mL`
		} else {
			return "Error"
		}
	};

	return (
		<>
			<Row style={{ width: '100%' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>Hourly dose: </Col>
						<Col>
							{roundTo(hourlyMassDose, 3)}
							{numerator_unit}
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row>
						<Col span={12}>Daily dose: </Col>
						<Col>
							{roundTo(hourlyMassDose * 24, 3)}
							{numerator_unit}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>Total duration:</Col>
						<Col>{instructionDuration === Infinity ? 'N/A' : `${instructionDuration} hours`}</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row>
						<Col span={12}># preparations:</Col>
						<Col>{numPreparations === Infinity ? 'N/A' : Math.ceil(numPreparations)}</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>Bag will last:</Col>
						<Col>{hoursRemainInBag === Infinity ? 'N/A' : `${roundTo(hoursRemainInBag, 3)} hours`}</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row>
						<Col span={12}>
							<u>
								<b>Dose:</b>
							</u>
						</Col>
						<Col>{`${roundTo(dose, 3)}`} {dose_unit ?? `${numerator_unit}/kg/hour`}</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ width: '100%', marginTop: '6px' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>
							<u>
								<b>Fluid rate:</b>
							</u>
						</Col>
						<Col>{`${roundTo(fluidRate, 2)} mL/hour`}</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row>
						<Col span={12}>
							<u>
								<b>Patient weight:</b>
							</u>
						</Col>
						<Col>{roundTo(patientWeight, 3)}kg</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ width: '100%', marginTop: '30px' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>
							<u>
								<b>Total CRI Volume:</b>
							</u>
						</Col>
						<Col>
							{ fluidVolume ? `${fluidVolume} mL` : 'N/A'}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>
							<u>
								<b>Med Volume:</b>
							</u>
						</Col>
						<Col>
							{numerator_value && massOfDrug > 0 && massOfDrug !== Infinity
								? `${roundTo(massOfDrug / numerator_value, 3)} ${denominator_unit}`
								: 'N/A'
							}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col span={12}>
					<Row>
						<Col span={12}>
							<u>
								<b>Diluent Volume:</b>
							</u>
						</Col>
						<Col>
							{roundTo(getDiluentVolume(fluidVolume, numerator_value, massOfDrug), 3)}
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
