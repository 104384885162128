import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Col, Dropdown, FormInstance, Menu, Modal, Row, Space, Typography } from 'antd';
import { DiagnosticOrder } from 'components/forms/DiagnosticOrder';
import { FluidOrder } from 'components/forms/FluidOrder';
import { MedCriOrder } from 'components/forms/MedCriOrder';
import { NonMedicalOrder } from 'components/forms/NonMedicalOrder';
import { TaskOrder } from 'components/forms/TaskOrder';
import { ToGoMedOrder } from 'components/forms/ToGoMedOrder';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useGetEstimatesByVisitIdNewQuery } from 'services/estimateService';
import { useGetInstructionOptionsQuery, useHideEstimateItemMutation, useHideEstimateItemNonMedMutation } from 'services/instructionService';
import { COOKIE_DURATION, FormName } from 'utils/constants';
import { checkCookieExists, deleteCookie } from 'utils/miscFuncs';
import {
    isInstanceOfDiagnosticOption,
    isInstanceOfFluidOption,
    isInstanceOfMedicineSearchOption,
    isInstanceOfNonMedicalSearchOption,
    isInstanceOfTaskOption,
} from 'utils/types/InstructionOrderTypes';

import OxygenTherapyOrder from 'components/forms/OxygenTherapyOrder';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { next30Min } from 'utils/formFuncs';
import {
    Estimate,
    EstimateItemNew,
    isInstanceOfCriEstimateItemNew,
    isInstanceOfDiagnosticEstimateItemNew,
    isInstanceOfFluidEstimateItemNew,
    isInstanceOfMedicalEstimateItemNew,
    isInstanceOfNonMedicalEstimateItemNew,
    isInstanceOfOxygenTherapyEstimateItemNew,
    isInstanceOfTaskEstimateItemNew,
    isInstanceOfToGoMedEstimateItemNew,
} from 'utils/types/estimateTypesNew';
import { searchForOptionWithEstimate } from '../EstimatesNew/utils';
import { showNoChargeModal } from '../DischargeTab/utils';

interface PendingEstimateItemsProps {
    patientWeight?: number;
    modalForm: FormInstance<any>;
    updateIsInvoiceLocked: () => boolean;
}
export const PendingEstimateItems = (props: PendingEstimateItemsProps) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const { addComposeBox } = useComposeBoxContext();

    const { data: estimates } = useGetEstimatesByVisitIdNewQuery({ visitId });
    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const { data: instructionSearchOptions } = useGetInstructionOptionsQuery(null);

    const [hideEstimateItem] = useHideEstimateItemMutation();
    const [hideNonMedEstimateItem] = useHideEstimateItemNonMedMutation();

    const markEstimateAsIgnored = (estimateItem: EstimateItemNew) => {
        if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
            hideNonMedEstimateItem({
                visitId,
                nonMedOrderId: estimateItem.non_medical_order_id,
            });
        } else {
            hideEstimateItem({
                visitId,
                instructionId: estimateItem.instruction_id,
            });
        }
    };

    const estimateWriteButtonHandler = (estimateItem: EstimateItemNew) => {
        const searchOption = searchForOptionWithEstimate(instructionSearchOptions, estimateItem);
        if (!searchOption) {
            return;
        }

        if (!loggedInUserData) {
            return;
        }
        const now = moment();
        const startTime = next30Min(now).unix();
        const baseFields = {
            name: estimateItem.name,
            category: undefined,
            priority: undefined,
            start_time: startTime,
            end_time: undefined,
        };
        if (isInstanceOfDiagnosticOption(searchOption) && isInstanceOfDiagnosticEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['diag_order'],
                content: (
                    <DiagnosticOrder
                        options={searchOption}
                        existingInstruction={{
                            diagnostic_id: estimateItem.diagnostic_id,
                            frequency: estimateItem.frequency,
                            is_prn: estimateItem.is_prn,
                            prn_condition: estimateItem.prn_condition || undefined,
                            id: estimateItem.instruction_id,
                            type_id: estimateItem.type_id,
                            ...baseFields,
                        }}
                        fromEstimate={true}
                    />
                ),
            });
        } else if (isInstanceOfTaskOption(searchOption) && isInstanceOfTaskEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['task_order'],
                content: (
                    <TaskOrder
                        options={searchOption}
                        existingInstruction={{
                            task_id: estimateItem.task_id,
                            frequency: estimateItem.frequency,
                            is_prn: estimateItem.is_prn,
                            prn_condition: estimateItem.prn_condition || undefined,
                            id: estimateItem.instruction_id,
                            type_id: estimateItem.type_id,
                            ...baseFields,
                        }}
                        fromEstimate={true}
                    />
                ),
            });
        } else if (isInstanceOfFluidOption(searchOption) && isInstanceOfFluidEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['fluid_order'],
                content: (
                    <FluidOrder
                        options={searchOption}
                        existingInstruction={{
                            fluids_id: estimateItem.fluids_id,
                            fluids_volume_ml: estimateItem.fluids_volume_ml,
                            rate_ml_per_hr: estimateItem.rate_ml_per_hr,
                            route_id: estimateItem.route_id,
                            is_prn: estimateItem.is_prn,
                            prn_condition: estimateItem.prn_condition || undefined,
                            id: estimateItem.instruction_id,
                            type_id: estimateItem.type_id,
                            ...baseFields,
                        }}
                        fromEstimate={true}
                        form={props.modalForm}
                    />
                ),
            });
        } else if (isInstanceOfMedicineSearchOption(searchOption) && isInstanceOfMedicalEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['med_order'],
                content: (
                    <MedCriOrder
                        options={searchOption}
                        existingInstruction={{
                            medication_id: estimateItem.medication_id,
                            route_id: estimateItem.route_id,
                            dose: estimateItem.dose,
                            frequency: estimateItem.frequency,
                            is_prn: estimateItem.is_prn,
                            prn_condition: estimateItem.prn_condition || undefined,
                            id: estimateItem.instruction_id,
                            type_id: estimateItem.type_id,
                            latest_patient_weight_kg: estimateItem.latest_patient_weight_kg,
                            approx_patient_weight_kg: estimateItem.approx_patient_weight_kg,
                            ...baseFields,
                        }}
                        patientWeight={props.patientWeight}
                        fromEstimate={true}
                        defaultCri={false}
                    />
                ),
            });
        } else if (isInstanceOfMedicineSearchOption(searchOption) && isInstanceOfCriEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['med_order'],
                content: (
                    <MedCriOrder
                        options={searchOption}
                        existingInstruction={{
                            medication_id: estimateItem.medication_id,
                            fluids_volume_ml: estimateItem.fluids_volume_ml || undefined,
                            fluids_id: estimateItem.fluids_id,
                            rate_ml_per_hr: estimateItem.rate_ml_per_hr,
                            dose:
                                estimateItem.type_id === 'C' && estimateItem.default_cri_unit === 'mcg/kg/min'
                                    ? (estimateItem.dose * 1000) / 60
                                    : estimateItem.dose,
                            dose_unit: estimateItem.type_id === 'C' ? estimateItem.default_cri_unit ?? '' : '',
                            latest_patient_weight_kg: estimateItem.latest_patient_weight_kg,
                            approx_patient_weight_kg: estimateItem.approx_patient_weight_kg,
                            is_prn: estimateItem.is_prn,
                            prn_condition: estimateItem.prn_condition || undefined,
                            id: estimateItem.instruction_id,
                            ...baseFields,
                        }}
                        patientWeight={props.patientWeight}
                        defaultCri={true}
                        fromEstimate={true}
                    />
                ),
            });
        } else if (isInstanceOfNonMedicalSearchOption(searchOption) && isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
            if (props.updateIsInvoiceLocked) {
                const isInvoiceLocked = props.updateIsInvoiceLocked();
                if (isInvoiceLocked) {
                    showNoChargeModal(() => {
                        addComposeBox({
                            formName: FormName['non_medical_order'],
                            content: (
                                <NonMedicalOrder
                                    options={searchOption}
                                    existingOrder={{
                                        id: estimateItem.non_medical_order_id,
                                        non_medical_id: estimateItem.non_medical_id,
                                        type_id: estimateItem.type_id,
                                        unit: estimateItem.unit,
                                        quantity: estimateItem.quantity,
                                        cents: estimateItem.unit_cost_cents,
                                        ...baseFields,
                                    }}
                                    fromEstimate={true}
                                    skipModal
                                />
                            ),
                        });
                    });
                    return;
                }
            }
            addComposeBox({
                formName: FormName['non_medical_order'],
                content: (
                    <NonMedicalOrder
                        options={searchOption}
                        existingOrder={{
                            id: estimateItem.non_medical_order_id,
                            non_medical_id: estimateItem.non_medical_id,
                            type_id: estimateItem.type_id,
                            unit: estimateItem.unit,
                            quantity: estimateItem.quantity,
                            cents: estimateItem.unit_cost_cents,
                            ...baseFields,
                        }}
                        fromEstimate={true}
                    />
                ),
            });
        } else if (isInstanceOfMedicineSearchOption(searchOption) && isInstanceOfToGoMedEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['med_order'],
                content: (
                    <ToGoMedOrder
                        options={{
                            id: estimateItem.instruction_id,
                            quantity: estimateItem.quantity,
                            dispense_unit: estimateItem.dispense_unit,
                            ...baseFields,
                        }}
                    />
                ),
            });
        } else if (isInstanceOfFluidOption(searchOption) && isInstanceOfOxygenTherapyEstimateItemNew(estimateItem)) {
            addComposeBox({
                formName: FormName['oxygen_therapy_order'],
                content: (
                    <OxygenTherapyOrder
                        options={searchOption}
                        existingInstruction={{
                            fluids_id: estimateItem.fluids_id,
                            oxygen_quantity: estimateItem.oxygen_quantity,
                            is_prn: estimateItem.is_prn,
                            prn_condition: estimateItem.prn_condition || undefined,
                            id: estimateItem.instruction_id,
                            type_id: estimateItem.type_id,
                            ...baseFields,
                        }}
                    />
                ),
            });
        }
    };
    const rows = estimates
        ?.filter((estimate: Estimate) => {
            return estimate?.estimate_status === 'approved';
        })
        ?.map((estimate: Estimate) => {
            return estimate?.estimate_items
                ?.filter((estimateItem) => {
                    return (
                        estimateItem.type_id !== 'TGH' &&
                        !estimateItem.is_ordered &&
                        !estimateItem.is_processed &&
                        estimateItem.is_shown_on_tx_sheet
                    );
                })
                ?.map((estimateItem) => {
                    return (
                        <PendingEstimateItem
                            estimateItem={estimateItem}
                            estimate={estimate}
                            estimateWriteButtonHandler={estimateWriteButtonHandler}
                            markEstimateAsIgnored={markEstimateAsIgnored}
                            key={Math.random().toString(36).slice(2)}
                        />
                    );
                });
        })
        ?.flat();
    return <Row gutter={[12, 12]}>{rows}</Row>;
};

interface PendingEstimateItemProps {
    estimateItem: EstimateItemNew;
    estimate: Estimate;
    estimateWriteButtonHandler: (estimateItem: EstimateItemNew) => void;
    markEstimateAsIgnored: (estimateItem: EstimateItemNew) => void;
}
const PendingEstimateItem = ({ estimateItem, estimateWriteButtonHandler, markEstimateAsIgnored }: PendingEstimateItemProps) => {
    const { data: instructionSearchOptions } = useGetInstructionOptionsQuery(null);
    let name = estimateItem.name;
    if ('serial' in estimateItem && estimateItem.serial) {
        name = `${name} - Serial ${estimateItem.serial_hours} Hours`;
    }
    const searchOption = searchForOptionWithEstimate(instructionSearchOptions, estimateItem);
    const isNonMed = searchOption && isInstanceOfNonMedicalSearchOption(searchOption);
    let id = 0;
    if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
        id = estimateItem.non_medical_order_id;
    } else {
        id = estimateItem.instruction_id;
    }
    const overlay = (
        <Menu>
            <>
                <Menu.Item data-cy={'sendToTxSheet'} onClick={() => estimateWriteButtonHandler(estimateItem)}>
                    {isNonMed ? 'Add to Invoice' : 'Send to Tx Sheet'}
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        if (checkCookieExists('do_not_show_pending_estimate_warning_modal')) {
                            markEstimateAsIgnored(estimateItem);
                        } else {
                            warningModal();
                        }
                    }}
                >
                    {isNonMed ? 'Exclude from Invoice' : 'Exclude from Tx Sheet'}
                </Menu.Item>
            </>
        </Menu>
    );

    const warningModal = () =>
        Modal.confirm({
            title: isNonMed ? 'Exclude from Invoice' : 'Exclude from Treatment Sheet',
            maskClosable: false,
            autoFocusButton: 'ok',
            onOk: () => {
                markEstimateAsIgnored(estimateItem);
            },
            onCancel: () => {
                deleteCookie('do_not_show_pending_estimate_warning_modal=');
            },
            okText: isNonMed ? 'Exclude from Invoice' : 'Exclude from Tx Sheet',
            cancelText: 'Cancel',
            centered: true,
            content: (
                <>
                    {isNonMed && (
                        <Typography.Paragraph>
                            By excluding this from the invoice, the customer will not be charged for '{estimateItem.name}' on their final
                            bill.
                        </Typography.Paragraph>
                    )}
                    {!isNonMed && (
                        <Typography.Paragraph>
                            Usually orders are excluded from the Treatment Sheet because either{' '}
                            <Typography.Text strong>
                                the order is no longer necessary OR a similar order already exists because of a previously approved order.
                            </Typography.Text>
                        </Typography.Paragraph>
                    )}
                    <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                document.cookie = `do_not_show_pending_estimate_warning_modal=1;max-age=${COOKIE_DURATION}`;
                            } else {
                                deleteCookie('do_not_show_pending_estimate_warning_modal=');
                            }
                        }}
                    >
                        Do not show me this again
                    </Checkbox>
                </>
            ),
        });

    return (
        <>
            <Col span={10}>
                <Typography.Text>{name}</Typography.Text>
            </Col>
            <Col span={8}>{`${estimateItem.quantity} ${estimateItem.unit}`}</Col>
            <Col span={6} data-cy='pendingEstimateActionDropdown'>
                <Dropdown key={id} overlay={overlay}>
                    <Space style={{ color: 'var(--antd-blue)' }}>
                        Actions
                        <DownOutlined />
                    </Space>
                </Dropdown>
            </Col>
        </>
    );
};
