import { Col, FormInstance, Row } from 'antd';
import { DiagnosticOrder } from 'components/forms/DiagnosticOrder';
import { FluidOrder } from 'components/forms/FluidOrder';
import { MedCriOrder } from 'components/forms/MedCriOrder';
import OxygenTherapyOrder from 'components/forms/OxygenTherapyOrder';
import { TaskOrder } from 'components/forms/TaskOrder';
import { Button } from 'components/lib';
import { useOutsideClickAlert } from 'hooks';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { useAppDispatch } from 'hooks/useRedux';
import moment from 'moment';
import { RefObject } from 'react';
import { useGetInstructionOptionsQuery } from 'services/instructionService';
import { setBonusActionData, setIsBonusActionModalVisible } from 'store/slices/treatmentSheetSlice';
import { BASE_QUERY_OPTIONS, FormName, INITIAL_CONTEXT_MENU } from 'utils/constants';
import {
	BaseExistingInstruction,
	PimsUser,
	TsGroupType,
	isInstanceOfCRIInstruction,
	isInstanceOfExistingCriInstruction,
	isInstanceOfExistingDiagInstruction,
	isInstanceOfExistingFluidInstruction,
	isInstanceOfExistingOxygenTherapyInstruction,
	isInstanceOfExistingTaskInstruction,
	isInstanceOfMedicineInstruction,
} from 'utils/dataTypes';
import { filterClosedInstructions } from 'utils/filterFuncs';
import { next30Min } from 'utils/formFuncs';
import {
	BaseSearchOption,
	DiagnosticSearchOption,
	FluidSearchOption,
	MedicineSearchOption,
	TaskSearchOption,
} from 'utils/types/InstructionOrderTypes';
import { showNoChargeModal } from '../DischargeTab/utils';

interface ContextPopoverProps {
	closePopRef: RefObject<HTMLDivElement>;
	modalForm: FormInstance<any>;
	onModalFinish: (values: any, formName: FormName) => void;
	generateDiscontinueModalState: Function;
	generateAdjustFluidRateModalState: Function;
	user: PimsUser,
	setModalstate: Function;
	setContextMenuState: Function;
	visible: boolean;
	x: number;
	y: number;
	time: number;
	patientWeight: number;
	groupId: number;
	groups: (TsGroupType & {
		title: JSX.Element;
	})[];
	updateIsInvoiceLocked: () => boolean;
}
export const ContextPopover = ({
	closePopRef,
	setContextMenuState,
	modalForm,
	user,
	generateDiscontinueModalState,
	generateAdjustFluidRateModalState,
	onModalFinish,
	setModalstate,
	visible,
	x,
	y,
	time,
	patientWeight,
	groupId,
	groups,
	updateIsInvoiceLocked,
}: ContextPopoverProps) => {
	useOutsideClickAlert(closePopRef, () => {
		setContextMenuState(INITIAL_CONTEXT_MENU);
	});
	const dispatch = useAppDispatch();
	const {addComposeBox} = useComposeBoxContext();

	const { data } = useGetInstructionOptionsQuery(null, BASE_QUERY_OPTIONS);
	const orderOptions: BaseSearchOption[] | undefined = data;

	const fullGroup = groups.find(
		(group) => group.id === groupId,
	) as BaseExistingInstruction;
	const isInstructionOpen = fullGroup
		? filterClosedInstructions(fullGroup)
		: false;
	
	const prepareNewInstructionForm = (fullGroup: BaseExistingInstruction) => {
		let newInstructionFormName: string = "";
		let newInstructionForm: JSX.Element = <></>;

		const now = moment();
		fullGroup.start_time = next30Min(now).unix();

		if (fullGroup && isInstanceOfExistingDiagInstruction(fullGroup)) {
			//diagnostic
			const baseDiag = orderOptions?.find(
				(med) => med.id === fullGroup.diagnostic_id && med.type_id === 'D',
			);
	
			newInstructionFormName = FormName['diag_order'];
			newInstructionForm = (
				<DiagnosticOrder
					options={baseDiag as DiagnosticSearchOption}
					existingInstruction={fullGroup}
				/>
			);
		} else if (fullGroup && isInstanceOfExistingTaskInstruction(fullGroup)) {
			const baseDiag = orderOptions?.find(
				(med) => med.id === fullGroup.task_id && med.type_id === 'T',
			);
			//Task
			newInstructionFormName = FormName['task_order'];
			//TODO taks orders (when API has them)
			newInstructionForm = (
				<TaskOrder
					options={baseDiag as TaskSearchOption}
					existingInstruction={fullGroup}
				/>
			);
		} else if (fullGroup && isInstanceOfMedicineInstruction(fullGroup)) {
			//medicine
			newInstructionFormName = FormName['med_order'];
			const baseMedicine = orderOptions?.find(
				(med) => med.id === fullGroup.medication_id && med.type_id === 'M',
			);
			newInstructionForm = (
				<MedCriOrder
					options={baseMedicine as MedicineSearchOption}
					existingInstruction={fullGroup}
					patientWeight={patientWeight}
					defaultCri={false}
				/>
			);
		} else if (fullGroup && isInstanceOfExistingCriInstruction(fullGroup)){
			const baseDiag = orderOptions?.find(
				(med) => med.id === fullGroup.medication_id && med.type_id === 'M',
			) as MedicineSearchOption;
			newInstructionFormName = FormName['med_order'];
			newInstructionForm = (
				<MedCriOrder
					options={baseDiag}
					existingInstruction={fullGroup}
					patientWeight={patientWeight}
					defaultCri={true}
				/>
			);
		} else if (fullGroup && isInstanceOfExistingFluidInstruction(fullGroup)){
			const fluidOption = orderOptions?.find(
				(med) => med.id === fullGroup.fluids_id && med.type_id === 'F',
			) as FluidSearchOption
			newInstructionFormName = FormName['fluid_order'];
			newInstructionForm = (
				<FluidOrder
					options={fluidOption}
					existingInstruction={fullGroup}
					form={modalForm}
				/>
			);
		} else if (fullGroup && isInstanceOfExistingOxygenTherapyInstruction(fullGroup)) {
			const oxygenOption = orderOptions?.find(
				(order) => order.id === fullGroup.fluids_id && order.type_id === 'F',
			) as FluidSearchOption;
			const { id, ...newBodyWithoutInstructionId } = fullGroup;

			newInstructionFormName = FormName['oxygen_therapy_order'];
			newInstructionForm = (
				<OxygenTherapyOrder
					options={oxygenOption}
					existingInstruction={newBodyWithoutInstructionId}
				/>
			);
		}
		return {
			newInstructionFormName,
			newInstructionForm,
		};
	};

	const showBonusActionOption = () =>
		fullGroup?.type_id === 'M' ||
		fullGroup?.type_id === 'D' ||
		fullGroup?.type_id === 'T';

	
	const allowDiscontinue = (fullGroup && fullGroup.name === 'Weight') ? false : true;
	
	const showBonusActionModal = () => {
		dispatch(setIsBonusActionModalVisible(true));
		dispatch(setBonusActionData(fullGroup as any));
	};

	const openInstructionButtons = (
		<>
			{showBonusActionOption() && (
				<Col span={24}>
					<Button
						style={{ width: '100%' }}
						onClick={() => {
							setContextMenuState(INITIAL_CONTEXT_MENU);
							const isInvoiceLocked = updateIsInvoiceLocked();
							if (isInvoiceLocked) {
								showNoChargeModal(showBonusActionModal);
							} else {
								showBonusActionModal();
							}
						}}
					>
						Add Bonus Action
					</Button>
				</Col>
			)}
			{fullGroup &&
				(isInstanceOfExistingFluidInstruction(fullGroup) ||
				isInstanceOfExistingCriInstruction(fullGroup) ||
				isInstanceOfExistingOxygenTherapyInstruction(fullGroup)) &&
				<Col span={24}>
					<Button
						style={{ width: '100%' }}
						onClick={() => {
							setContextMenuState(INITIAL_CONTEXT_MENU);
							setModalstate(
								generateAdjustFluidRateModalState(
									fullGroup,
									user,
									modalForm,
									onModalFinish,
									setModalstate,
									patientWeight,
								),
							);
						}}
					>
						Adjust Rate
					</Button>
				</Col>
			}
			{allowDiscontinue && (<>
				<Col span={24}>
					<Button
						danger
						style={{ width: '100%' }}
						onClick={() => {
							setContextMenuState(INITIAL_CONTEXT_MENU);
							setModalstate(
								generateDiscontinueModalState(
									fullGroup as BaseExistingInstruction,
									user,
									modalForm,
									onModalFinish,
									setModalstate,
								),
							);

							if (isInstanceOfCRIInstruction(fullGroup) && fullGroup.default_cri_unit === 'mcg/kg/min') {
								fullGroup.dose = fullGroup.dose / 60 * 1000;
								fullGroup.dose_unit = 'mcg/kg/min';
							}

							const {newInstructionFormName, newInstructionForm} = prepareNewInstructionForm(fullGroup);
							addComposeBox({
								formName: newInstructionFormName,
								content: newInstructionForm,
							});
						}}
					>
						Discontinue + rewrite
					</Button>
				</Col>
				<Col span={24}>
					<Button
						danger
						type='primary'
						style={{ width: '100%' }}
						onClick={() => {
							setContextMenuState(INITIAL_CONTEXT_MENU);
							setModalstate(
								generateDiscontinueModalState(
									fullGroup as BaseExistingInstruction,
									user,
									modalForm,
									onModalFinish,
									setModalstate,
								),
							);
						}}
					>
						Discontinue
					</Button>
				</Col>
			</>)}
		</>
	);
	const closedInstructionButtons = (
		<Col span={24}>
			<Button
				danger
				style={{ width: '100%' }}
				onClick={() => {
					setContextMenuState(INITIAL_CONTEXT_MENU);
					const {newInstructionFormName, newInstructionForm} = prepareNewInstructionForm(fullGroup);
					addComposeBox({
						formName: newInstructionFormName,
						content: newInstructionForm,
					});
				}}
			>
				Re-order
			</Button>
		</Col>
	);

	const actionButtons = (
		<div className='ant-popover-inner-content'>
			<Row gutter={[16, 16]}>
				{isInstructionOpen
					? openInstructionButtons
					: closedInstructionButtons}
			</Row>
		</div>
	);
	const popContent = actionButtons;

	return (
		<div
			ref={closePopRef}
			className='ant-popover'
			style={{
				display: visible ? 'block' : 'none',
				position: 'absolute',
				left: x - 210,
				top: y,
				width: '200px',
			}}
		>
			<div className='ant-popover-content'>
				<div className='ant-popover-inner'>
					<div className='ant-popover-title ts-context-menu-title'>
						<Row>
							<Col>{fullGroup?.name}</Col>
						</Row>
					</div>
					{popContent}
				</div>
			</div>
		</div>
	);
};
