import { DownOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Dropdown, Form, Input, Menu, Modal, Radio, Row, Typography, message } from 'antd';
import OxygenTherapyOrder from 'components/forms/OxygenTherapyOrder';
import PackageFormModal, { PackageFormInstance } from 'components/forms/PackageFormModal';
import { DiagnosticEstimateForm } from 'components/forms/estimates/DiagnosticEstimate';
import { FluidEstimateForm } from 'components/forms/estimates/FluidEstimate';
import { MedCriEstimate } from 'components/forms/estimates/MedCriEstimate';
import { NonMedicalEstimateForm, getNonMedicalEstimateFormDefaults } from 'components/forms/estimates/NonMedicalEstimate';
import { TaskEstimateForm } from 'components/forms/estimates/TaskEstimate';
import { Ledger } from 'components/lib';
import moment from 'moment';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetUserDataQuery } from 'services/authService';
import { useGetVisitDiscountQuery } from 'services/billingService';
import {
    useAddNewEstimateMutation,
    useEditEstimateMutation,
    useLazyGetEstimatesByVisitIdNewQuery,
} from 'services/estimateService';
import { useGetInstructionOptionsQuery, useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { useCreateEstimateFormAndSendSMSMutation, useGetVisitByIdQuery } from 'services/visitService';
import { BASE_QUERY_OPTIONS, PATIENT_WEIGHT_INSTRUCTION_NAME } from 'utils/constants';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import { roundTo } from 'utils/formatFuncs';
import { getRecentVitalsFromInstructions } from 'utils/miscFuncs';
import {
    BaseSearchOption,
    isInstanceOfDiagnosticOption,
    isInstanceOfFluidOption,
    isInstanceOfMedicineSearchOption,
    isInstanceOfNonMedicalSearchOption,
    isInstanceOfPackageSearchOption,
    isInstanceOfTaskOption,
} from 'utils/types/InstructionOrderTypes';
import {
    DiagnosticEstimateFormFields,
    EstimateFormFields,
    FluidEstimateFormFields,
    MedicineEstimateFormFields,
    NonMedicalEstimateFormFields,
    OxygenTherapyEstimateFormFields,
    TaskEstimateFormFields,
    isInstanceOfCriEstimateFormFields,
    isInstanceOfDiagnosticEstimateFormFields,
    isInstanceOfMedicineEstimateFormFields,
    isInstanceOfNonMedicalEstimateFormFields,
    isInstanceOfOxygenTherapyEstimateFormFields,
    isInstanceOfTaskEstimateFormFields,
    isInstanceOfToGoMedEstimateFormFields,
} from 'utils/types/billingTypes';
import {
    Estimate,
    EstimateCreate,
    EstimateDiscontinuedItems,
    EstimateEdit,
    EstimateFreeItems,
    EstimateItemNew,
    EstimateStatus,
    isInstanceOfCriEstimateItemNew,
    isInstanceOfMedicalEstimateItemNew,
    isInstanceOfNonMedicalEstimateItemNew,
} from 'utils/types/estimateTypesNew';
import { validateFieldIsGreaterThan, validateFieldIsLessThan, validateFieldIsNotDecimal } from 'utils/validationFuncs';
import { EstimateModalTable } from '../EstimateTable';
import { EstimateTag } from '../EstimateTag';
import { SendEstimateModal } from '../SendEstimateModal';
import '../index.css';
import { calculateTotalEstimateCost, generateQuantity, isEstimateItemInsteadOfEstimateFormFields } from '../utils';
import { EstimateItemModal } from './EstimateItemModal';
import { EstimateOrderSearch } from './EstimateOrderSearch';

interface EstimateTabProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    estimate?: Estimate;
    setEditEstimate: React.Dispatch<React.SetStateAction<Estimate | undefined>>;
    newEstimate?: EstimateCreate;
    setNewEstimate: React.Dispatch<React.SetStateAction<EstimateCreate | undefined>>;
    isFirstEstimate: boolean;
}

const isEdited = (
    name: string | null,
    estimateList: (EstimateFormFields | EstimateItemNew)[],
    durationHours?: number,
    oldEstimate?: Estimate,
) => {
    if (!oldEstimate) {
        return true;
    }
    const nameCheck = name === oldEstimate.estimate_name;
    const durationHoursCheck = durationHours === oldEstimate.duration_hours;
    let estimateLengthCheck = estimateList.length === oldEstimate.estimate_items.length;
    let estimateCheck = estimateList.every((currentListItem, index) => {
        return JSON.stringify(currentListItem) === JSON.stringify(oldEstimate.estimate_items[index]);
    });

    return !(nameCheck && durationHoursCheck && estimateLengthCheck && estimateCheck);
};

export const EstimateModal = ({
    isOpen,
    setIsOpen,
    estimate,
    setEditEstimate,
    newEstimate,
    setNewEstimate,
    isFirstEstimate,
}: EstimateTabProps) => {
    const packageFormModalRef = useRef<ElementRef<typeof PackageFormModal>>(null);

    const title = estimate?.estimate_name ?? newEstimate?.name ?? 'New Estimate';
    const status: EstimateStatus = estimate?.estimate_status ?? 'draft';

    const [name, setName] = useState(estimate?.estimate_name ?? newEstimate?.name);

    const [loading, setLoading] = useState(false);

    const [durationHours, setDurationHours] = useState<number | undefined>(estimate?.duration_hours ?? newEstimate?.duration_hours ?? 1);

    const isInPatient = (estimate?.duration_hours ?? newEstimate?.duration_hours ?? 1) > 1;
    const [outPatientInPatient, setOutPatientInPatient] = useState<'out' | 'in'>(isInPatient ? 'in' : 'out');

    const [estimatesList, setEstimatesList] = useState<(EstimateFormFields | EstimateItemNew)[]>(estimate?.estimate_items ?? []);
    const [newItems, setNewItems] = useState<EstimateFormFields[]>(newEstimate?.estimate_items ?? []);
    const [freeItems, setFreeItems] = useState<EstimateFreeItems[]>([]);
    const [discontinuedItems, setDiscontinuedItems] = useState<EstimateDiscontinuedItems[]>([]);

    const [estimatesToDisplay, setEstimatesToDisplay] = useState<(EstimateFormFields | EstimateItemNew)[]>([...estimatesList, ...newItems]);
    useEffect(() => {
        setEstimatesToDisplay([...estimatesList, ...newItems]);
    }, [estimatesList, newItems, discontinuedItems]);

    const [subtotal, setSubtotal] = useState(0);

    const [isSendModalOpen, setIsSendModalOpen] = useState(false);

    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { data: currentVisit } = useGetVisitByIdQuery(visitId, BASE_QUERY_OPTIONS);

    const { data: existingCustomer } = useGetCustomerByIdQuery(currentVisit?.customer_id ?? '', BASE_QUERY_OPTIONS);

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    // const { data: taxAmountCents } = useGetEstimateTaxQuery(estimate?.estimate_id ?? 1, { skip: !estimate?.estimate_id });

    const [addNewEstimatePost] = useAddNewEstimateMutation();

    const [editEstimate] = useEditEstimateMutation();

    const [createAndSendEstimateForm] = useCreateEstimateFormAndSendSMSMutation();
    const [getEstimateByVisit] = useLazyGetEstimatesByVisitIdNewQuery();

    const { data: visitDiscount } = useGetVisitDiscountQuery(visitId);

    const { data: instructionsOptions } = useGetInstructionOptionsQuery(null);

    const { data: pastInstructions } = useGetInstructionsByVisitIdQuery({ visitId }, BASE_QUERY_OPTIONS);
    const vitalInstructions = getRecentVitalsFromInstructions(pastInstructions);

    const patientWeight =
        parseFloat(
            (vitalInstructions?.find((vital) => vital?.name === PATIENT_WEIGHT_INSTRUCTION_NAME)?.lastAction?.value as string) ?? '0',
        ) || undefined;
    const [searchOption, setSearchOptions] = useState<BaseSearchOption | undefined>();
    let [formJSX, setFormJSX] = useState(<></>);

    const emergencyVisitSet = useRef(false);

    useEffect(() => {
        if (isFirstEstimate && newItems.length === 0 && !emergencyVisitSet.current) {
            const emergencyVisit = instructionsOptions?.find((intr) => intr.name === 'Emergency Visit');

            if (!!emergencyVisit && isInstanceOfNonMedicalSearchOption(emergencyVisit)) {
                const { is_refund, ...nonMedicalItem } = getNonMedicalEstimateFormDefaults(emergencyVisit);

                submitEstimateItem(nonMedicalItem as NonMedicalEstimateFormFields);
            }
        }
    }, [isFirstEstimate, instructionsOptions, newItems]);

    useEffect(() => {
        if (newItems.some((item) => item.name === 'Emergency Visit')) {
            emergencyVisitSet.current = true;
        }
    }, [newItems]);

    useEffect(() => {
        if (!searchOption) {
            setFormJSX(<></>);
        } else if (isInstanceOfDiagnosticOption(searchOption)) {
            setFormJSX(<DiagnosticEstimateForm dataSource={searchOption} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} durationHours={durationHours} diagnostic={searchOption} />);
        } else if (isInstanceOfTaskOption(searchOption)) {
            setFormJSX(<TaskEstimateForm dataSource={searchOption} task={searchOption} durationHours={durationHours} />);
        } else if (isInstanceOfFluidOption(searchOption)) {
            if (searchOption.name.includes('Oxygen')) {
                setFormJSX(<OxygenTherapyOrder options={searchOption} fromEstimate />);
            } else {
                setFormJSX(<FluidEstimateForm dataSource={searchOption} />);
            }
        } else if (isInstanceOfMedicineSearchOption(searchOption)) {
            setFormJSX(<MedCriEstimate options={searchOption} patientWeight={patientWeight} durationHours={durationHours} />);
        } else if (isInstanceOfPackageSearchOption(searchOption)) {
            packageFormModalRef.current?.openModal(searchOption.id);
            setSearchOptions(undefined);
        } else if (isInstanceOfNonMedicalSearchOption(searchOption)) {
            setFormJSX(<NonMedicalEstimateForm dataSource={searchOption} />);
        } else {
            console.error(`Not Implemented for ${searchOption}`);
        }
    }, [searchOption, patientWeight]);

    useEffect(() => {
        const totalCost = estimatesToDisplay.reduce(calculateTotalEstimateCost, 0);
        setSubtotal(totalCost);
    }, [estimatesToDisplay]);

    useEffect(() => {
        const newEstimateList = newItems.map((estimateItem) => {
            let localSearchItem: BaseSearchOption | undefined = undefined;
            if (isInstanceOfCriEstimateFormFields(estimateItem)) {
                localSearchItem = instructionsOptions?.find((option) => {
                    return option.id === estimateItem.medication_id && option.type_id === 'M';
                });
            } else if (isInstanceOfDiagnosticEstimateFormFields(estimateItem)) {
                localSearchItem = instructionsOptions?.find((option) => {
                    return option.id === estimateItem.diagnostic_id && option.type_id === 'D';
                });
            } else if (isInstanceOfTaskEstimateFormFields(estimateItem)) { 
                localSearchItem = instructionsOptions?.find((option) => {
                    return option.id === estimateItem.task_id && option.type_id === 'T';
                });
            } else if (isInstanceOfMedicineEstimateFormFields(estimateItem)) {
                localSearchItem = instructionsOptions?.find((option) => {
                    return option.id === estimateItem.medication_id && option.type_id === 'M';
                });
            }

            let localEstimateItem: EstimateFormFields = {...estimateItem};
            if (
                localSearchItem &&
                (
                    isInstanceOfMedicineSearchOption(localSearchItem) ||
                    isInstanceOfTaskOption(localSearchItem) || 
                    isInstanceOfDiagnosticOption(localSearchItem)
                ) &&
                (
                    isInstanceOfDiagnosticEstimateFormFields(localEstimateItem) || 
                    isInstanceOfTaskEstimateFormFields(localEstimateItem) || 
                    isInstanceOfMedicineEstimateFormFields(localEstimateItem)
                )

            ) {
                const dose = isInstanceOfMedicineEstimateFormFields(localEstimateItem) ? localEstimateItem.dose : undefined;   
                const frequency = localEstimateItem.frequency;
                const start = 0;
                const end = (durationHours ?? 0) * 3600;
                const supplemental_cents = isInstanceOfMedicineEstimateFormFields(localEstimateItem) ? localEstimateItem.supplemental_cost_cents : undefined;
                const serial = checkIfSerialCheaper(localSearchItem, frequency,  start, end, supplemental_cents, dose) 
                if (serial){
                    localEstimateItem.serial = serial
                    localEstimateItem.serial_hours = localSearchItem.serial_hours
                    localEstimateItem.unit = 'hours';
                    localEstimateItem.unit_cost_cents = localSearchItem.price_cents_serial ?? 0;
                } else {
                    localEstimateItem.serial = serial
                    localEstimateItem.unit =  isInstanceOfMedicineSearchOption(localSearchItem) ? localSearchItem.dose_unit : 'unit';
                    localEstimateItem.unit_cost_cents = localSearchItem.cents

                }


            }
            const newQuantity = generateQuantity(localEstimateItem, durationHours, localSearchItem);
            return {
                ...localEstimateItem,
                quantity: newQuantity,
            };
        });
        setNewItems(newEstimateList);
    }, [durationHours, instructionsOptions]);

    const updateEstimateItem = (id: number, idType: 'timestamp' | 'instructionId' | 'nonMedicalOrderId', update: { is_free: boolean }) => {
        const newEstimatesList = [...newItems];
        const oldEstimateList = [...estimatesList];
        const oldFreeItems = [...freeItems];
        if (idType === 'timestamp') {
            const itemToUpdate = newEstimatesList.find((estimateItem) => {
                return estimateItem.timestamp === id;
            });
            if (itemToUpdate) {
                itemToUpdate.is_free = update.is_free;
            }
            setNewItems(newEstimatesList);
        } else {
            let newFreeItem: EstimateFreeItems | undefined = undefined;
            let updateIndex: number;
            if (idType === 'instructionId') {
                updateIndex = oldEstimateList.findIndex((estimateItem) => {
                    if (isEstimateItemInsteadOfEstimateFormFields(estimateItem) && !isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
                        return estimateItem.instruction_id === id;
                    }
                    return false;
                });
                if (estimatesList[updateIndex]) {
                    newFreeItem = {
                        instruction_id: id,
                        reason: null,
                        type_id: estimatesList[updateIndex].type_id,
                    };
                }
            } else {
                updateIndex = oldEstimateList.findIndex((estimateItem) => {
                    if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
                        return estimateItem.non_medical_order_id === id;
                    }
                    return false;
                });
                if (estimatesList[updateIndex]) {
                    newFreeItem = {
                        non_medical_order_id: id,
                        reason: null,
                        type_id: 'N',
                    };
                }
            }
            if (oldEstimateList[updateIndex] && newFreeItem !== undefined) {
                let newFreeItems: EstimateFreeItems[];
                if (update.is_free) {
                    newFreeItems = [...oldFreeItems, newFreeItem];
                } else {
                    newFreeItems = oldFreeItems;

                    const freeItemsRemoveIndex = oldFreeItems.findIndex((freeEst) => {
                        if ('non_medical_order_id' in freeEst && newFreeItem && 'non_medical_order_id' in newFreeItem) {
                            return freeEst.non_medical_order_id === newFreeItem.non_medical_order_id;
                        } else if ('instruction_id' in freeEst && newFreeItem && 'instruction_id' in newFreeItem) {
                            return freeEst.instruction_id === newFreeItem.instruction_id;
                        }
                        return false;
                    });

                    if (oldFreeItems[freeItemsRemoveIndex]) {
                        newFreeItems.splice(freeItemsRemoveIndex, 1);
                    }
                }
                setFreeItems(newFreeItems);

                const estimateItem = { ...oldEstimateList[updateIndex] };
                estimateItem.is_free = update.is_free;
                oldEstimateList[updateIndex] = estimateItem;
                setEstimatesList(oldEstimateList);
            }
        }
    };

    const removeEstimateItem = (id: number, idType: 'timestamp' | 'instructionId' | 'nonMedicalOrderId') => {
        let removeIndex: number;
        const newEstimatesList = [...newItems];
        const oldEstimateList = [...estimatesList];
        if (idType === 'timestamp') {
            removeIndex = newEstimatesList.findIndex((estimateItem) => {
                return estimateItem.timestamp === id;
            });
            if (removeIndex !== -1) {
                newEstimatesList.splice(removeIndex, 1);
            }
            setNewItems(newEstimatesList);
        } else {
            let newDiscontinuedItem: EstimateDiscontinuedItems | undefined = undefined;

            if (idType === 'instructionId') {
                removeIndex = oldEstimateList.findIndex((estimateItem) => {
                    if (isEstimateItemInsteadOfEstimateFormFields(estimateItem) && !isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
                        return estimateItem.instruction_id === id;
                    }
                    return false;
                });
                if (removeIndex !== -1 && oldEstimateList[removeIndex]) {
                    const updateItem = oldEstimateList[removeIndex];
                    let dose = null;
                    if (isInstanceOfCriEstimateItemNew(updateItem) || isInstanceOfMedicalEstimateItemNew(updateItem)) {
                        dose = updateItem.dose;
                    }
                    newDiscontinuedItem = {
                        instruction_id: id,
                        reason: null,
                        type_id: updateItem.type_id,
                        unit: updateItem.unit,
                        quantity: updateItem.quantity,
                        dose,
                        on_behalf_of: loggedInUserData?.user_id ?? 0,
                        serial: updateItem.serial ?? false,
                    };
                }
            } else {
                removeIndex = oldEstimateList.findIndex((estimateItem) => {
                    if (isInstanceOfNonMedicalEstimateItemNew(estimateItem)) {
                        return estimateItem.non_medical_order_id === id;
                    }
                    return false;
                });
                if (removeIndex !== -1 && oldEstimateList[removeIndex]) {
                    const updateItem = oldEstimateList[removeIndex];
                    newDiscontinuedItem = {
                        non_medical_order_id: id,
                        reason: null,
                        type_id: 'N',
                        unit: updateItem.unit,
                        quantity: updateItem.quantity,
                        dose: null,
                        on_behalf_of: loggedInUserData?.user_id ?? 0,
                        serial: updateItem.serial ?? false,
                    };
                }
            }
            if (newDiscontinuedItem) {
                const newDiscontinuedItems = [...discontinuedItems, newDiscontinuedItem];
                setDiscontinuedItems(newDiscontinuedItems);
            }
            if (removeIndex !== -1) {
                oldEstimateList.splice(removeIndex, 1);
            }
            setEstimatesList(oldEstimateList);
        }
    };

    const submitEstimateItem = (estimateFormFields: EstimateFormFields, searchOption?: BaseSearchOption) => {
        const quantity = generateQuantity(estimateFormFields, durationHours, searchOption);
        const now = moment.now();
        if ('calculator_type' in estimateFormFields) {
            const { calculator_type, dose_acknowledge, ...restOfEstimate } = estimateFormFields;
            setNewItems((newEstimateItem) => [...newEstimateItem, { ...restOfEstimate, quantity, timestamp: now }]);
        } else if (isInstanceOfToGoMedEstimateFormFields(estimateFormFields)) {
            const dispense_value = estimateFormFields.quantity;
            setNewItems((newEstimateItem) => [...newEstimateItem, { ...estimateFormFields, dispense_value, timestamp: now }]);
        } else if (isInstanceOfOxygenTherapyEstimateFormFields(estimateFormFields)) {
            setNewItems((newEstimateItem) => [
                ...newEstimateItem,
                {
                    ...estimateFormFields,
                    oxygen_quantity: roundTo(estimateFormFields.oxygen_quantity * 100, 0),
                    quantity,
                    timestamp: now,
                }
            ]);
        } else {
            setNewItems((newEstimateItem) => [...newEstimateItem, { ...estimateFormFields, quantity, timestamp: now }]);
        }
    };

    const onSave = (send: boolean) => {
        setLoading(true);
        if (estimate && !isEdited(name ?? null, estimatesToDisplay, durationHours, estimate) && send) {
            createAndSendEstimateForm({
                visitId,
                estimateId: estimate.estimate_id,
            }).then(() => {
                getEstimateByVisit({ visitId });
            });
        }
        const newEstimateItems = newItems.map((estimateItem) => {
            const { timestamp, ...restOfEstimate } = estimateItem;
            return restOfEstimate;
        });
        if (estimate) {
            const updateToEstimate: EstimateEdit = {
                ...estimate,
                name: name ?? null,
                new_items: newEstimateItems,
                free_items: freeItems,
                discontinued_items: discontinuedItems,
            };

            editEstimate({
                visitId,
                body: updateToEstimate,
                estimateId: estimate.estimate_id,
            }).then((resp) => {
                if ('error' in resp && 'status' in resp.error) {
                    message.error(`[Error ${resp.error.status}] Something went wrong. Unable to edit estimate.`);
                    setLoading(false);
                    setShowAlert(true);
                } else {
                    if (send && estimate) {
                        createAndSendEstimateForm({
                            visitId,
                            estimateId: estimate.estimate_id,
                        }).then(() => {
                            getEstimateByVisit({ visitId });
                        });
                    }
                    setIsOpen(false);
                    setEditEstimate(undefined);
                    setNewEstimate(undefined);
                }
            });
        } else {
            const addEstimate: EstimateCreate = {
                name: name ?? null,
                duration_hours: durationHours ?? 0,
                estimate_items: newEstimateItems,
                send,
            };
            addNewEstimatePost({ visitId, body: addEstimate }).then((resp) => {
                if ('error' in resp && 'status' in resp.error) {
                    message.error(`[Error ${resp.error.status}] Something went wrong. Unable to create estimate.`);
                    setLoading(false);
                    setShowAlert(true);
                } else {
                    if (send) {
                        // Once the estimate is returned we can send
                    }
                    setIsOpen(false);
                    setEditEstimate(undefined);
                    setNewEstimate(undefined);
                }
            });
        }
    };

    const onFinishPackage = (items: PackageFormInstance) => {
        const baseFields = {
            is_prn: false,
            prn_condition: null,
            priority: false,
            notes: null,
            reason: null,
            is_free: false,
            ordered_by: loggedInUserData?.user_id || 0,
        };

        items.diagnostics?.forEach((diag) => {
            const unit = diag.serial ? 'hours' : 'unit';
            submitEstimateItem({
                ...baseFields,
                type_id: 'D',
                diagnostic_id: diag.id,
                frequency: diag.frequency,
                name: diag.name,
                unit: unit,
                unit_cost_cents: diag.unit_cost_cents,
                serial: diag.serial,
                serial_hours: diag.serial_hours,
            } as DiagnosticEstimateFormFields);
        });
        items.fluids?.forEach((fluid) => {
            submitEstimateItem({
                ...baseFields,
                type_id: 'F',
                fluids_id: fluid.id,
                name: fluid.name,
                unit: 'minutes',
                unit_cost_cents: fluid.unit_cost_cents,
                route_id: fluid.route_id,
                rate_ml_per_hr: parseFloat(fluid.rate),
                fluids_volume_ml: parseFloat(fluid.volume),
                supplemental_cost_cents: fluid.supplemental_cost_cents,
                is_recurring_supplemental: fluid.is_recurring_supplemental,
            } as FluidEstimateFormFields);
        });
        items.medications?.forEach((med) => {
            const unit = med.serial ? 'hours' : med.dose_unit;

            submitEstimateItem({
                ...baseFields,
                type_id: 'M',
                medication_id: med.id,
                name: med.name,
                frequency: med.frequency,
                route_id: med.route_id,
                dose: med.dose_unit === med.numerator_unit ? med.numerator_value : med.numerator_value / med.concentration,
                dose_unit: med.dose_unit,
                unit,
                unit_cost_cents: med.unit_cost_cents,
                calculator_type: med.calculator_type,
                approx_patient_weight_kg: med.approx_patient_weight_kg,
                latest_patient_weight_kg: med.latest_patient_weight_kg,
                supplemental_cost_cents: med.supplemental_cost_cents,
                is_recurring_supplemental: med.is_recurring_supplemental,
                controlled_drug: med.controlled_drug,
                serial: med.serial,
                serial_hours: med.serial_hours,

            } as MedicineEstimateFormFields);
        });
        items.tasks?.forEach((task) => {
            const unit = task.serial ? 'hours' : 'unit';
            submitEstimateItem({
                ...baseFields,
                type_id: 'T',
                task_id: task.id,
                frequency: task.frequency,
                name: task.name,
                unit: unit,
                unit_cost_cents: task.unit_cost_cents,
                serial: task.serial,
                serial_hours: task.serial_hours,
            } as TaskEstimateFormFields);
        });
        items.nonMed?.forEach((nonMed) => {
            submitEstimateItem({
                type_id: 'N',
                non_medical_id: nonMed.id,
                quantity: parseInt(nonMed.quantity),
                name: nonMed.name,
                unit: 'unit',
                unit_cost_cents: nonMed.unit_cost_cents,
                is_free: false,
                reason: null,
            } as NonMedicalEstimateFormFields);
        });
        items.oxygen_therapy?.forEach((ot) => {
            submitEstimateItem({
                type_id: 'OT',
                fluids_id: ot.id,
                name: ot.name,
                unit: 'minutes',
                unit_cost_cents: ot.unit_cost_cents,
                oxygen_quantity: roundTo(parseFloat(ot.quantity) * 100, 0),
                supplemental_cost_cents: ot.supplemental_cost_cents,
                is_recurring_supplemental: ot.is_recurring_supplemental,
                ...baseFields,
            } as OxygenTherapyEstimateFormFields);
        });
    };

    const alertHeader = (
        <div style={{ textAlign: 'left' }}>
            <strong>An unknown error has occured!</strong>
        </div>
    );
    const alertDescription = (
        <div style={{ textAlign: 'left', marginTop: '16px' }}>
            <strong>DO NOT REFRESH THE BROWSER!</strong> Please review your orders and try again!
        </div>
    );

    const [showAlert, setShowAlert] = useState(false);
    const [form] = Form.useForm();

    return (
        <>
            <Modal
                visible={isOpen}
                title={title}
                onCancel={() => {
                    setIsOpen(false);
                    setEditEstimate(undefined);
                    setNewEstimate(undefined);
                }}
                onOk={() => {
                    setIsOpen(false);
                    setEditEstimate(undefined);
                    setNewEstimate(undefined);
                }}
                centered={true}
                width='75%'
                maskClosable={false}
                destroyOnClose={true}
                footer={
                    <Row justify='end'>
                        <Button
                            onClick={() => {
                                setIsOpen(false);
                                setEditEstimate(undefined);
                                setNewEstimate(undefined);
                            }}
                        >
                            Cancel
                        </Button>
                        <Dropdown.Button
                            icon={<DownOutlined />}
                            buttonsRender={([leftButton, rightButton]) => {
                                let newRightButton = rightButton;
                                if (loading) {
                                    newRightButton = <Button loading />;
                                }
                                return [leftButton, newRightButton];
                            }}
                            type={'primary'}
                            data-cy={'saveButton'}
                            disabled={
                                estimatesToDisplay.length === 0 ||
                                loading ||
                                !durationHours ||
                                (outPatientInPatient === 'in' && durationHours <= 1) ||
                                durationHours > 672
                            }
                            onClick={() => onSave(false)}
                            overlay={
                                <Menu>
                                    <Menu.Item disabled={estimatesToDisplay.length === 0} onClick={() => onSave(false)}>
                                        Save
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled={estimatesToDisplay.length === 0 || existingCustomer === undefined || !estimate}
                                        onClick={() => setIsSendModalOpen(true)}
                                    >
                                        {isEdited(name ?? null, estimatesToDisplay, durationHours, estimate) ? 'Save and Send' : 'Send'}
                                    </Menu.Item>
                                </Menu>
                            }
                            className='estimate-modal-save-dropdown'
                        >
                            Save
                        </Dropdown.Button>
                    </Row>
                }
            >
                {showAlert && <Alert message={alertHeader} description={alertDescription} type='error' showIcon />}

                <Form className='estimate-modal-layout' form={form}>
                    <Form.Item
                        labelAlign='left'
                        label='Status'
                        style={{
                            width: '100%',
                            maxWidth: '1200px',
                        }}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                    >
                        <EstimateTag status={status} approvedDate={null}></EstimateTag>
                    </Form.Item>
                    <Form.Item
                        labelAlign='left'
                        style={{
                            width: '100%',
                            maxWidth: '1200px',
                        }}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                        label='Estimate Name'
                        data-cy='estimateNameInput'
                    >
                        <Input
                            autoFocus
                            maxLength={100}
                            defaultValue={name ?? undefined}
                            onChange={(event) => {
                                setName(event.currentTarget.value || undefined);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        labelAlign='left'
                        style={{
                            width: '100%',
                            maxWidth: '1200px',
                        }}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 10 }}
                        tooltip={`
                    Duration is the number of hours the estimate covers.  Outpatient is assigned 1 hr duration for cost calculations only and not necessarily the time expected to be in the hospital.
                    `}
                        label='Duration'
                        required
                    >
                        <Row justify='center' align='middle'>
                            <Col span={15} lg={12} xl={15} xxl={12}>
                                <Radio.Group
                                    disabled={!!estimate}
                                    onChange={(e: any) => {
                                        setOutPatientInPatient(e.target.value);
                                        if (e.target.value === 'out') {
                                            setDurationHours(1);
                                        } else {
                                            setDurationHours(6);
                                        }
                                        form.resetFields(['duration']);
                                    }}
                                    value={outPatientInPatient}
                                >
                                    <Radio value={'out'}>
                                        Outpatient
                                        <Typography.Text
                                            style={{
                                                color: '#8C8C8C',
                                                fontSize: 12,
                                            }}
                                        >
                                            {' '}
                                            (1 hr)
                                        </Typography.Text>
                                    </Radio>
                                    <Radio value={'in'}>Inpatient</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={9} lg={12} xl={9} xxl={12}>
                                {outPatientInPatient === 'in' && (
                                    <Form.Item
                                        style={{
                                            padding: 0,
                                        }}
                                        name='duration'
                                        validateFirst={true}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Duration is required',
                                            },
                                            {
                                                validator: validateFieldIsGreaterThan('Duration', 1),
                                            },
                                            {
                                                validator: validateFieldIsLessThan('Duration', 673),
                                            },
                                            {
                                                validator: validateFieldIsNotDecimal('Duration'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!!estimate}
                                            defaultValue={durationHours}
                                            onChange={(e) => {
                                                if (!e.target.value) {
                                                    setDurationHours(undefined);
                                                }
                                                if (!isNaN(parseInt(e.target.value)) && Number.isInteger(parseFloat(e.target.value))) {
                                                    setDurationHours(parseInt(e.target.value));
                                                } else {
                                                    setDurationHours(undefined);
                                                }
                                            }}
                                            addonAfter={'hrs'}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </Form.Item>
                    <div className='estimate-modal-order'>
                        <EstimateOrderSearch
                            handleSelect={(searchOption: BaseSearchOption) => {
                                setSearchOptions(searchOption);
                            }}
                            vitalInstructions={vitalInstructions ?? []}
                        />
                    </div>
                    <EstimateModalTable
                        freeItems={freeItems}
                        estimateItems={estimatesToDisplay}
                        updateEstimateItem={updateEstimateItem}
                        removeEstimateItem={removeEstimateItem}
                        durationHours={durationHours}
                    />
                    <Row justify='end' className='estimate-modal-ledger-container'>
                        <Alert
                            message='Sales tax will be calculated once the estimate is saved. Sales tax will appear on the consent form shared with the customer.'
                            type='warning'
                            showIcon
                        />

                        <Ledger
                            subtotal={durationHours ? subtotal : 0}
                            // TODO: Add tax calculation when it's possible to get tax per item
                            // tax={taxAmountCents ?? 0}
                            tax={0}
                            visitDiscount={visitDiscount}
                            paid={0}
                            type={'estimate'}
                            visitId={visitId}
                            hideTax
                        />
                    </Row>
                </Form>

                <EstimateItemModal
                    searchOption={searchOption}
                    formJSX={formJSX}
                    setSearchOptions={setSearchOptions}
                    onSubmit={(estimateFormFields: EstimateFormFields, searchOption?: BaseSearchOption) => {
                        if (isInstanceOfNonMedicalEstimateFormFields(estimateFormFields)) {
                            submitEstimateItem(
                                { ...estimateFormFields, quantity: parseInt(estimateFormFields.quantity.toString()) },
                                searchOption,
                            );
                        } else {
                            submitEstimateItem(estimateFormFields, searchOption);
                        }
                        setSearchOptions(undefined);
                    }}
                />

                <SendEstimateModal
                    onOk={() => {
                        onSave(true);
                    }}
                    isOpen={isSendModalOpen}
                    setIsOpen={setIsSendModalOpen}
                    contact={existingCustomer}
                />
            </Modal>
            <PackageFormModal ref={packageFormModalRef} onFinish={onFinishPackage} fromEstimate durationHours={durationHours} />
        </>
    );
};

export default EstimateModal;
