import { FALLBACK_MED_ROUTES } from 'utils/constants';
import { INSTRUCTION_TYPES, ResultEntry, RESULT_ENTRY_WIDGETS } from '../dataTypes';
import { roundTo } from 'utils/formatFuncs';

export type INSTRUCTION_CALCULATOR_TYPES = 'complex' | 'simple' | 'prepackaged';

export type ORDER_SEARCH_OPTION_TYPE = Array<BaseSearchOption>;

/**
 * THINGS FROM `GET` REQUESTS
 */
export interface BaseSearchOption {
    id: number;
    name: string;
    type_id: INSTRUCTION_TYPES;
    cents: number | null;
    default_duration_mins: number | null;
    price_id: number;
    price_type: string;
    supplemental_id: number | null;
    supplemental_name: string | null;
    supplemental_cents: number | null;
    hide_item?: boolean | null;
}

export interface BaseMedicineFields {
    form: string;
    brand_name: string;
    generic_name: string;
    dose_unit: string;

    denominator_unit: string;
    denominator_value: number;
    numerator_unit: string | null;
    numerator_value: number | null;
}

export interface MedicineSearchOption extends BaseSearchOption, BaseMedicineFields {
    calculator_type: INSTRUCTION_CALCULATOR_TYPES;
    controlled_drug: boolean;
    type_id: 'M';
    default_dose: number | null;
    default_dose_unit: string;
    default_duration_mins: number | null;
    default_frequency: string | null;
    default_route: typeof FALLBACK_MED_ROUTES[number] | null;
    low_dose_alert?: number;
    high_dose_alert?: number;
    serial: boolean;
    serial_hours: number | null;
    price_id_serial: number| null;
    price_cents_serial: number| null;

}

export const isInstanceOfMedicineSearchOption = (item?: BaseSearchOption | null): item is MedicineSearchOption =>
    !!item && (item as MedicineSearchOption).type_id === 'M';

export interface NonMedicalSearchOption extends BaseSearchOption {
    type_id: 'N';
    unit: string;
    has_reason: boolean;
}

export const isInstanceOfNonMedicalSearchOption = (item: any): item is NonMedicalSearchOption => item.type_id === 'N';

export interface DiagnosticSearchOption extends BaseSearchOption {
    type_id: 'D';
    default_duration_mins: null | number;
    default_frequency: null | string;
    widget: RESULT_ENTRY_WIDGETS;
    serial: boolean;
    serial_hours: number | null;
    price_id_serial: number| null;
    price_cents_serial: number| null;
}

export const isInstanceOfDiagnosticOption = (item: any): item is DiagnosticSearchOption =>
    !isInstanceOfMedicineSearchOption(item) && (item as DiagnosticSearchOption).type_id === 'D';

export interface TaskSearchOption extends BaseSearchOption {
    type_id: 'T';
    default_duration_mins: null | number;
    default_frequency: null | string;
    serial: boolean;
    serial_hours: number | null;
    price_id_serial: number| null;
    price_cents_serial: number| null;
}

export const isInstanceOfTaskOption = (item: any): item is TaskSearchOption => (item as TaskSearchOption).type_id === 'T';

export interface CriSearchOption extends BaseSearchOption {
    fluids_id: number | null; //straight medication will have NO fluid
    fluids_volume_ml: number; //straight medication will have NO fluid
    medication_id: number;
    medication_name: string;
    type_id: 'C';
}

export const isInstanceOfCriOption = (item: any): item is CriSearchOption =>
    !isInstanceOfMedicineSearchOption(item) && (item as CriSearchOption).type_id === 'C';

interface APICri {
    default_rate_mcl_per_hr: null | number; //Rate of FLUID, sent to database
}

interface FrontendCri {
    default_rate_ml_per_hr: null | number; //Rate of FLUID infusion, set on pump
}

export interface APIEnrichedCriSearchOption extends CriSearchOption, APICri {
    fluid?: FluidSearchOption; //Full entry for fluid
    medication: MedicineSearchOption; //Full entry for medication
    default_dose: number | null;
    default_duration_mins: number | null;
}

export interface EnrichedCriSearchOption extends CriSearchOption, FrontendCri {
    fluid?: FluidSearchOption; //Full entry for fluid
    medication: MedicineSearchOption; //Full entry for medication
    default_dose: number | null;
    default_duration_mins: number | null;
    default_cri_unit?: string | null;
    controlled_drug: boolean;
}

export const isInstanceOfAPIEnrichedCriSearchOption = (item?: any): item is APIEnrichedCriSearchOption =>
    !!item &&
    (item as APIEnrichedCriSearchOption).type_id === 'C' &&
    (item as APIEnrichedCriSearchOption).default_rate_mcl_per_hr !== undefined &&
    !!(item as APIEnrichedCriSearchOption).medication;

export const isInstanceOfEnrichedCriSearchOption = (item?: any): item is EnrichedCriSearchOption =>
    !!item &&
    (item as EnrichedCriSearchOption).type_id === 'C' &&
    (item as EnrichedCriSearchOption).default_rate_ml_per_hr !== undefined &&
    !!(item as EnrichedCriSearchOption).medication;

interface APIFluids {
    default_rate_mcl_per_hr: null | number; //Rate of FLUID, sent to database
}

interface FrontendFluids {
    default_rate_ml_per_hr: null | number; //Rate of FLUID infusion, set on pump
}

export interface APIFluidSearchOption extends BaseSearchOption, APIFluids {
    type_id: 'F';
    abbreviation: string | null;
    default_duration_mins: null | number;
    default_volume_ml: null | number;
}

export interface FluidSearchOption extends BaseSearchOption, FrontendFluids {
    type_id: 'F';
    abbreviation: string | null;
    default_duration_mins: null | number;
    default_volume_ml: null | number;
}

export const isInstanceOfAPIFluidOption = (item?: any): item is APIFluidSearchOption =>
    !!item && (item as APIFluidSearchOption).type_id === 'F' && (item as APIFluidSearchOption).default_rate_mcl_per_hr !== undefined;

export const isInstanceOfFluidOption = (item?: any): item is FluidSearchOption => !!item && (item as FluidSearchOption).type_id === 'F';

export const backendFluidOptionToFrontendFluidOption = (fluid: APIFluidSearchOption): FluidSearchOption => {
    const { default_rate_mcl_per_hr, ...restofFluid } = fluid;
    return {
        ...restofFluid,
        default_rate_ml_per_hr: default_rate_mcl_per_hr ? roundTo(default_rate_mcl_per_hr / 1000, 2) : null,
    };
};

export interface PackageSearchOption extends BaseSearchOption {
    type_id: 'P';
}

export const isInstanceOfPackageSearchOption = (item?: BaseSearchOption): item is PackageSearchOption =>
    !!item && (item as PackageSearchOption).type_id === 'P';

export interface IHDResultsFile {
    url: string;
    metadata: {
        result_url: string;
    };
}

/**
 * Packages don't REALLY exist in the DB... just a list of items within the package (and start_delay)
 * We just add one field to keep track of things in our Modal-form
 * This Type only really gets used in the Modal-form, so this type blends GET and PUT/POST a little bit
 */
export interface PackageMedInstruction extends BasePackageItem, BaseOutgoingOrder, MedicineSearchOption, OutgoingMedicineOrder {
    type_id: 'M';
}

export const isInstanceOfPackageMedInstruction = (item?: any): item is PackageMedInstruction =>
    !!item && (item as PackageMedInstruction).type_id === 'M' && (item as PackageMedInstruction).toggled !== undefined;

export const isInstanceOfPackageNonMedInstruction = (item?: any): item is PackageNonMedInstruction =>
    !!item && (item as PackageNonMedInstruction).type_id === 'N' && (item as PackageNonMedInstruction).toggled !== undefined;

export const isInstanceOfPackageDiagnosticInstruction = (item?: any): item is PackageDiagnosticInstruction =>
    !!item && (item as PackageDiagnosticInstruction).type_id === 'D' && (item as PackageDiagnosticInstruction).toggled !== undefined;

export const isInstanceOfPackageFluidMedInstruction = (item?: any): item is PackageFluidInstruction =>
    !!item && (item as PackageFluidInstruction).type_id === 'F' && (item as PackageFluidInstruction).toggled !== undefined;
export const isInstanceOfPackageTaskInstruction = (item?: any): item is PackageTaskInstruction =>
    !!item && (item as PackageTaskInstruction).type_id === 'T' && (item as PackageTaskInstruction).toggled !== undefined;

export interface PackageDiagnosticInstruction extends BasePackageItem, BaseOutgoingOrder {
    diagnostic_id: number;
    result_entry: ResultEntry;

    type_id: 'D';

    serial: boolean;
    serial_hours: number | null;
    price_cents_serial: number | null;

}

export interface PackageTaskInstruction extends BasePackageItem, BaseOutgoingOrder {
    task_id: number;
    type_id: 'T';

    serial: boolean;
    serial_hours: number | null;
    price_cents_serial: number | null;

}

export interface PackageNonMedInstruction extends BasePackageItem, BaseOutgoingOrder {
    type_id: 'N';
    name: string;
    non_med_id: number;
    quantity?: number;
    unit: string;
    has_reason: boolean;

    serial: boolean;
    serial_hours: number | null;
    price_cents_serial: number | null;

}

interface APIPackageCri {
    rate_mcl_per_hr: number; //Rate of FLUID, sent to database
}

interface FrontendPackageCri {
    rate_ml_per_hr: number; //Rate of FLUID, sent to database
}

export interface APIPackageCriInstruction extends BasePackageItem, BaseOutgoingOrder, CriSearchOption, APIPackageCri {
    brand_name: string; //same as label

    fluids_id: number | null; //If running STRAIGHT medication from cabinet, this is null
    fluids_volume_ml: number;
    label: string;

    medication_id: number;
    medication_name: string;
    dose: number;
    dose_unit: string;
    numerator_value: number;
    numerator_unit: string;
    denominator_value: number;
    denominator_unit: string;
    calculator_type: string;

    type_id: 'C';
}

export interface PackageCriInstruction extends BasePackageItem, BaseOutgoingOrder, CriSearchOption, FrontendPackageCri {
    brand_name: string; //same as label

    fluids_id: number | null; //If running STRAIGHT medication from cabinet, this is null
    fluids_volume_ml: number;
    label: string;

    medication_id: number;
    medication_name: string;
    dose: number;
    dose_unit: string;
    numerator_value: number;
    numerator_unit: string;
    denominator_value: number;
    denominator_unit: string;
    calculator_type: string;

    type_id: 'C';
}

export const isInstanceOfAPIPackageCriInstruction = (item?: any): item is APIPackageCriInstruction =>
    !!item &&
    (item as APIPackageCriInstruction).type_id === 'C' &&
    (item as APIPackageCriInstruction).toggled !== undefined &&
    (item as APIPackageCriInstruction).rate_mcl_per_hr !== undefined;

export const isInstanceOfPackageCriInstruction = (item?: any): item is PackageCriInstruction =>
    !!item && (item as PackageCriInstruction).type_id === 'C' && (item as PackageCriInstruction).toggled !== undefined;

export const backendPackageCriInstructionToFrontendPackageCriInstruction = (cri: APIPackageCriInstruction): PackageCriInstruction => {
    const { rate_mcl_per_hr, ...restofFluid } = cri;
    return {
        ...restofFluid,
        rate_ml_per_hr: roundTo(rate_mcl_per_hr / 1000, 2),
    };
};

export interface APIPackageFluidInstruction extends BasePackageItem, BaseOutgoingOrder, APIFluidSearchOption {
    type_id: 'F';
    fluids_id: number;
    route_id: 'IV' | 'IO';
    fluids_volume_ml: number;
    rate_mcl_per_hr: number | null;
}

export interface PackageFluidInstruction extends BasePackageItem, BaseOutgoingOrder, FluidSearchOption {
    type_id: 'F';
    fluids_id: number;
    route_id: 'IV' | 'IO';
    fluids_volume_ml: number;
    rate_ml_per_hr: number | null;
    oxygen_quantity?: number | null;
}

export const isInstanceOfAPIPackageFluidInstruction = (item?: any): item is APIPackageFluidInstruction =>
    !!item && (item as APIPackageFluidInstruction).type_id === 'F' && (item as APIPackageFluidInstruction).rate_mcl_per_hr !== undefined;

export const backendPackageFluidInstructionToFrontendPackageFluidInstruction = (
    fluid: APIPackageFluidInstruction,
): PackageFluidInstruction => {
    const { rate_mcl_per_hr, default_rate_mcl_per_hr, ...restofFluid } = fluid;
    return {
        ...restofFluid,
        rate_ml_per_hr: rate_mcl_per_hr ? roundTo(rate_mcl_per_hr / 1000, 2) : null,
        default_rate_ml_per_hr: default_rate_mcl_per_hr ? roundTo(default_rate_mcl_per_hr / 1000, 2) : null,
    };
};

export type PackageInstructionTypes =
    | PackageMedInstruction
    | PackageDiagnosticInstruction
    | PackageCriInstruction
    | PackageFluidInstruction
    | PackageTaskInstruction
    | PackageNonMedInstruction;

export interface EnrichedPackageSearchOption extends PackageSearchOption {
    instructions: PackageInstructionTypes[];
}

export const isInstanceOfEnrichedPackageSearchOption = (item: any): item is EnrichedPackageSearchOption =>
    !!(item as EnrichedPackageSearchOption).instructions;

/**
 * THINGS FOR PUT/POST
 */
export interface BasePackageItem {
	id: number;
	name: string;
	toggled: boolean;
	required: boolean;
	start_delay_mins: number | null;
	duration_mins: number | null;
	type_id: INSTRUCTION_TYPES;
	cents: number | null;
}

export interface BaseOutgoingOrder {
    start_time: number; //unix timestamp
    end_time: number; //unix timestamp
    frequency: string | undefined;
    is_prn: boolean;
    prn_condition: string | null;
    priority: boolean;
    notes: string | null;
    ordered_by: number; //TODO pull this from current logged in user
}

export interface OutgoingMedicineOrder extends BaseOutgoingOrder {
    dose: number;
    dose_unit: string;
    medication_id: number;
    route_id: string;
    controlled_drug: boolean;

    approx_patient_weight_kg: number | null;
    latest_patient_weight_kg: number | null;

    estimate_id: number | null;
    serial: boolean;
}

export interface OutgoingDiagOrder extends BaseOutgoingOrder {
    diagnostic_id: number;
    estimate_id: number | null;
    serial: boolean;
}

export interface OutgoingTaskOrder extends BaseOutgoingOrder {
    task_id: number;
    estimate_id: number | null;
    serial: boolean;

}

export interface OutgoingCriOrder extends BaseOutgoingOrder, Omit<OutgoingMedicineOrder, 'route_id' | 'controlled_drug' | 'serial'> {
    fluids_id: number | null; //If running STRAIGHT medicatn from cabinet, this is null
    fluids_volume_ml: number; //If running STRAIGHT medication from cabinet, this is null

    medication_id: number;

    dose: number; //Number in the numerator, dosage in numerator_unit/kg/hour
    dose_unit: string;

    rate_mcl_per_hr: number; //Rate of FLUID infusion, sent to database

    label: string; //Literally a printed out label for the fluid-bag

    latest_patient_weight_kg: number | null; //either latest OR approx
    approx_patient_weight_kg: number | null; //either latest OR approx

    estimate_id: number | null;
}

export const isInstanceOfOutgoingCriOrder = (item: any): item is OutgoingCriOrder =>
    !!item && (item as OutgoingCriOrder).medication_id !== null && !!(item as OutgoingCriOrder).label;

export interface OutgoingFluidOrder extends BaseOutgoingOrder {
    frequency: undefined;
    fluids_id: number;
    fluids_volume_ml: number;

    rate_mcl_per_hr: number | null; //Rate of FLUID, sent to database

    route_id: string;

    estimate_id: number | null;
    fluid_additives: {id: number, dose_qty: string}[];
}

export interface OutgoingFluidAdditiveOrder {
    fluid_additives: {id: number, dose_qty: string}[];
    initial_order: boolean;
    created_at: number;
}

export const isInstanceOfOutgoingFluidOrder = (item: any): item is OutgoingFluidOrder =>
    !!item && !!(item as OutgoingFluidOrder).fluids_id && !item.medication_id;

export interface OutgoingOxygenTherapyOrder extends BaseOutgoingOrder {
    fluids_id: number;
    quantity: number;
    unit: string;

    estimate_id: number | null;
}

export interface OutgoingNonMedicalOrder {
    quantity: number;
    unit: string;
    non_medical_id: number;
    why: string | null;
    why_other: string | null;
    reason: string | null;
    non_medical_order_id: number | null;
}

export interface Supplemental {
    id: number;
    name: string;

    price_id: number;
    cents: number;
    recurring: boolean;
}

export interface AdditiveSearchOption {
    id: string;
    name: string;
    short_name: string;
}
