import { PackageFormInstance } from 'components/forms/PackageFormModal';
import { formatDateWithMinuteRounding, roundTo } from 'utils/formatFuncs';
import { TagType } from 'utils/types/enums';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';
import { instructionApi } from './instructionApi';

type OrderPackageItems = Omit<PackageFormInstance, 'patient_weight' | 'end_time' | 'start_time'>;

export const estimateService = instructionApi.injectEndpoints({
    endpoints: (builder) => ({
        orderPackage: builder.mutation<
            void,
            {
                visitId: number;
                orderItems: OrderPackageItems;
            }
        >({
            query: ({ visitId, orderItems }) => ({
                url: `visit/${visitId}/package`,
                method: 'POST',
                body: {
                    diagnostics:
                        orderItems.diagnostics?.map((diag) => ({
                            diagnostic_id: diag.id,
                            start_time: formatDateWithMinuteRounding(diag.start_time),
                            end_time: diag.end_time ? formatDateWithMinuteRounding(diag.end_time) : null,
                            frequency: diag.frequency,
                            notes: diag.instruction ?? null,
                            serial: diag.serial,
                        })) ?? [],
                    fluids:
                        orderItems.fluids?.map((fluid) => ({
                            fluids_id: fluid.id,
                            route_id: fluid.route_id,
                            fluids_volume_ml: parseFloat(fluid.volume),
                            rate_mcl_per_hr: parseFloat(fluid.rate) * 1000,
                            start_time: formatDateWithMinuteRounding(fluid.start_time),
                            end_time: fluid.end_time ? formatDateWithMinuteRounding(fluid.end_time) : null,
                            notes: fluid.instruction ?? null,
                        })) ?? [],
                    tasks:
                        orderItems.tasks?.map((task) => ({
                            task_id: task.id,
                            start_time: formatDateWithMinuteRounding(task.start_time),
                            end_time: task.end_time ? formatDateWithMinuteRounding(task.end_time) : null,
                            frequency: task.frequency,
                            notes: task.instruction ?? null,
                            serial: task.serial,
                        })) ?? [],
                    non_medicals:
                        orderItems.nonMed?.map((nonMed) => ({
                            non_medical_id: nonMed.id,
                            quantity: parseInt(nonMed.quantity),
                            why: nonMed.why ?? null,
                            why_other: nonMed.why_other ?? null,
                            reason: nonMed.reason ?? null,
                        })) ?? [],
                    medications:
                        orderItems.medications?.map((med) => ({
                            medication_id: med.id,
                            route_id: med.route_id,
                            dose_unit: `micro-${med.dose_unit}`,
                            dose:
                                (med.dose_unit === med.numerator_unit ? med.numerator_value : med.numerator_value / med.concentration) *
                                1000,
                            latest_patient_weight_kg: med.latest_patient_weight_kg,
                            approx_patient_weight_kg: med.latest_patient_weight_kg ? null : med.approx_patient_weight_kg,
                            start_time: formatDateWithMinuteRounding(med.start_time),
                            end_time: med.end_time ? formatDateWithMinuteRounding(med.end_time) : null,
                            frequency: med.frequency,
                            notes: med.instruction ?? null,
                            controlled_drug: med.controlled_drug,
                            serial: med.serial,
                        })) ?? [],
                    oxygen_therapy:
                        orderItems.oxygen_therapy.map((ot) => ({
                            fluids_id: ot.id,
                            quantity: roundTo(parseFloat(ot.quantity) * 100, 0),
                            unit: getOxygenTherapyUnit(ot.name),
                            start_time: formatDateWithMinuteRounding(ot.start_time),
                            end_time: ot.end_time ? formatDateWithMinuteRounding(ot.end_time) : null,
                            notes: ot.instruction ?? null,
                        })) ?? [],
                },
            }),
            invalidatesTags: (_result, _error, { visitId }) => [{ type: TagType.Instruction, id: visitId }],
        }),
    }),
});

export const { useOrderPackageMutation } = estimateService;
