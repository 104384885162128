import { UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag } from 'antd';
import { useGetUserDataQuery } from 'services/authService';
import {
    useAssignSelfToPatientMutation,
    useUnassignDoctorFromPatientMutation,
} from 'services/vetDashService';
import { useTransitionStatusMutation } from 'services/visitService';
import { UserNameWrapper } from 'utils/componentWrappers';
import { PatientRecordVisit } from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { changeStatusWhenAssigningDoctorIfStatusArrived } from 'utils/triggerFuncs';
import {
    checkIfUserHasNecessaryPermission,
    USER_PERMISSIONS,
} from 'utils/userPermissions';

interface AssignedVetSectionProps {
    currentVisit: PatientRecordVisit;
}

export const AssignedVetSection = ({
    currentVisit,
}: AssignedVetSectionProps) => {
    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const isVisitAssigned = !!currentVisit.doctor_id;
    const [assignSelf] = useAssignSelfToPatientMutation();
    const [unassignDoctor] = useUnassignDoctorFromPatientMutation();
    const [transitionStatus] = useTransitionStatusMutation();
    const isFinalizedVisit = Boolean(currentVisit.finalized_at);

    const userHasPermissionToAssign = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.visit_claim,
    );

    return (
        <Row align='middle' style={{ paddingTop: '4px' }}>
            <Col span={12} style={{ textAlign: 'right', paddingRight: '16px' }}>
                Assigned Vet
            </Col>
            <Col span={10}>
                {(() => {
                    if (!userHasPermissionToAssign && !isVisitAssigned) {
                        return <span>Unassigned</span>;
                    } else {
                        if (isVisitAssigned) {
                            return (
                                <Tag
                                    style={{
                                        fontSize: '14px',
                                        margin: '5px 0',
                                    }}
                                    data-cy="assignedDoctorTag"
                                    closable={userHasPermissionToAssign}
                                    onClose={(e) => {
                                        if (!userHasPermissionToAssign) return;

                                        e.preventDefault();
                                        if (isFinalizedVisit) {
                                            closedErrorMsg(isFinalizedVisit);
                                            return;
                                        }
                                        if (isVisitAssigned) {
                                            unassignDoctor({
                                                visitId: currentVisit.id,
                                            });
                                        }
                                    }}
                                >
                                    <UserNameWrapper>
                                        {currentVisit.doctor_id}
                                    </UserNameWrapper>
                                </Tag>
                            );
                        } else {
                            return (
                                <Button
                                    icon={<UserAddOutlined />}
                                    type={'link'}
                                    style={{ padding: 0 }}
                                    disabled={isFinalizedVisit}
                                    data-cy="assignDoctorButton"
                                    onClick={() => {
                                        if (isFinalizedVisit) {
                                            closedErrorMsg(isFinalizedVisit);
                                            return;
                                        }
                                        if (!isVisitAssigned) {
                                            assignSelf({
                                                visitId: currentVisit.id,
                                            }).unwrap().then((response: any) => {
                                              changeStatusWhenAssigningDoctorIfStatusArrived(
                                                  currentVisit.id,
                                                  currentVisit.status,
                                                  transitionStatus,
                                              )
                                            });
                                        }
                                    }}
                                >
                                    Assign to me
                                </Button>
                            );
                        }
                    }
                })()}
            </Col>
        </Row>
    );
};
