import { Checkbox, Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useGetNonMedicalWhyTypesQuery } from 'services/instructionService';
import { COOKIE_DURATION } from 'utils/constants';
import { checkCookieExists } from 'utils/miscFuncs';
import { NonMedicalOrderRules } from 'utils/types/validations';
import { validateFieldIsGreaterThan, validateFieldIsNotDecimal, validateFieldIsShorterThan } from 'utils/validationFuncs';
import { NonMedicalExistingOrder } from '../../utils/dataTypes';
import { NonMedicalSearchOption } from '../../utils/types/InstructionOrderTypes';
import { HiddenInput } from './fields/HiddenInput';

const formItemStyle: CSSProperties = {
    width: '100%',
};

const getDefaults = (
    options: NonMedicalSearchOption,
    existingOrder?: NonMedicalExistingOrder,
): NonMedicalExistingOrder => {
    if (existingOrder) {
        return {
            ...existingOrder,
        };
    } else {
        const defaultInstruction: NonMedicalExistingOrder = {
            id: -1,
            non_medical_id: options.id,
            name: options.name,
            type_id: options.type_id,
            unit: 'unit',
            quantity: 1,
            cents: options.cents,
        };
        return defaultInstruction;
    }
};

export const OUTSTANDING_ORDER_DATE_FORMAT = 'YYYY-MM-DD, hh:mm a';
interface NonMedicalOrderProps {
    options: NonMedicalSearchOption;
    existingOrder?: NonMedicalExistingOrder;
    onFormChange?: Function; //not actually optional -- always sent via FormWrapper
    labelCol?: any; //Width of LABEL, in "columns" (like <Col span={4})
    wrapperCol?: any; //Width of INPUT, in "columns" (like <Col span={12})
    fromEstimate?: boolean;
    skipModal?: boolean;
}
export const NonMedicalOrder = ({
    options,
    existingOrder,
    fromEstimate,
    labelCol,
    wrapperCol,
    onFormChange,
    skipModal,
}: NonMedicalOrderProps) => {
    const [showWhyOther, setShowWhyOther] = useState(false);

    const doNotShowChecked = useRef(false);

    const { data: nonMedicalWhyTypes } = useGetNonMedicalWhyTypesQuery(null);

    const hasReason = options.has_reason;
    const unitIsDollar = options.unit === 'dollar';

    const onFormChangeFormatted = onFormChange ?? (() => console.error('ERROR, onFormChange NOT PASSED THROUGH'));

    useEffect(() => {
        if (!skipModal && !checkCookieExists('do_not_show_non_medical_and_tgh_confirm_modal')) {
            Modal.warning({
                title: 'Non-medical Orders',
                centered: true,
                maskClosable: true,
                autoFocusButton: 'ok',
                okText: 'Ok',
                onOk: () => {
                    if (doNotShowChecked.current) {
                        document.cookie = `do_not_show_non_medical_and_tgh_confirm_modal=1;max-age=${COOKIE_DURATION}`;
                    }
                },
                content: (
                    <>
                        <Typography.Paragraph>
                            Non-medical and To-go orders will only appear on estimates and invoices. They will NOT appear on treatment sheets.
                        </Typography.Paragraph>
                        <Checkbox
                            defaultChecked={false}
                            onChange={(e) => {
                                doNotShowChecked.current = e.target.checked;
                            }}
                        >
                            Do not show me this again
                        </Checkbox>
                    </>
                ),
            });
        }
    }, [])

	useEffect(() => {
		const defaultNonMedical = getDefaults(options, existingOrder);
		onFormChangeFormatted(defaultNonMedical)
	}, [options, existingOrder]);

	const nonMedicalOrderRules: NonMedicalOrderRules = {
		quantity: [
			{
				required: true,
                message: unitIsDollar ? "'amount' is required" : undefined,
			},
			{
				validator: validateFieldIsGreaterThan('quantity', 0)
			},
			{
				validator: validateFieldIsNotDecimal(unitIsDollar ? 'amount' : 'quantity'),
			}
		]
	};

	return (
		<>
			<Form.Item
				preserve={false}
				name='quantity'
				label={unitIsDollar ? 'Amount' : 'Quantity'}
				style={formItemStyle}
				labelCol={labelCol}
				wrapperCol={{ span: unitIsDollar ? 7 : wrapperCol }}
				rules={nonMedicalOrderRules.quantity}
			>
                <Input
                    prefix={unitIsDollar ? '$' : undefined}
                    disabled={false}
                    style={{
                        width: '100%',
                    }}
                />
			</Form.Item>
            {hasReason && (
            <>
                <Form.Item
                    label='Why'
                    name='why'
                    style={formItemStyle}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    rules={[{ required: true, message: 'Why is required' }]}
                >
                    <Select<string>
                        allowClear
                        showSearch
                        options={nonMedicalWhyTypes?.map((item: string) => {
                            return { label: item, value: item };
                        })}
                        filterOption={(inputValue, option) =>
                            (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onChange={(value) => {
                            setShowWhyOther(value === 'Other');
                        }}
                    />
                </Form.Item>
                {showWhyOther && (
                    <Form.Item
                        preserve={false}
                        name='why_other'
                        label='Other'
                        style={formItemStyle}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        rules={[{ required: true, message: 'Other is required' }]}
                    >
                        <Input placeholder='Please provide short answer' />
                    </Form.Item>
                )}
                <Row className='non-medical-reason-info'>
                    <Col span={20} offset={4}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>For internal use only.</span>
                    </Col>
                </Row>
                <Form.Item
                    preserve={false}
                    name='reason'
                    label='Reason'
                    style={formItemStyle}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    rules={[
                        { required: true, message: 'Reason is required' },
                        { validator: validateFieldIsShorterThan('reason', 151) },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Row className='non-medical-reason-info'>
                    <Col span={20} offset={4}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                            Provide an explanation of cost for customer awareness and insurance purposes.
                        </span>
                        <strong>This will appear on the invoice.</strong>
                    </Col>
                </Row>
            </>
            )}
			<HiddenInput fieldName='non_medical_id' />
			<HiddenInput fieldName='type_id' />
			<HiddenInput fieldName='unit_cost_cents' />
			<HiddenInput fieldName='unit' />
			<HiddenInput fieldName='name' />
			<HiddenInput fieldName='id' />
			<HiddenInput fieldName="fromEstimate" initialValue={fromEstimate} />



		</>
	);
};
