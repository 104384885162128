import { CheckOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyGetSupplementalByIdQuery } from 'services/supplementalService';
import { roundTo } from 'utils/formatFuncs';
import { PackageFluidInstruction } from 'utils/types/InstructionOrderTypes';
import { getOxygenTherapyUnit } from 'views/visit/EstimatesNew/utils';
import { PackageFormInstance } from '.';
import { HiddenInput } from '../fields/HiddenInput';
import EditLineButton from './EditLineButton';
import InstructionFormItem from './InstructionFormItem';
import StartEndTimeFormItems from './StartEndTimeFormItems';

interface OxygenTherapyFormProps {
    fluid: PackageFluidInstruction;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    fromEstimate?: boolean;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const OxygenTherapyForm: React.FC<OxygenTherapyFormProps> = ({
    fluid,
    form,
    index,
    fromEstimate,
    hiddenSectionClass,
    activeLineItem,
    setActiveLineItem,
}) => {
    const [trigger, { data: supplementalData }] = useLazyGetSupplementalByIdQuery();

    const [hasError, setHasError] = useState(false);
    const lineId = `OT-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const oxygenTherapyFields = [
        ['oxygen_therapy', index, 'quantity'],
        ['oxygen_therapy', index, 'end_time'],
        ['oxygen_therapy', index, 'start_time'],
        ['oxygen_therapy', index, 'instruction'],
    ];
    const oxygenTherapyErrorArray = form.getFieldsError(oxygenTherapyFields);

    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        return (
            prev.oxygen_therapy[index].quantity !== curr.oxygen_therapy[index].quantity ||
            prev.oxygen_therapy[index].checked !== curr.oxygen_therapy[index].checked ||
            prev.oxygen_therapy[index].start_time !== curr.oxygen_therapy[index].start_time ||
            prev.oxygen_therapy[index].end_time !== curr.oxygen_therapy[index].end_time
        );
    };

    const setOxygenTherapyFieldValue = (value: any) => {
        const fieldsValue = form.getFieldsValue();

        if (fieldsValue.oxygen_therapy) {
            form.setFieldsValue({
                ...fieldsValue,
                oxygen_therapy: fieldsValue.oxygen_therapy.map((oxygen_therapy: any, indx: number) => {
                    return indx === index ? { ...oxygen_therapy, ...value } : oxygen_therapy;
                }),
            });
        }
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['oxygen_therapy', index, 'quantity'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (oxygenTherapyErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, oxygenTherapyErrorArray]);

    useEffect(() => {
        if (fluid.supplemental_id) {
            trigger({ supplemental_id: fluid.supplemental_id });
        }
    }, [fluid.supplemental_id]);

    useEffect(() => {
        if (supplementalData) {
            setOxygenTherapyFieldValue({
                is_recurring_supplemental: supplementalData.recurring,
                supplemental_cost_cents: supplementalData.cents,
            });
        }
    }, [supplementalData]);

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const checked =
                    getFieldValue(['oxygen_therapy', index, 'checked']) ?? (fluid.required ? fluid.required : fluid.toggled) ?? true;
                const disabledClass = !checked ? 'disabled-line' : '';
                const initialError = !getFieldValue(['oxygen_therapy', index, 'quantity']);

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${disabledClass}`}
                        onClick={() => {
                            if (!hasError && !isEditing) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['oxygen_therapy', index, 'id']} initialValue={fluid.fluids_id} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'unit_cost_cents']} initialValue={fluid.cents} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'name']} initialValue={fluid.name} />
                            <HiddenInput
                                fieldName={['oxygen_therapy', index, 'supplemental_cost_cents']}
                                initialValue={fluid.supplemental_cents}
                            />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'is_recurring_supplemental']} initialValue={false} />
                            <HiddenInput fieldName={['oxygen_therapy', index, 'required']} initialValue={fluid.required} />
                        </div>

                        <Form.Item
                            className='grid-table__sticky-column'
                            initialValue={fluid.required ? fluid.required : fluid.toggled}
                            valuePropName='checked'
                            name={['oxygen_therapy', index, 'checked']}
                        >
                            {fluid.required ? (
                                <Tooltip title='Required order. Cannot be removed.'>
                                    <CheckOutlined />
                                </Tooltip>
                            ) : (
                                <Checkbox />
                            )}
                        </Form.Item>

                        <div className='grid-table__sticky-column'>
                            <Tooltip title={fluid.name.length > 45 ? fluid.name : null}>
                                <span className='treatment-sheet-side-column__title'>{fluid.name}</span>
                            </Tooltip>
                        </div>

                        {checked ? (
                            <>
                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Quantity
                                </div>
                                <div className='fluid-dose-container'>
                                    <Form.Item
                                        name={['oxygen_therapy', index, 'quantity']}
                                        initialValue={fluid.oxygen_quantity ? roundTo(fluid.oxygen_quantity / 100, 2) : null}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (isNaN(value)) {
                                                        return Promise.reject('Required');
                                                    }

                                                    if (value <= 0) {
                                                        return Promise.reject('Cannot be smaller or equal to 0');
                                                    }

                                                    if (value < 0.01) {
                                                        return Promise.reject('The value cannot be smaller than 0.01');
                                                    }

                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {isEditing ? (
                                            <Input suffix={getOxygenTherapyUnit(fluid.name)} type='number' min={0} />
                                        ) : (
                                            <span>
                                                {getFieldValue(['oxygen_therapy', index, 'quantity'])
                                                    ? `${getFieldValue(['oxygen_therapy', index, 'quantity'])}`
                                                    : '-'}
                                                {getOxygenTherapyUnit(fluid.name)}
                                            </span>
                                        )}
                                    </Form.Item>
                                </div>

                                <div className='empty-element' />
                                <div className='empty-element' />

                                {!fromEstimate && (
                                    <>
                                        <StartEndTimeFormItems
                                            endTimeFormFieldName={['oxygen_therapy', index, 'end_time']}
                                            endTimeFormFieldValue={getFieldValue(['oxygen_therapy', index, 'end_time'])}
                                            isEditing={isEditing}
                                            startTimeFormFieldName={['oxygen_therapy', index, 'start_time']}
                                            startTimeFormFieldValue={getFieldValue(['oxygen_therapy', index, 'start_time'])}
                                        />

                                        <InstructionFormItem
                                            formFieldName={['oxygen_therapy', index, 'instruction']}
                                            initialValue={null}
                                            isEditing={isEditing}
                                            formFieldValue={getFieldValue(['oxygen_therapy', index, 'instruction'])}
                                        />
                                    </>
                                )}

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) setActiveLineItem(lineId);
                                    }}
                                />
                            </>
                        ) : (
                            <div className='disabled-line__form-inputs' />
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default OxygenTherapyForm;
