import { Button, Col, Form, Modal, Radio, Row, Tooltip, Typography, message } from 'antd';
import CollapseContent from 'components/CollapseContent';
import { Ledger } from 'components/lib';
import { useEffect, useState } from 'react';
import {
    useGetBillingDataQuery,
    useGetLedgerByVisitIdQuery,
    useGetStripePaymentErrorsByVisitIdQuery,
    useGetVisitDiscountQuery,
    useLazyDownloadInvoiceQuery, useLazyGetBillingDataQuery, useLazyGetVisitDiscountQuery,
} from 'services/billingService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { ExpandedPatient, PatientRecordVisit } from 'utils/dataTypes';
import { formatCurrency } from 'utils/formatFuncs';
import { convertBasisToDecimal, getLedgerValues, twelveHourWindowHasPassed } from 'utils/miscFuncs';
import { Billing, BillingDisplayType } from 'utils/types/billingTypes';
import AdditionalServicesTable from './AdditionalServicesTable';
import { AttributionSwap } from './AttributionSwap';
import { BillingTable } from './BillingTable';
import DeleteRevenue from './DeleteRevenue';
import { DonationsTable } from './DonationsTable';
import { MarkItemAsFree } from './MarkItemAsFree';
import { PaymentsTable } from './PaymentsTable';
import './billing.css';
import { CheckCircleOutlined, ExclamationCircleOutlined, LockOutlined, StopOutlined, UnlockOutlined } from '@ant-design/icons';
import { CheckoutDrawer } from 'components/CheckoutDrawer';
import { useAppSelector } from 'hooks/useRedux';
import { useGetTaxForMultipleEstimatesQuery } from 'services/estimateService';
import { useFinalizeInvoiceMutation, useTransitionStatusMutation, useUndoFinalizeInvoiceMutation } from 'services/visitService';
import { useGetPatientByIdQuery, useMarkPatientDeceasedMutation } from 'services/patientService';
import { useGetDischargeTogoMedsByVisitIdQuery } from 'services/visitService';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDischargePatientInstructionsMutation, useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import {
    PromptPatientDeceasedDateModal,
    reviewAndFinalizeFlow,
    reviewAndFinalizeFlowRelock,
} from '../DischargeTab/utils';
import moment from 'moment';
import { useLazyDownloadDischargeDocQuery } from 'services/taskService';
import _ from 'lodash';
import { filterFluidsCri } from '../../../utils/filterFuncs';

interface BillingTabProps {
    visitId: number;
    currentVisit: PatientRecordVisit;
    patientData: ExpandedPatient | undefined;
    isActive: boolean;
    isInvoiceLocked: boolean;
    canUnlockInvoice: boolean;
}

const { confirm } = Modal;

export const BillingTab = (props: BillingTabProps) => {
    const [isVisibleRefundModal, setIsVisibleRefundModal] = useState(false);
    const [isCheckoutDrawerOpen, setIsCheckoutDrawerOpen] = useState(false);
    const [isRelocking, setIsRelocking] = useState(false);
    const paymentModalOpen = useAppSelector((state) => state.visitHeaderSlice.paymentModalOpen);

    const { visitId, isActive, currentVisit, canUnlockInvoice } = props;

    const [pollingInterval, setPollingInterval] = useState(60000);
    useEffect(() => {
        if ( !isVisibleRefundModal && !paymentModalOpen && !isCheckoutDrawerOpen && isActive){
            setPollingInterval(60000)
        } else {
            setPollingInterval(0)
        }
    }, [isVisibleRefundModal, paymentModalOpen, isCheckoutDrawerOpen, isActive])

    const [isRefresh, setIsRefresh] = useState(false);
    const [billingData, setBillingData] = useState<undefined | Billing>(undefined);
    
    const { data: localBillingData, refetch: refetchBillingData, isFetching: isFetchingBillingData, isSuccess: isSuccessBillingData  } = useGetBillingDataQuery(
        {visitId, isRefresh},
        { pollingInterval }
    );
    const { data: ledgerItems, refetch: refetchLedgerByVisit } = useGetLedgerByVisitIdQuery(
        visitId,
        { pollingInterval },
    );
    const { data: stripePaymentErrorItems, refetch: refetchStripePaymentErrorsByVisit } = useGetStripePaymentErrorsByVisitIdQuery(
        visitId,
        { pollingInterval },
    );
    const { data: visitDiscount } = useGetVisitDiscountQuery(
        visitId,
        { ...BASE_QUERY_OPTIONS },
    );
    const {refetch: refetchTaxes } = useGetTaxForMultipleEstimatesQuery(visitId, { pollingInterval: 120000 });
    const { data: instData } = useGetInstructionsByVisitIdQuery({ visitId });
    const [downloadDischargeDoc] = useLazyDownloadDischargeDocQuery();
    const criAndFluidInstructions = instData?.filter(filterFluidsCri) ?? [];
    const ongoingInstructions = instData?.filter(
        (instruction) =>
            !instruction.discontinued_at && instruction.type_id !== 'TGH',
    );
    const [discontinuePatientInstructions] = useDischargePatientInstructionsMutation();
    const [finalizeInvoice] = useFinalizeInvoiceMutation();
    const [undoFinalizeInvoice] = useUndoFinalizeInvoiceMutation();
    
    const discontinueCriAndFluidInstructions = () => {
        if (props.currentVisit?.id) {
            return discontinuePatientInstructions({
                visitId,
                ids: criAndFluidInstructions?.filter(instruction => instruction.discontinued_at === null).map((instruction) => instruction.id) ?? [],
            }).then((response) => {
                if ('data' in response) {
                    refetchBillingData();
                    refetchLedgerByVisit();
                    refetchTaxes();
                }
            });
        }
    };

    const [getBillingDataLazy] = useLazyGetBillingDataQuery();
    const [getVisitDiscountLazy] = useLazyGetVisitDiscountQuery();

    const unlockInvoice = () => {
        undoFinalizeInvoice({ visitId }).unwrap().catch(
            () => message.error("Unable to unlock, more than 12 hours have passed.")
        );
    };

    const [form] = Form.useForm();

    const [markPatientDeceased] = useMarkPatientDeceasedMutation();

    const onFinishRelock = (ids: number[]) => {
        return discontinuePatientInstructions({
            visitId, ids: ids,
        })
            .unwrap()
            .then((resp) => {
                finalizeInvoice({ visitId: currentVisit.id })
            });
    };

    const relockInvoice = () => {
        if (isRelocking) {
            return;
        }
        if (props.patientData) {
            reviewAndFinalizeFlowRelock(
                ongoingInstructions,
                discontinueCriAndFluidInstructions,
                onFinishRelock,
                getBillingDataLazy,
                getVisitDiscountLazy,
                currentVisit.id,
                setIsRelocking,
                props.patientData.name
            );
        }
    };

    const onFinishReviewAndFinalize = (ids: number[]) => {
        return discontinuePatientInstructions({
            visitId, ids: ids,
        })
        .unwrap()
        .then((resp) => {
            transitionStatus({ visitId, status: 'discharged' })
                .unwrap().then(() => {
                    if (resp.linked_actions.map(item => item.identifier).includes('prompt_for_deceased_at')) {
                        PromptPatientDeceasedDateModal(form, props.patientData!.pet_id, props.patientData!.name, markPatientDeceased, moment(props.patientData!.birthday, 'YYYY-MM-DD'));
                    }
                });
            downloadDischargeDoc({ visitId });
        })
        .catch((err) => {
            confirm({
                title: 'Unable to Discharge',
                content: err.data,
                cancelButtonProps: { hidden: true }
            });
        });
    };



    const {
        data: togoMeds,
        refetch: refetchGetDischargeTogoMedsByVisitId,
    } = useGetDischargeTogoMedsByVisitIdQuery(visitId);


    useEffect(() => {
        if (isSuccessBillingData && !isFetchingBillingData) {
            if (localBillingData?.refresh) {
                setIsRefresh(true);
                setBillingData(localBillingData);
            }
        }
    }, [localBillingData, isSuccessBillingData, isFetchingBillingData]);


    // Todo: fix this, currently since the instructions and billing are on separate apis we can't automatically update the tags between them.
    // so its required for us to refetch the data every time the tab is opened
    useEffect(() => {
        setIsRefresh(false);
        setBillingData(undefined);
        if (isActive) {
            refetchBillingData();
            refetchLedgerByVisit();
            refetchStripePaymentErrorsByVisit();
            refetchTaxes();
            refetchGetDischargeTogoMedsByVisitId();
        }
    }, [isActive])

    const [selected, setSelected] = useState<number[]>([]);
    // Refetch on refund modal open to keep data consistent 
    useEffect(() => {
        if (isVisibleRefundModal) {
            refetchBillingData();
            refetchLedgerByVisit();
        }
    }, [isVisibleRefundModal])

    const [billingType, setBillingType] = useState<BillingDisplayType>('order');

    const [downloadInvoice, { isFetching: downloadingInvoice }] = useLazyDownloadInvoiceQuery();

    const { due, total: ledgerTotal } = getLedgerValues(
        billingData?.subtotal,
        billingData?.amount_paid,
        billingData?.tax,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents,
    )
    const donations = (ledgerItems || []).reduce((total, ledgerItem) => total + convertBasisToDecimal(ledgerItem.donation_basis) * ledgerItem.amount_cents, 0);

    const isInvoiceFinalized = Boolean(currentVisit?.invoice_finalized_at);

    const getIcon = () => {
        if (currentVisit.status === 'checked_out') {
            return <CheckCircleOutlined style={{color: 'var(--green-alert-success)'}} />;
        }
        if (currentVisit.status === 'discharged') {
            return <ExclamationCircleOutlined style={{color: 'var(--veg-secondary-red)'}} />;
        }
        return <ExclamationCircleOutlined />;
    };

    const { data: patientData } = useGetPatientByIdQuery(currentVisit?.pet_id ?? skipToken, { skip: !currentVisit?.pet_id });

    const [transitionStatus] = useTransitionStatusMutation();

    const doCheckout = () => {
        transitionStatus({ visitId, status: 'checked_out' }).then((response) => {
            if ('data' in response) {
                message.success('Patient status has been updated to Checked Out.');
                setIsCheckoutDrawerOpen(false);
            } else {
                message.error('There was an error checking out the visit.');
            }
        });
    }

    return (<div>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Typography.Text
                        style={{
                            fontSize: '20px',
                            fontWeight: 500,
                            lineHeight: '28px',
                        }}
                    >
                        Billing
                    </Typography.Text>

                            <Row style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                <Col>
                    <span>
                        {due <= 0 ? 'balance' : 'balance due'}
                    </span>
                    <span
                        className='billing-currency-display'
                        data-cy={'billingBalance'}
                        style={{ color: due === 0 ? 'inherit' : due < 0 ? 'var(--veg-green)' : 'var(--veg-red)' }}
                    >
                        {formatCurrency(Math.abs(due))} {due < 0 ? 'CR' : ''}
                    </span>
                </Col>

                <Col style={{ alignSelf: 'flex-end', marginTop: '24px' }}>
                    <Button
                        className='generate-invoice-button'
                        onClick={() => {
                            downloadInvoice({
                                visitId,
                            });
                        }}
                        loading={downloadingInvoice}
                        disabled={downloadingInvoice}
                    >
                        Generate Invoice
                    </Button>
                    {!currentVisit.invoice_finalized_at && currentVisit.status !== 'checked_out' &&
                        <Button
                            data-cy="dischargePatientButton"
                            disabled={
                                ![
                                    'inprogress',
                                    'hospitalized',
                                    'discharged',
                                ].includes(currentVisit?.status ?? '')
                            }
                            onClick={_.debounce(() => {
                                if (props.patientData) {
                                    reviewAndFinalizeFlow(
                                        ongoingInstructions,
                                        discontinueCriAndFluidInstructions,
                                        props.patientData!.name,
                                        onFinishReviewAndFinalize,
                                        getBillingDataLazy,
                                        getVisitDiscountLazy,
                                        visitId,
                                        ledgerTotal,
                                    );
                                }}, 500, {leading: true, trailing: false})}
                            style={{marginLeft: '8px'}}
                        >
                            Review and Finalize
                        </Button>
                    }
                    {(currentVisit.invoice_finalized_at || currentVisit.status === 'checked_out') &&
                        <Button
                            disabled={
                                (currentVisit.finalized_at && currentVisit.status !== 'checked_out') ||
                                (currentVisit.status !== 'discharged' && currentVisit.status !== 'checked_out')
                            }
                            icon={getIcon()}
                            onClick={() => {
                                // referral source is the only requirement for checkout
                                // if patient is deceased bypass it and checkout right away
                                if (!!patientData?.deceased_at) {
                                    doCheckout();
                                } else {
                                    setIsCheckoutDrawerOpen(true);
                                }
                            }}
                            style={{marginLeft: '4px'}}
                            data-cy={'checkoutButton'}
                        >
                            Checkout
                        </Button>
                    }
                        
                </Col>
            </Row>
        </Row>

        <CollapseContent Title='Services' isExpandedDefault>
            <>
                <Row style={{ marginBottom: "8px" }}>
                    <Radio.Group
                        onChange={(e) => setBillingType(e.target.value)}
                        className="billing-buttons"
                        defaultValue={billingType}
                    >
                        <Radio.Button value="order">Grouped By Order</Radio.Button>
                        <Radio.Button value="doctor">Grouped By Doctor</Radio.Button>
                    </Radio.Group>

                    <AttributionSwap
                        selected={selected}
                        billingItems={billingData?.billing_items || []}
                        setSelected={setSelected}
                        visitId={visitId}
                        visitFinalizedAt={currentVisit.finalized_at}
                    />
                    <MarkItemAsFree 
                        selected={selected}
                        billingItems={billingData?.billing_items || []}
                        setSelected={setSelected}
                        visitId={visitId}
                    />
                    <DeleteRevenue
                        isInvoiceFinalized={isInvoiceFinalized}
                        billingItems={billingData?.billing_items || []}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    {criAndFluidInstructions.some(instruction => instruction.discontinued_at === null) &&
                        <Button
                            style={{marginLeft: 'var(--spacing-sm)'}}
                            icon={<StopOutlined />}
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Discontinue All CRI, Fluids, and Oxygen',
                                    content: (
                                        <>
                                            <p>
                                                CRIs, Fluids and Oxygen are automatically billed at an hourly rate.
                                            </p>
                                            <p>
                                                In the case of euthanasia and to get an accurate final invoice amount, all CRI, fluid and oxygen orders should be discontinued.
                                            </p>
                                        </>
                                    ),
                                    okText: 'Discontinue All',
                                    centered: true,
                                    maskClosable: true,
                                    onOk: discontinueCriAndFluidInstructions,
                                });
                            }}
                        >
                            Stop continuous orders
                        </Button>
                    }
                    {!currentVisit.finalized_at && props.isInvoiceLocked &&
                        <div style={{ marginLeft: 'auto' }}>
                            <Tooltip
                                title={canUnlockInvoice
                                    ? null
                                    : 'The 12 hour window for adjustments has expired. No further changes can be made.' 
                                }
                            >
                                <Button
                                    disabled={!canUnlockInvoice}
                                    icon={<LockOutlined style={{color: canUnlockInvoice ? 'var(--green-alert-success)' : 'inherit'}} />}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Unlock Invoice',
                                            content: (
                                                <>
                                                    <p>
                                                        Are you sure you want to unlock {patientData?.name}’s invoice and allow adjustments?  
                                                    </p>
                                                </>
                                            ),
                                            okText: 'Unlock Invoice',
                                            centered: true,
                                            maskClosable: true,
                                            onOk: unlockInvoice,
                                            okButtonProps: {style: {color: 'var(--gray-9)', background: 'var(--gold-6)', borderColor: 'var(--gold-6)'}}
                                        });
                                    }}
                                >
                                    Locked
                                </Button>
                            </Tooltip>
                        </div>
                    }
                    {isInvoiceFinalized && !props.isInvoiceLocked &&
                        <Button
                            icon={<UnlockOutlined style={{color: 'var(--gold-6)'}} />}
                            style={{marginLeft: 'auto'}}
                            onClick={relockInvoice}
                        >
                            Unlocked
                        </Button>
                    }
                </Row>

                <BillingTable
                    billingItems={billingData?.billing_items?.filter(bi => bi.unit_price_cents > 0 || bi.type_id === 'M') || []}
                    isFetching={isFetchingBillingData}
                    type={billingType}
                    selected={selected}
                    setSelected={setSelected}
                />
            </>
        </CollapseContent>

        <CollapseContent Title='Additional Services - No Cost'>
            <AdditionalServicesTable
                billingItems={billingData?.billing_items?.filter(bi => bi.unit_price_cents === 0 && bi.type_id !== 'M') || []}
            />
        </CollapseContent>

        <CollapseContent Title='Payments/Refunds'>
            <PaymentsTable
                visitId={visitId}
                ledgerItems={ledgerItems || []}
                stripePaymentErrorItems={stripePaymentErrorItems || []}
                isVisibleRefundModal={isVisibleRefundModal} 
                setIsVisibleRefundModal={setIsVisibleRefundModal}
                isFetchingBillingData={isFetchingBillingData}
                balanceDue={due}
                isInvoiceFinalized={isInvoiceFinalized}
            />
        </CollapseContent>

        <CollapseContent Title='Donations'>
            <DonationsTable
                visitId={visitId}
                ledgerItems={ledgerItems || []}
            />
        </CollapseContent>

        <Row justify="end">
            <Ledger
                subtotal={billingData?.subtotal ?? 0}
                paid={billingData?.amount_paid ?? 0}
                tax={billingData?.tax ?? 0}
                donations={donations}
                visitDiscount={visitDiscount}
                type="billing"
                visitId={visitId}
            />
        </Row>
        <CheckoutDrawer toGoMeds={togoMeds ?? []} isOpen={isCheckoutDrawerOpen} setIsOpen={setIsCheckoutDrawerOpen} doCheckout={doCheckout} />
    </div>)
}
