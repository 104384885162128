// Estimates Section
import { INSTRUCTION_TYPES } from 'utils/dataTypes';
import { INSTRUCTION_CALCULATOR_TYPES } from './InstructionOrderTypes';
import { roundTo } from 'utils/formatFuncs';
import { PaymentType } from './paymentTypes';

interface BaseEstimateCreate {
    type_id: INSTRUCTION_TYPES;

    unit: string;
    is_free: boolean;
    quantity: number;
    ordered_by: number;
    is_prn: boolean;
    prn_condition: string | null;
    priority: boolean;
    notes: string | null;
    reason: string | null;
}
export interface DiagnosticEstimateCreate extends BaseEstimateCreate {
    type_id: 'D';
    diagnostic_id: number;
    frequency: string;

    unit: 'unit';
}

export interface TaskEstimateCreate extends BaseEstimateCreate {
    type_id: 'T';
    task_id: number;
    frequency: string;

    unit: 'unit';
}

interface APIFluids {
    rate_mcl_per_hr: number | undefined; //Rate of FLUID, sent to database
}

interface FrontendFluids {
    rate_ml_per_hr: number | undefined; //Rate of FLUID infusion, set on pump
}

export interface APIFluidEstimateCreate extends BaseEstimateCreate, APIFluids {
    type_id: 'F';
    diagnostic_id: number;
    frequency: string;

    unit: 'minutes';

    route_id: 'IV' | 'IO';
    fluids_volume_ml: number;
}

export interface FluidEstimateCreate extends BaseEstimateCreate, FrontendFluids {
    type_id: 'F';
    diagnostic_id: number;
    frequency: string;

    unit: 'minutes';

    route_id: 'IV' | 'IO';
    fluids_volume_ml: number;
}

export const isInstanceOfFluidEstimateCreate = (item: any): item is FluidEstimateCreate =>
    item.fluids_id !== undefined && item.type_id === 'F' && (item as FluidEstimateCreate).rate_ml_per_hr !== undefined;

export const frontendFluidEstimateCreateToBackendFluidEstimateCreate = (fluid: FluidEstimateCreate): APIFluidEstimateCreate => {
    const { rate_ml_per_hr, ...restofFluid } = fluid;
    return {
        ...restofFluid,
        rate_mcl_per_hr: rate_ml_per_hr ? roundTo(rate_ml_per_hr * 100, 0) * 10 : undefined,
    };
};

interface APICri {
    rate_mcl_per_hr: number | undefined; //Rate of FLUID, sent to database
}

interface FrontendCri {
    rate_ml_per_hr: number | undefined; //Rate of FLUID infusion, set on pump
}

export interface APICriEstimateCreate extends BaseEstimateCreate, APICri {
    type_id: 'C';
    frequency: string;
    medication_id: number;

    label: string;
    dose: number;
    dose_unit: string;

    fluids_id: number | null;
    fluids_volume_ml: number | null;
    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
}

export interface CriEstimateCreate extends BaseEstimateCreate, FrontendCri {
    type_id: 'C';
    frequency: string;
    medication_id: number;

    label: string;
    dose: number;
    dose_unit: string;

    fluids_id: number | null;
    fluids_volume_ml: number | null;
    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
}

export const isInstanceOfCriEstimateCreateAPi = (item: any): item is APICriEstimateCreate =>
    item.fluids_id !== undefined && item.type_id === 'C' && (item as APICriEstimateCreate).rate_mcl_per_hr !== undefined;

export const frontendCriEstimateCreateToBackendCriEstimateCreate = (cri: CriEstimateCreate): APICriEstimateCreate => {
    const { rate_ml_per_hr, ...restofCri } = cri;
    return {
        ...restofCri,
        rate_mcl_per_hr: rate_ml_per_hr ? roundTo(rate_ml_per_hr * 100, 0) * 10 : undefined,
    };
};
export const isInstanceOfCriEstimateCreate = (item: any): item is CriEstimateCreate =>
    item.fluids_id !== undefined && item.type_id === 'C' && (item as CriEstimateCreate).rate_ml_per_hr !== undefined;

export interface MedicalEstimateCreate extends BaseEstimateCreate {
    approx_patient_weight_kg?: number | null;
    type_id: 'M';
    frequency: string;
    medication_id: number;

    route_id: string;
    dose: number;
    dose_unit: string;
}

export const isInstanceOfMedicalEstimateCreate = (item: any): item is MedicalEstimateCreate =>
    item.medication_id !== undefined && item.type_id === 'M';

export type EstimateItemsCreate =
    | MedicalEstimateCreate
    | DiagnosticEstimateCreate
    | APIFluidEstimateCreate
    | FluidEstimateCreate
    | APICriEstimateCreate
    | CriEstimateCreate
    | TaskEstimateCreate;

export interface FreeRefundFieldsNonMedical {
    non_medical_order_id: number;
    reason: string | null;
    type_id: 'N';
}

export interface FreeRefundFieldsMedical {
    instruction_id: number;
    reason: string | null;
    type_id: EARNABLE_TYPES;
}

export type FreeRefundFields = FreeRefundFieldsNonMedical | FreeRefundFieldsMedical;

export interface DiscontinueFieldsMedical {
    instruction_id: number;
    type_id: EARNABLE_TYPES;
    unit: string | null;
    quantity: number;
    on_behalf_of: number;
    reason: string | null;
    dose: number | null;
}
export interface DiscontinueFieldsNonMedical {
    non_medical_order_id: number;
    type_id: 'N';
    unit: string | null;
    quantity: number;
    on_behalf_of: number;
    reason: string | null;
}

export type DiscontinueFields = DiscontinueFieldsMedical | DiscontinueFieldsNonMedical;

export const isInstanceOfDiscontinueFieldsMedical = (item: any): item is DiscontinueFieldsMedical =>
    item.instruction_id !== undefined && item.type_id !== 'N';

export const isInstanceOfDiscontinueFieldsNonMedical = (item: any): item is DiscontinueFieldsNonMedical =>
    item.non_medical_order_id !== undefined && item.type_id === 'N';

export const isInstanceOfDiscontinueFields = (item: any): item is DiscontinueFields =>
    isInstanceOfDiscontinueFieldsMedical(item) || isInstanceOfDiscontinueFieldsNonMedical(item);

export interface OutOfEstimateFieldsNonMedical {
    non_medical_order_id: number;
    type_id: 'N';
    unit: string | null;
    quantity: number;
}

export interface OutOfEstimateFieldsMedical {
    instruction_id: number;
    type_id: EARNABLE_TYPES;
    unit: string | null;
    quantity: number;
    ordered_by: number;
    notes: string | null;
    dose: number | null;
}

export type OutOfEstimateFields = OutOfEstimateFieldsNonMedical | OutOfEstimateFieldsMedical;

export const isInstanceOfOutOfEstimateFields = (item: any): item is OutOfEstimateFields => {
    if (item.non_medical_order_id !== undefined && item.type_id === 'N') {
        return true;
    } else if (item.instruction_id !== undefined && item.ordered_by !== undefined && item.notes !== undefined) {
        return true;
    } else {
        return false;
    }
};

export const isInstanceOfOutOfEstimateFieldsNonMedical = (item: any): item is OutOfEstimateFieldsNonMedical =>
    item.non_medical_order_id !== undefined && item.type_id === 'N';

export interface EstimateCreateBody {
    discount: number | null;
    new_items: EstimateItemsCreate[];
    refund: FreeRefundFields[];
    free: FreeRefundFields[];
    discontinued: DiscontinueFields[];
    out_of_estimate: OutOfEstimateFields[];
    send_sms?: boolean;
}

export interface EstimateOrderBody {
    start_time: number;
    end_time: number | null;
    ordered_by: number;
    notes: string | null;
}

export interface NonMedicalEstimateOrderBody {
    quantity: number;
    unit: string;
    why: string | null;
    why_other: string | null;
    reason: string | null;
}

interface BaseEstimateItem {
    instruction_id: number;

    visit_id: number;
    type_id: INSTRUCTION_TYPES;
    start_time: number | null;
    end_time: number | null;
    discontinued_at: number | null;
    discontinued_reason: string | null;

    name: string;
    unit_cost_cents: number | null;
    notes: string | null;
    unit: string;
    complete_and_prior_quantity: number;
    incomplete_and_prior_quantity: number;
    incomplete_and_not_prior_quantity?: number; // this will be generated on the frontend
    complete_and_not_prior_quantity: number;
    completed_since_last_estimate_quantity: number;
    approved_quantity: number;
    is_free: boolean;
    is_refund: boolean;
    reason: string | null;
    is_prn: boolean;
    prn_condition: string | null;
    priority: boolean;

    discontinueFields: DiscontinueFields | undefined;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;
}

export interface DiagnosticEstimateItem extends BaseEstimateItem {
    type_id: 'D';

    frequency: string;
    diagnostic_id: number;
    name: string;
    category: string | null;
}

export const isInstanceOfDiagnosticEstimateItem = (item: any): item is DiagnosticEstimateItem =>
    item.diagnostic_id !== undefined && item.type_id === 'D';

export interface TaskEstimateItem extends BaseEstimateItem {
    type_id: 'T';

    frequency: string;
    task_id: number;
    name: string;
}

export const isInstanceOfTaskEstimateItem = (item: any): item is TaskEstimateItem => item.task_id !== undefined && item.type_id === 'T';

export interface APIFluidEstimateItem extends BaseEstimateItem, APIFluids {
    type_id: 'F';

    fluids_id: number;
    route_id: string;
    name: string;
    abbreviation: string | null;
    fluids_volume_ml: number;
}

export interface FluidEstimateItem extends BaseEstimateItem, FrontendFluids {
    type_id: 'F';

    fluids_id: number;
    route_id: string;
    name: string;
    abbreviation: string | null;
    fluids_volume_ml: number;
    oxygen_quantity?: number | null;
}

export const isInstanceOfAPIFluidEstimateItem = (item: any): item is APIFluidEstimateItem =>
    item.fluids_id !== undefined && item.type_id === 'F' && !!(item as APIFluidEstimateItem).rate_mcl_per_hr;

export const isInstanceOfFluidEstimateItem = (item: any): item is FluidEstimateItem =>
    item.fluids_id !== undefined && item.type_id === 'F' && !!(item as FluidEstimateItem).rate_ml_per_hr;

export const backendFluidEstimateItemToFrontendFluidEstimateItem = (fluid: APIFluidEstimateItem): FluidEstimateItem => {
    const { rate_mcl_per_hr, ...restofFluid } = fluid;
    return {
        ...restofFluid,
        rate_ml_per_hr: rate_mcl_per_hr ? roundTo(rate_mcl_per_hr / 1000, 2) : undefined,
    };
};

export interface APICriEstimateItem extends BaseEstimateItem, APICri {
    type_id: 'C';

    name: string;
    label: string;
    medication_id: number;
    dose: number;
    dose_unit: string;

    fluids_id: number | null;
    fluids_volume_ml: number | null;

    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
}

export interface CriEstimateItem extends BaseEstimateItem, FrontendCri {
    type_id: 'C';

    name: string;
    label: string;
    medication_id: number;
    dose: number;
    dose_unit: string;

    fluids_id: number | null;
    fluids_volume_ml: number | null;

    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
    default_cri_unit?: string | null;
}

export const isInstanceOfAPICriEstimateItem = (item: any): item is APICriEstimateItem =>
    item.medication_id !== undefined && item.type_id === 'C' && !!(item as APICriEstimateItem).rate_mcl_per_hr;

export const isInstanceOfCriEstimateItem = (item: any): item is CriEstimateItem =>
    item.medication_id !== undefined && item.type_id === 'C' && !!(item as CriEstimateItem).rate_ml_per_hr;

export const backendCriEstimateItemToFrontendCriEstimateItem = (cri: APICriEstimateItem): CriEstimateItem => {
    const { rate_mcl_per_hr, ...restofCri } = cri;
    return {
        ...restofCri,
        rate_ml_per_hr: rate_mcl_per_hr ? roundTo(rate_mcl_per_hr / 1000, 2) : undefined,
    };
};

export interface MedicalEstimateItem extends BaseEstimateItem {
    type_id: 'M';

    frequency: string;
    medication_id: number;
    name: string;
    form: string;
    route_id: string;
    dose: number;
    dose_unit: string;

    approx_patient_weight_kg: number | null;
    latest_patient_weight_kg: number | null;
}

export const isInstanceOfMedicalEstimateItem = (item: any): item is MedicalEstimateItem =>
    item.medication_id !== undefined && item.type_id === 'M';

export interface NonMedicalEstimateItem extends BaseEstimateItem {
    visit_id: number;
    type_id: 'N';

    non_medical_order_id: number;
    non_medical_id: number;
    name: string;

    prior_quantity: number;
    completed_quantity: number;

    unit_cost_cents: number;
    unit: string;

    approved_quantity: number;

    is_free: boolean;
    is_refund: boolean;
    reason: string | null;
}

export const isInstanceOfNonMedicalEstimateItem = (item: any): item is NonMedicalEstimateItem =>
    item.non_medical_order_id !== undefined && item.type_id === 'N';

export type EstimateItemWithOutNonMedical =
    | DiagnosticEstimateItem
    | TaskEstimateItem
    | FluidEstimateItem
    | CriEstimateItem
    | MedicalEstimateItem;

export type EstimateItem = EstimateItemWithOutNonMedical | NonMedicalEstimateItem;

interface BaseEstimateFormFields {
    type_id: string;
    name: string;
    unit_cost_cents: number | null;
    is_prn: boolean;
    prn_condition: string | null;
    priority: boolean;
    unit: string;
    is_free: boolean;
    is_refund?: boolean;
    reason: null | string;
    ordered_by: number;
    notes: string | null;
    quantity: number;
    start_time?: number;
    end_time?: number;
    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;

    timestamp?: number;
    serial?: boolean;  
    serial_hours?: number | null;  

}
export interface DiagnosticEstimateFormFields extends BaseEstimateFormFields {
    frequency: string;
    diagnostic_id: number;
    type_id: 'D';
    serial: boolean;
    serial_hours: number | null;

}

export const isInstanceOfDiagnosticEstimateFormFields = (item: any): item is DiagnosticEstimateFormFields =>
    item.diagnostic_id !== undefined && item.type_id === 'D';

export interface TaskEstimateFormFields extends BaseEstimateFormFields {
    frequency: string;
    task_id: number;
    type_id: 'T';
    serial: boolean;
    serial_hours: number | null;

}

export const isInstanceOfTaskEstimateFormFields = (item: any): item is TaskEstimateFormFields =>
    item.task_id !== undefined && item.type_id === 'T';

export interface MedicineEstimateFormFields extends BaseEstimateFormFields {
    medication_id: number;
    type_id: 'M';
    frequency: string;
    route_id: string;
    dose: number;
    dose_unit: string;
    calculator_type?: INSTRUCTION_CALCULATOR_TYPES;
    approx_patient_weight_kg: number | null;
    latest_patient_weight_kg: number | null;
    dose_acknowledge?: boolean;
    controlled_drug: boolean;
    serial: boolean;
    serial_hours: number | null;

}

export const isInstanceOfMedicineEstimateFormFields = (item: any): item is MedicineEstimateFormFields =>
    item.medication_id !== undefined && item.type_id === 'M';

export interface FluidEstimateFormFields extends BaseEstimateFormFields {
    fluids_id: number;
    type_id: 'F';
    route_id: 'IV' | 'IO';
    fluids_volume_ml: number;
    rate_ml_per_hr: number | null;
}

export interface OxygenTherapyEstimateFormFields extends BaseEstimateFormFields {
    fluids_id: number;
    type_id: 'OT';
    oxygen_quantity: number;
    unit: string;
}

export const isInstanceOfFluidEstimateFormFields = (item: any): item is FluidEstimateFormFields =>
    item.fluids_id !== undefined && item.type_id === 'F';

export const isInstanceOfOxygenTherapyEstimateFormFields = (item: any): item is OxygenTherapyEstimateFormFields =>
    item.fluids_id !== undefined && item.type_id === 'OT';

export interface CriEstimateFormFields extends BaseEstimateFormFields {
    medication_id: number;
    type_id: 'C';

    label: string;
    dose: number;
    dose_unit: string;
    rate_ml_per_hr: number;
    fluids_id: number | null;
    fluids_volume_ml: number | null;
    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;
    controlled_drug: boolean;
}

export const isInstanceOfCriEstimateFormFields = (item: any): item is CriEstimateFormFields =>
    item.medication_id !== undefined && item.type_id === 'C';

export interface ToGoMedEstimateFormFields extends BaseEstimateFormFields {
    medication_id: number;
    type_id: 'TGH';
    dose_unit: string;

    dispense_unit: string;
    ratio: number;
    dispense_value: number;
    instructions: string | null;
    next_dose: string | null;
    controlled_drug: boolean;
}

export const isInstanceOfToGoMedEstimateFormFields = (item: any): item is ToGoMedEstimateFormFields =>
    item.medication_id !== undefined && item.type_id === 'TGH';

export interface NonMedicalEstimateFormFields {
    non_medical_id: number;

    is_free: boolean;
    is_refund?: boolean;
    type_id: 'N';
    unit: string;
    name: string;
    quantity: number;
    unit_cost_cents: number | null;
    reason: string | null;
    supplemental_cost_cents: null;
    is_recurring_supplemental: null;

    timestamp?: number;
    serial?: boolean;  
    serial_hours?: number | null;  

}

export const isInstanceOfNonMedicalEstimateFormFields = (item: any): item is NonMedicalEstimateFormFields =>
    item.non_medical_id !== undefined && item.type_id === 'N';

export type EstimateFormFields =
    | DiagnosticEstimateFormFields
    | TaskEstimateFormFields
    | FluidEstimateFormFields
    | MedicineEstimateFormFields
    | CriEstimateFormFields
    | NonMedicalEstimateFormFields
    | ToGoMedEstimateFormFields
    | OxygenTherapyEstimateFormFields;

export type QuantityField =
    | 'incomplete_and_prior_quantity'
    | 'complete_and_prior_quantity'
    | 'complete_and_not_prior_quantity'
    | 'incomplete_and_not_prior_quantity';

// Billing Section

export type EARNABLE_TYPES = 'C' | 'T' | 'F' | 'M' | 'D' | 'N' | 'TGH';

export type BillingDisplayType = 'order' | 'doctor';

export interface EarnedRevenueLineItem {
    id: number;
    instruction_id: number | null;
    non_medical_id: number | null;
    doctor_id: number | null;
    quantity: number;
    unit: string;
    price_cents: number;
    is_supplement: boolean;
    discount_basis: number | null;
    created_at: number;
    visit_discount_created_at: number | null;
    is_comped: boolean;
    is_comped_created_at: number | null;
    additive_name?: string;
}

export interface BillingItem {
    instruction_id: number | null;
    non_medical_order_id: number | null;
    doctor_id: number | null;
    doctor_first_name: string | null;
    doctor_last_name: string | null;
    visit_id: number;
    type_id: EARNABLE_TYPES;
    name: string;
    frequency: string | null;
    start_time: number | null;
    quantity: number;
    unit: string;
    unit_price_cents: number;
    supplemental_price_cents: number | null;
    earned_revenue_line_items: EarnedRevenueLineItem[];
    why: string | null;
    why_other: string | null;
    reason: string | null;
    pricing_unit: string | null;
    pricing_unit_size: number | null;
    serial?: boolean;
    serial_hours?: number;
}

export interface Billing {
    billing_items: BillingItem[];
    subtotal: number;
    amount_paid: number;
    tax: number;
    refresh: boolean;
}

export interface BillingItemDisplay extends BillingItem {
    instructionName: string | null;
    end_time: number | null;
    price: number;
    earned_revenue_line_items: EarnedRevenueLineItemDisplay[];
}
export interface EarnedRevenueLineItemDisplay extends EarnedRevenueLineItem {
    instructionName: string | null;
    type_id: EARNABLE_TYPES;
    first_name: string | null;
    last_name: string | null;
    is_comped: boolean;
    created_at: number;
    instruction_start_time: number | null;
    why: string | null;
    why_other: string | null;
    reason: string | null;
    serial?: boolean;
    serial_hours?: number;

}

export interface BillingTableEarnedRevenueItem {
    key: string | number;
    id: number;
    name: string;
    instructionName: string | null;
    frequency: string | null;
    start: string;
    end: string;
    quantity: number;
    unit: string;
    unit_price: string;
    price: number;
    is_comped: boolean;
    is_supplement: boolean;
    why: string | null;
    why_other: string | null;
    reason: string | null;
    created_at: number;
    displayQuantity: string;
    displayUnit: string;
    serial?: boolean;
    serial_hours?: number | null;
}

export interface BillingTableEarnedRevenueItemGroup extends BillingTableEarnedRevenueItem {
    ids: number[];
}

export const isInstanceOfBillingTableEarnedRevenueItemGroup = (item: BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup | BillingTableRow): item is BillingTableEarnedRevenueItemGroup => {
    if ('ids' in item) {
        return true;
    }
    return false;
};




export interface AdditionalServicesTableChildItem {
    key: string | number;
    id: string | number;
    name: string;
    frequency: string | null;
    end: string;
}

export interface LedgerItem {
    id: number;
    visit_id: number;
    amount_cents: number;
    donation_basis: number;
    form_of_payment: PaymentType;
    created_at: number;
    created_by_first_name: string | null;
    created_by_last_name: string | null;
    refunded_from: number | null;
    card_brand: string | null;
    last_4_digits: string | null;
}

export interface DiscountItem {
    id: number;
    basis: number;
    name: string;
    created_at: number;
    created_by: number;
    discontinued_at: number | null;
}

export interface VisitDiscount {
    visit_id: number;
    discount_id: number;
    basis: number;
    name: string;
    cap_amount_cents?: number;
}

export interface AttributionSummary {
    case_volume: number;
    doctor_name: string;
    location: string;
    value: number;
    okta_id?: string;

    visits: {
        [visit_id: number]: AttributionSummaryVisit;
    };
}

export interface AttributionSummaryVisit {
    doctor_total: number;
    invoice_total: number;
    pet_name: string;
    pet_full_name: string;
    visit_invoice_finalized_at: number;
}

export interface BillingInfo {
    invoiceTotal: number;
    paid: number;
    approvedEstimateTotal: number;
}

export interface BillingTableRow {
    key: string | number;
    id: number;
    non_medical_order_id: number | null;
    instruction_id: number | null;
    type_id: EARNABLE_TYPES | null;
    name: string;
    price: number;
    children: (BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup)[];
    why: string | null;
    why_other: string | null;
    reason: string | null;
    pricing_unit_size: number | null;
    unit: string | null;
    serial?: boolean;
    serial_hours?: number | null;

}

export const isInstanceOfBillingTableParentRow = (item: BillingTableRow | BillingTableEarnedRevenueItem | BillingTableEarnedRevenueItemGroup): item is BillingTableRow => {
    if ('children' in item) {
        return true;
    }
    return false;
};

export interface StripePaymentErrorItem {
    payment_intent_id: string;
    form_of_payment: PaymentType;
    last_4_digits: string | null;
    card_brand: string | null;
    last_error_code: string;
    last_error_message: string;
    updated_at: number;
    amount_cents: number;
}
