import { CheckCircleOutlined, ExclamationCircleOutlined, MinusOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popconfirm, Row, Select, Switch, Table, Tooltip } from 'antd';
import { ClockIcon } from 'assets/img/ClockIcon';
import { ClockPlusIcon } from 'assets/img/ClockPlusIcon';
import { ContactIcon } from 'assets/img/ContactIcon';
import { QuickActionIcon } from 'assets/img/QuickActionIcon';
import heartbreak from 'assets/img/heartbreak_icon_red.svg';
import CallLogDrawer from 'components/CallLogDrawer';
import { IntakeInfoDrawer } from 'components/IntakeInfoDrawer';
import { Avatar, PageHeader } from 'components/lib';
import { ComposeBoxProvider } from 'hooks/ComposeBoxProvider';
import moment from 'moment';
import { CSSProperties, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/authService';
import { useGetInstructionOptionsQuery, usePostCheckinTprInstructionsMutation } from 'services/instructionService';
import { useSearchRdvmQuery } from 'services/rdvmService';
import { useGetBreedsQuery, useGetSpeciesQuery } from 'services/specieService';
import {
	useAssignSelfToPatientAsNurseMutation,
	useAssignSelfToPatientMutation,
	useGetInboundCallsQuery,
	useGetPatientsQuery,
	useRemoveSpikedMutation,
	useSetSpikedMutation,
	useUnassignDoctorFromPatientMutation,
	useUnassignNurseFromPatientMutation
} from 'services/vetDashService';
import { useTransitionStatusMutation } from 'services/visitService';
import {
	BASE_QUERY_OPTIONS,
	VET_LANDING_TABLE_TITLE
} from 'utils/constants';
import {
	InboundCall,
	TRIAGE_LEVELS,
	VetDashboardVisit,
} from 'utils/dataTypes';
import { roundTo } from 'utils/formatFuncs';
import { changeStatusWhenAssigningDoctorIfStatusArrived } from 'utils/triggerFuncs';
import {
	USER_PERMISSIONS,
	checkIfUserHasNecessaryPermission,
} from 'utils/userPermissions';
import classes from './dashboard.module.css';
import './dashboardTable.css';

const { Column } = Table;
const { Option } = Select;

const formItemStyle: CSSProperties = {
	width: '100%',
};

const Dashboard = () => {
	const [showAllPatients, setShowAllPatients] = useState(true);

	const { data: patientsByStatus, isLoading: isDashboardLoading } = useGetPatientsQuery(
		null,
		{ ...BASE_QUERY_OPTIONS, refetchOnMountOrArgChange: true, pollingInterval: 60000 }
	);
	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const { data: inboundCalls, isLoading: isInboundCallsLoading } = useGetInboundCallsQuery(
		null,
		{ pollingInterval: 20000 },
	)

	// Prefect these to limit number of loads in the main page
	useSearchRdvmQuery(null, { skip: isDashboardLoading || isInboundCallsLoading })
	useGetInstructionOptionsQuery(null, { skip: isDashboardLoading || isInboundCallsLoading })
	useGetBreedsQuery(null, { skip: isDashboardLoading || isInboundCallsLoading })
	useGetSpeciesQuery(null, { skip: isDashboardLoading || isInboundCallsLoading })

	const user_id = loggedInUserData?.user_id;
	const hospital_name = loggedInUserData ? loggedInUserData?.hospital_name : '';
	const userIsNurse = loggedInUserData?.role_name === 'Nurse';

	const unassigned = patientsByStatus?.unassigned.filter(item =>
		!(item.pet_name === 'New Patient' &&
			item.status === 'enroute')
	).map(
		(patient: VetDashboardVisit, index: number) => ({
			...patient,
			key: index,
		}),
	);
	const inprogress = patientsByStatus?.inprogress.map(
		(patient: VetDashboardVisit, index: number) => ({
			...patient,
			key: index,
		}),
	);
	const hospitalized = patientsByStatus?.hospitalized.map(
		(patient: VetDashboardVisit, index: number) => ({
			...patient,
			key: index,
		}),
	);
	const discharged = patientsByStatus?.discharged.map(
		(patient: VetDashboardVisit, index: number) => ({
			...patient,
			key: index,
		}),
	);
	const checked_out = patientsByStatus?.checked_out.map(
		(patient: VetDashboardVisit, index: number) => ({
			...patient,
			key: index,
		}),
	);

	return (
		<div style={{ padding: '8px' }}>
			<PageHeader title={hospital_name}>
				<span>
					Show only my patients
				</span>
				<Switch
					className={classes.switch}
					checked={!showAllPatients}
					onClick={() => setShowAllPatients(!showAllPatients)}
					style={{ margin: '0 1rem' }}
					data-cy="showOnlyMyPatientsSwitch"
				/>
			</PageHeader>
			<Row
				gutter={[12, 12]}
				className={classes.tableWrapper}
				style={{ marginTop: '8px' }}
			>
				<Col span={24}>
					<InboundCallsTable
						title={VET_LANDING_TABLE_TITLE['calls']}
						calls={inboundCalls ?? []}
						defaultExpanded={true}
						dataCy="inbountCallsTable"
					/>
				</Col>
			</Row>
			<Row
				gutter={[12, 12]}
				className={classes.tableWrapper}
				style={{ marginTop: '8px' }}
			>
				<Col span={24}>
					<PatientTable
						title={VET_LANDING_TABLE_TITLE['unassigned']}
						patients={unassigned ?? []}
						numTotalPatients={unassigned?.length ?? 0}
						defaultExpanded={true}
						dataCy="waitingRoomUnassignedPatientsTable"
					/>
				</Col>
				<Col span={24}>
					<PatientTable
						title={VET_LANDING_TABLE_TITLE['inprogress']}
						patients={
							inprogress?.filter(
								(patient: VetDashboardVisit) =>
									showAllPatients ||
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							) ?? []
						}
						numCurrentUserPatients={
							//DO NOT CHECK `showAllPatients` here -- just want to know how many are assigned
							inprogress?.filter(
								(patient: VetDashboardVisit) =>
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							)?.length ?? 0
						}
						numTotalPatients={inprogress?.length ?? 0}
						defaultExpanded={true}
						dataCy="inProgressPatientsTable"
					/>
				</Col>
				<Col span={24}>
					<PatientTable
						title={VET_LANDING_TABLE_TITLE['hospitalized']}
						patients={
							hospitalized?.filter(
								(patient: VetDashboardVisit) =>
									showAllPatients ||
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							) ?? []
						}
						numCurrentUserPatients={
							//DO NOT CHECK `showAllPatients` here -- just want to know how many are assigned
							hospitalized?.filter(
								(patient: VetDashboardVisit) =>
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							)?.length ?? 0
						}
						numTotalPatients={hospitalized?.length ?? 0}
						defaultExpanded={true}
						dataCy="hospitalizedPatientsTable"
					/>
				</Col>
				<Col span={24}>
					<PatientTable
						title={VET_LANDING_TABLE_TITLE['discharged']}
						patients={
							discharged?.filter(
								(patient: VetDashboardVisit) =>
									showAllPatients ||
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							) ?? []
						}
						numCurrentUserPatients={
							//DO NOT CHECK `showAllPatients` here -- just want to know how many are assigned
							discharged?.filter(
								(patient: VetDashboardVisit) =>
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							)?.length ?? 0
						}
						numTotalPatients={discharged?.length ?? 0}
						defaultExpanded={true}
						dataCy="dischargedPatientsTable"
					/>
				</Col>
				<Col span={24}>
					<PatientTable
						title={VET_LANDING_TABLE_TITLE['checked_out']}
						patients={
							checked_out?.filter(
								(patient: VetDashboardVisit) =>
									showAllPatients ||
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							) ?? []
						}
						numCurrentUserPatients={
							//DO NOT CHECK `showAllPatients` here -- just want to know how many are assigned
							checked_out?.filter(
								(patient: VetDashboardVisit) =>
									(userIsNurse ? patient.nurse_id : patient.doctor_id) === user_id,
							)?.length ?? 0
						}
						numTotalPatients={checked_out?.length ?? 0}
						defaultExpanded={true}
						dataCy="checkedOutPatientsTable"
					/>
				</Col>
			</Row>
		</div>
	);
};

interface PatientTableProps {
	patients: VetDashboardVisit[];
	numCurrentUserPatients?: number; //Within this category/table, num of patients assigned to current doctor. Optional, b/c it doesnt make sense for the "unassigned" table
	numTotalPatients: number; //Within this category/table, total num of patients
	title: string;
	defaultExpanded: boolean;
	dataCy?: string;
}

export const PatientTable = ({
	patients,
	title,
	defaultExpanded,
	numCurrentUserPatients,
	numTotalPatients,
	dataCy,
}: PatientTableProps) => {
	const [isExpanded, setIsExpanded] = useState(defaultExpanded);
	let history = useHistory();
	const [assignSelf] = useAssignSelfToPatientMutation();
	const [assignSelfAsNurse] = useAssignSelfToPatientAsNurseMutation();
	const [unassignDoctor] = useUnassignDoctorFromPatientMutation();
	const [unassignNurse] = useUnassignNurseFromPatientMutation();
	const [transitionStatus] = useTransitionStatusMutation();
	const [setSpiked] = useSetSpikedMutation();
	const [removeSpiked] = useRemoveSpikedMutation();

	const { data: loggedInUserData } = useGetUserDataQuery(
		null,
		BASE_QUERY_OPTIONS,
	);
	const [
		postCheckinTprInstructions,
	] = usePostCheckinTprInstructionsMutation();
	const user_id = loggedInUserData?.user_id;

	const userHasPermissionToAssign = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.visit_claim,
	);

	const userHasPermissionToAssignAsNurse = checkIfUserHasNecessaryPermission(
		loggedInUserData?.user_permissions,
		USER_PERMISSIONS.visit_claim_as_nurse,
	);

	const handleMarkAsArrived = (record: VetDashboardVisit) => {
		transitionStatus({
			status: 'arrived',
			visitId: record.visit_id,
		});
		postCheckinTprInstructions({ visitId: record.visit_id });
	};

	const handleAssignSelf = (record: VetDashboardVisit) => {
		const visitId = record.visit_id;
		if (userHasPermissionToAssign) {
			assignSelf({ visitId }).unwrap().then(
				(response: any) => {
					changeStatusWhenAssigningDoctorIfStatusArrived(
						visitId,
						record.status,
						transitionStatus,
					);
				}
			);
		}
	};

	const handleAssignSelfAsNurse = (record: VetDashboardVisit) => {
		const visitId = record.visit_id;
		if (userHasPermissionToAssignAsNurse) {
			assignSelfAsNurse({ visitId });
		}
	};

	const visitDurationUnit = 'hours';
	const displayVisitDuration = (record: VetDashboardVisit) => {
		return record.arrived_at && record.discharged_at
			? `${moment.unix(record.discharged_at).diff(moment.unix(record.arrived_at), visitDurationUnit)} hours`
			: record.arrived_at
				? moment.unix(record.arrived_at).toNow(true)
				: "-";
	}

	const [intakeInfoVisit, setIntakeInfoVisit] = useState<VetDashboardVisit | undefined>();
	const [intakeForm] = Form.useForm();
	const [isIntakeInfoDrawerOpen, setIsIntakeInfoDrawerOpen] = useState(false);
	const [triageLevel, setTriageLevel] = useState<TRIAGE_LEVELS>(null);

	return (
		<>
			<Table
				locale={{ emptyText: ' ' }}
				data-cy={dataCy}
				title={() => (
					<Row align='middle' gutter={[8, 4]} data-cy="dashboardHeaderRow">
						<Col>
							<Button
								size='small'
								icon={
									isExpanded ? (
										<MinusOutlined data-cy="collapseButton" />
									) : (
										<PlusOutlined data-cy="expandButton" />
									)
								}
								onClick={() => setIsExpanded(!isExpanded)}
							/>
						</Col>
						<Col>
							<h3>
								{title}
								{numCurrentUserPatients !== undefined
									? ` (${numCurrentUserPatients}/${numTotalPatients})`
									: ` (${numTotalPatients})`}
							</h3>
						</Col>
					</Row>
				)}
				dataSource={isExpanded ? patients : []}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {
							let selection = window.getSelection()?.toString();
							if (!selection) {
								history.push(`/visit/${record.visit_id}`);
							}
						}, // click row
						onDoubleClick: (event) => { }, // double click row
						onContextMenu: (event) => { }, // right button click row
						onMouseEnter: (event) => { }, // mouse enter row
						onMouseLeave: (event) => { }, // mouse leave row
					};
				}}
				className={`dashboard-${isExpanded ? 'expanded' : 'minimized'}`}
				rowClassName={classes.row}
				pagination={false}
			>
				{isExpanded ? (
					<>
						<Column
							title='Name'
							dataIndex='pet_name'
							key='name'
							width='20%'
							data-cy="petName"
							render={(text, record: VetDashboardVisit) => {
								const triageClass = !!record.triage_level ? `triage triage-${record.triage_level}` : '';
								if (record.owner_last_name) {
									return <span className={triageClass}>
										{`${record.pet_name} (${record.owner_last_name})`} {record.pet_deceased_at && <img src={heartbreak} width="20" style={{ marginLeft: "0px" }} />}
									</span>;
								} else {
									return <span className={triageClass}>
										{record.pet_name} {record.pet_deceased_at && <img src={heartbreak} width="20" style={{ marginLeft: "5px" }} />}
									</span>;
								}
							}}
						/>
						<Column
							title='Signalment'
							dataIndex='signalment'
							key='signalment'
							width='20%'
							render={(text, record: VetDashboardVisit) => {
								//TODO need real data for this
								//TODO there is a mega-janky setup for getting `Vitals`
								//TODO it will change. Could add other stuff like sex/neutered when we change this for real
								let weightString = '';
								if (record.pet_weight) {
									weightString = roundTo(record.pet_weight, 3) + ' kg';
								} else {
									weightString = '';
								}

								let sexIntact = '';
								if (record.pet_is_intact !== null) {
									if (record.pet_sex === 'M') {
										sexIntact = record.pet_is_intact ? "MI" : "MN";
									} else if (record.pet_sex === 'F') {
										sexIntact = record.pet_is_intact ? "FI" : "FS";
									}
								} else {
									if (record.pet_sex) {
										sexIntact = record.pet_sex === 'M' ? 'M' : 'F';
									}
								}

								return (
									<div>
										<div>
											<b>{weightString ? weightString : ''}</b>
											{weightString ? ` ${sexIntact}` : sexIntact}
											{record.pet_species ? ` ${record.pet_species}` : ''}
											{record.pet_breed ? ` (${record.pet_breed})` : ''}
										</div>
										{record.rdvm_name ? <div>{record.rdvm_name}</div> : null}
									</div>
								);
							}}
						/>
						<Column
							title='Reason'
							dataIndex='reason_for_visit'
							key='complaints'
							width='25%'
						/>
						<Column
							align='center'
							title='Time in hospital'
							dataIndex='time_in_hospital'
							key='time_in_hospital'
							width='10%'
							render={(text, record: VetDashboardVisit) =>
								["noshow", "enroute"].includes(record.status)
									? (
										<Button
											type='default'
											data-cy="actionButton"
											onClick={(e) => {
												e.stopPropagation();
												handleMarkAsArrived(record);
											}}
										>
											Mark as Arrived
										</Button>
									)
									: displayVisitDuration(record)
							}
						/>

						<Column
							align='center'
							title='Spike 3'
							dataIndex='spiked_by'
							key='spiked'
							width='5%'
							render={(text, record: VetDashboardVisit) =>
								record.spiked_at ? (
									<div
										onClick={(e) => {
											e?.stopPropagation();
										}}
									>
										<Popconfirm
											placement='left'
											title='Are you sure you want to unmark Spike 3 complete?'
											okText='Yes'
											disabled={!userHasPermissionToAssign}
											className='spike-3-pop-confirm'
											cancelText='Cancel'
											onConfirm={(e) => {
												e?.stopPropagation();

												removeSpiked(record.visit_id);
											}}
											overlayClassName='pendo-data-unspike'
											onCancel={(e) => {
												e?.stopPropagation();
											}}
										>
											<Button
												icon={
													<Tooltip title='Click to mark it as not spiked.'>
														<ClockIcon style={{ height: '21px' }} />
													</Tooltip>
												}
												disabled={!userHasPermissionToAssign}
											/>
										</Popconfirm>
									</div>
								) : (
									<Button
										icon={
											<Tooltip title='Click to mark it as spiked.'>
												<ClockPlusIcon style={{ height: '21px' }} />
											</Tooltip>
										}
										disabled={!userHasPermissionToAssign}
										data-pendo='click-spike-3-dashboard'
										style={{
											borderColor: 'var(--gray-7)',
											display: 'inline-flex',
											alignItems: 'center',
											justifyContent: 'center',
											opacity: userHasPermissionToAssign ? '1' : '.6',
										}}
										onClick={(e) => {
											e.stopPropagation();
											setSpiked(record.visit_id);
										}}
									/>
								)
							}
						/>

						<Column
							align='center'
							title='Vet'
							dataIndex='doctor_last_name'
							key='vet'
							width='5%'
							render={(text, record: VetDashboardVisit) => {
								if (record.doctor_id === null) {
									return (
										<Button
											shape='circle'
											icon={
												<Tooltip title='Click to assign yourself as the vet.'>
													<UserAddOutlined
														style={{ color: userHasPermissionToAssign ? 'var(--gray-9)' : 'inherit' }}
													/>
												</Tooltip>
											}
											style={{ borderColor: 'var(--gray-7)' }}
											onClick={(e) => {
												e.stopPropagation();
												handleAssignSelf(record);
											}}
											disabled={!userHasPermissionToAssign}
											data-cy={'assignYourselfButton'}
										/>
									);
								} else {
									return (
										<div
											onClick={(e) => {
												e?.stopPropagation();
											}}
											style={{ width: '32px', height: '32px', margin: 'auto', }}
										>
											<Popconfirm
												placement='left'
												title='Are you sure to un-assign yourself?'
												okText="Yes"
												cancelText="No"
												onConfirm={(e) => {
													e?.stopPropagation();
													if (userHasPermissionToAssign && record.doctor_id === user_id) {
														unassignDoctor({
															visitId: record.visit_id,
														});
													}
												}}
												onCancel={(e) => {
													e?.stopPropagation();
												}}
												disabled={!userHasPermissionToAssign || record.doctor_id !== user_id}
											>
												<Tooltip title={`${record.doctor_first_name} ${record.doctor_last_name}`}>
													<Button
														type='link'
														shape='circle'
														style={{
															width: '32px',
															height: '32px',
															margin: 0,
															padding: 0,
														}}
														data-cy='unassignButton'
														onClick={(e) => {
															e.stopPropagation();
														}}
														disabled={!userHasPermissionToAssign || record.doctor_id !== user_id}
													>
														<Avatar
															firstName={record.doctor_first_name}
															lastName={record.doctor_last_name}
														/>
													</Button>
												</Tooltip>
											</Popconfirm>
										</div>
									);
								}
							}}
						/>
						<Column
							align='center'
							title='Nurse'
							dataIndex='nurse_last_name'
							key='vet'
							width='5%'
							render={(text, record: VetDashboardVisit) => {
								const isDisabled = !userHasPermissionToAssignAsNurse;
								if (record.nurse_id === null) {
									return (
										<Button
											shape='circle'
											icon={
												<Tooltip title='Click to assign yourself as the nurse.'>
													<UserAddOutlined
														style={{ color: !isDisabled ? 'var(--gray-9)' : 'inherit' }}
													/>
												</Tooltip>
											}
											style={{ borderColor: 'var(--gray-7)', borderRadius: '2px' }}
											onClick={(e) => {
												e.stopPropagation();
												handleAssignSelfAsNurse(record);
											}}
											disabled={isDisabled}
										/>
									);
								} else {
									return (
										<div
											onClick={(e) => {
												e?.stopPropagation();
											}}
											style={{ width: '32px', height: '32px', margin: 'auto', }}
										>
											<Popconfirm
												placement='left'
												title='Are you sure to un-assign yourself?'
												okText="Yes"
												cancelText="No"
												onConfirm={(e) => {
													e?.stopPropagation();
													if (!isDisabled) {
														unassignNurse({
															visitId: record.visit_id,
														});
													}
												}}
												onCancel={(e) => {
													e?.stopPropagation();
												}}
												disabled={isDisabled || record.nurse_id !== user_id}
											>
												<Tooltip title={`${record.nurse_first_name} ${record.nurse_last_name}`}>
													<Button
														type='link'
														shape='circle'
														style={{
															width: '32px',
															height: '32px',
															margin: 0,
															padding: 0,
														}}
														data-cy='unassignButton'
														onClick={(e) => {
															e.stopPropagation();
														}}
														disabled={!userHasPermissionToAssignAsNurse || record.nurse_id !== user_id}
													>
														<Avatar
															firstName={record.nurse_first_name}
															lastName={record.nurse_last_name}
															className='nurse-avatar'
														/>
													</Button>
												</Tooltip>
											</Popconfirm>
										</div>
									);
								}
							}}
						/>
						<Column
							align='center'
							title=''
							key='actions'
							width='10%'
							render={(text, record: VetDashboardVisit) => {
								return (
									<Button
										icon={<QuickActionIcon />}
										disabled={!userHasPermissionToAssign}
										style={{
											borderColor: 'var(--gray-7)',
											display: 'inline-flex',
											alignItems: 'center',
											justifyContent: 'center',
											opacity: userHasPermissionToAssign ? '1' : '.6',
										}}
										onClick={(e) => {
											e.stopPropagation();
											const selection = window.getSelection()?.toString();
											if (!selection) {
												setIntakeInfoVisit(record);
												setTriageLevel(record.triage_level);
												intakeForm.setFieldsValue({
													reason_for_visit: record.reason_for_visit,
													spike_3: record.spiked_at !== null && record.spiked_by !== null,
												})
												setIsIntakeInfoDrawerOpen(true);
											}
										}}
									/>
								)
							}}
						/>
					</>
				)
					: null}
			</Table>
			{!!intakeInfoVisit &&
				<IntakeInfoDrawer
					visit={intakeInfoVisit}
					form={intakeForm}
					open={isIntakeInfoDrawerOpen}
					setOpen={setIsIntakeInfoDrawerOpen}
					triageLevel={triageLevel}
					setTriageLevel={setTriageLevel}
				/>}
		</>
	);
};

interface InboundCallsTableProps {
	calls: InboundCall[];
	title: string;
	defaultExpanded: boolean;
	dataCy?: string;
}

export const InboundCallsTable = ({
	calls,
	title,
	defaultExpanded,
	dataCy,
}: InboundCallsTableProps) => {
	const [isExpanded, setIsExpanded] = useState(defaultExpanded);
	const [showInactiveCalls, setShowInactiveCalls] = useState(true);
	const [callLogRecord, setCallLogRecord] = useState<InboundCall>();
	const [isCallLogDrawerOpen, setIsCallLogDrawerOpen] = useState(false);
	const [form] = Form.useForm();
	const filteredCalls = calls?.filter(
		(call: InboundCall) =>
			showInactiveCalls ||
			call.status_code !== "Disconnected"
	).sort((a, b) => b.submitted_at - a.submitted_at);

	return (
		<>
			<Table
				locale={{ emptyText: ' ' }}
				rowKey={(record) => `call_${record.session_id}`}
				className={`dashboard-${isExpanded ? 'expanded' : 'minimized'}`}
				rowClassName={classes.row}
				data-cy={dataCy}
				title={() => (
					<Row align='middle' gutter={[8, 4]}>
						<Col>
							<Button
								size='small'
								icon={
									isExpanded ? (
										<MinusOutlined />
									) : (
										<PlusOutlined />
									)
								}
								onClick={() => setIsExpanded(!isExpanded)}
							/>
						</Col>
						<Col>
							<h3>
								{title}
								{` (${!!calls ? calls.length : 0}) `}
							</h3>
						</Col>
						{
							isExpanded ?
								(<Col flex="auto" style={{ textAlign: "right" }}>
									<span>Show only active calls</span>
									<Switch
										className={classes.switch}
										checked={!showInactiveCalls}
										onClick={() => setShowInactiveCalls(!showInactiveCalls)}
										style={{ margin: "0 1rem" }}
									/>
								</Col>)
								: null
						}
					</Row>
				)}
				dataSource={isExpanded ?
					filteredCalls : []
				}
				onRow={(record, rowIndex) => {
					return {
						onClick: () => {
							const selection = window.getSelection()?.toString();
							if (!selection) {
								setCallLogRecord(record);
								form.setFieldsValue({ ...record });
								setIsCallLogDrawerOpen(true);
							}
						},
						onDoubleClick: (event) => { }, // double click row
						onContextMenu: (event) => { }, // right button click row
						onMouseEnter: (event) => { }, // mouse enter row
						onMouseLeave: (event) => { }, // mouse leave row
					};
				}}
				pagination={isExpanded ? { size: "small", total: filteredCalls.length, defaultPageSize: 5, pageSizeOptions: ["5", "10", "20"] } : false}
			>
				{isExpanded ? <Column title='From' dataIndex='from_number' key='from_number' width='20%' /> : null}
				{isExpanded ? <Column title='Caller ID' dataIndex='from_name' key='from_name' width='20%' /> : null}
				{isExpanded ? <Column title='Status' dataIndex='status_code' key='status_code' width='10%' /> : null}
				{isExpanded ? <Column title='Start time' dataIndex='submitted_at' key='submitted_at' width='10%' render={displayCallStartTime} /> : null}
				{isExpanded ? <Column title='Notes' key='session_id' width='20%' render={generateNotePreview} /> : null}
				{isExpanded ? <Column title='Contacts' key='contacts' width='20%' render={generateContactsPreview} /> : null}
			</Table >
			<CallLogDrawer formInstance={form} record={callLogRecord} open={isCallLogDrawerOpen} setOpen={setIsCallLogDrawerOpen} />
		</>
	);
};

const displayCallStartTime = (record_date: number) => {
	return new Date(record_date * 1000).toLocaleTimeString()
}

const generateContactsPreview = (record: InboundCall) => {
	const buttons = record.contacts?.map((obj) => (
		<NavLink to={`/customer/${obj.owner_id}`}>
			<Button className='contact-button' type='link' icon={<ContactIcon />}>
				{obj.name}
			</Button>
		</NavLink>
	));
	return <>{buttons}</>;
};

const checkIfRecordIsComplete = (record: InboundCall) => {
	return record.call_type !== null &&
		record.doctor_id !== null &&
		record.patient_name !== null &&
		record.reason !== null &&
		record.recommendation !== null &&
		record.patient_name !== null &&
		record.spoke_with_doctor !== null &&
		record.send_check_in_link !== null
}

const generateNotePreview = (record: InboundCall) => {
	return (
		<Button>
			{checkIfRecordIsComplete(record) ? (
				<CheckCircleOutlined style={{ fontSize: '16px', color: 'var(--green-alert-success)' }} />
			) : (
				<ExclamationCircleOutlined style={{ fontSize: '16px', color: 'var(--veg-red)' }} />
			)}
			Notes & Link
		</Button>
	);
};

const DashboardWithProvider = () => (
	<ComposeBoxProvider>
		<Dashboard />
	</ComposeBoxProvider>
);

export default DashboardWithProvider;
