import moment, { Moment } from 'moment';
import { isFrequencyValid, calculateNumberOfTotalActions } from './ghostActionUtils';
import { DiagnosticSearchOption, MedicineSearchOption, TaskSearchOption } from './types/InstructionOrderTypes';

export const next30Min = (time: Moment) => {
	let remainder = 30 - (time.minute() % 30);
	
	return moment(time).add(remainder, 'minutes').subtract(time.second(), 'seconds'); // ensure the actions are even to the minute
};

export const escapeClose = (show: boolean, setShow: Function) => {
	const close = (e: any) => {
		if (e.key === 'Escape') {
			window.removeEventListener('keydown', close);
			setShow(false);
		}
	};
	window.addEventListener('keydown', close);
};

export function isLastValueSame<Type>(
	firstValue: Type,
): (currentValue: Type) => Boolean {
	let lastValue = firstValue;
	return (currentValue: Type) => {
		if (lastValue === currentValue) {
			return true;
		} else {
			lastValue = currentValue;
			return false;
		}
	};
}

export const setPackageInstructionTimes = (options: {
	duration_mins: number | null;
	start_delay_mins: number | null;
	baseTime?: moment.Moment | null;
}): { end_time: number; start_time: number } => {
	const { duration_mins, start_delay_mins, baseTime } = options;
	const defaultDurationMin = 1 * 24 * 60;
	const startOffset = start_delay_mins || 0;
	const endOffset = startOffset + (duration_mins || defaultDurationMin);
	const localBaseTime = baseTime || moment();
	const startTime = localBaseTime.clone().add(startOffset, 'minutes').unix();
	const endTime = localBaseTime.clone().add(endOffset, 'minutes').unix();

	return {
		end_time: endTime,
		start_time: startTime,
	};
};


export const checkIfSerialCheaper = (
    option: {serial: boolean, serial_hours: number | null, price_cents_serial: number | null, cents: number | null},
    frequency?: string, 
    start_time?: number, 
    end_time?: number, 
    supplemental_cost_cents?: number | null,
    dose?: number
    ): boolean => {

    if (!frequency || !option.serial || !option.serial_hours || !option.price_cents_serial || !option.cents) {
        return false;
    }
    if (!isFrequencyValid(frequency)) {
        return false;
    }
	const startTime = start_time ?? moment().unix();
    const endTime = end_time ?? moment(startTime * 1000).add(option.serial_hours, 'hour').unix(); 

	const numberOfSerialOrders = CalcNumberOfSerialOrders(startTime, endTime, option.serial_hours)
    const serialPrice = option.price_cents_serial * option.serial_hours * numberOfSerialOrders;

    const numberOfActions = calculateNumberOfTotalActions(startTime, endTime, startTime, endTime, frequency);
    const supplementalCost = supplemental_cost_cents ?? 0;
    const priceOfActions = ((option.cents * (dose ?? 1)) + supplementalCost) * numberOfActions;
    return serialPrice < priceOfActions;

}

// start and end will be in seconds from epoch
const CalcNumberOfSerialOrders = (startTime: number,  endTime: number, serial_hours: number) => {
	
	const numberOfSerialOrders = Math.ceil((endTime - startTime) / (serial_hours * 60 * 60))
	
	return numberOfSerialOrders;


}
