import {
    DownloadOutlined,
    EditOutlined,
    MoreOutlined,
    UpOutlined,
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Col,
    Collapse,
    Dropdown,
    Empty,
    Form,
    Menu,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
    message
} from 'antd';
import afternoon_icon from 'assets/img/afternoon_icon.png';
import morning_icon from 'assets/img/morning_icon.png';
import night_icon from 'assets/img/night_icon.png';
import CustomCollapse from 'components/CustomCollapse';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import { useGetUserDataQuery } from 'services/authService';
import {
    useDiscontinueInstructionMutation,
    useGetInstructionsByVisitIdQuery,
    useLazyGetInstructionsByVisitIdQuery,
} from 'services/instructionService';
import { useLazyDownloadDischargeDocQuery } from 'services/taskService';
import {
    useAddDischargeTogoMedMutation,
    useGetDischargeNoteByVisitIdQuery,
    useGetDischargeTogoMedsByVisitIdQuery,
    useGetVisitByIdQuery,
    useLazyGetDischargeTogoMedsByVisitIdQuery,
    useModifyDischargeTogoMedMutation,
    useModifyFinalizeDischargeTogoMedMutation,
    useValidateEditDischargeNoteMutation,
} from 'services/visitService';
import { BASE_QUERY_OPTIONS, COOKIE_DURATION, FormName, TASKS_HOST } from 'utils/constants';
import {
    DischargeExistingInstruction,
    ExpandedPatient,
    PatientRecordVisit,
} from 'utils/dataTypes';
import { closedErrorMsg } from 'utils/disableFuncs';
import { roundTo } from 'utils/formatFuncs';
import { checkCookieExists, deleteCookie } from 'utils/miscFuncs';
import { MedicineSearchOption } from 'utils/types/InstructionOrderTypes';
import { OrderSearch, OrderSearchProps } from '../OrderSearch';
import { DischargeNotesModal } from './DischargeNotesModal';
import { ToGoMedForm } from './TogoMedForm';
import WrittenPrescription from './WrittenPrescription';
import './index.css';
import { generateNewTogoMed, showNoChargeModal } from './utils';


export const NEXT_ADMIN_FORMAT = 'MM-DD, hh:mm a';
export const DISCHARGE_MEDS_DATE_FORMAT = 'MMM Do, hh:mm a';
export const DISCHARGE_FOLLOW_UP_DATE_FORMAT = 'MMM Do';

interface DischargeTabProps {
    orderSearchProps: OrderSearchProps;
    isTabActive: boolean;
    patient: ExpandedPatient;
    updateIsInvoiceLocked: () => boolean;
}

const { Panel } = Collapse;

const Tooltip_Message_No_Doctor = "This medication can not be finalized without a doctor assigned to the case"

export const DischargeTab = (props: DischargeTabProps) => {
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const [isDischargeNotesModalVisible, setIsDischargeNotesModalVisible] = useState(false);
    const [modalDischargeNote, setModalDischargeNote] = useState('');

    const [getInstructions] = useLazyGetInstructionsByVisitIdQuery();
    const {
        data: dischargeNote,
        isFetching: isDischargeNoteFetching,
        refetch: refetchGetDischargeNoteByVisitId,
    } = useGetDischargeNoteByVisitIdQuery(visitId);
    const {
        data: togoMeds,
        isFetching: isTogoMedsFetching,
        isSuccess: isSuccessToGoMeds,
        refetch: refetchGetDischargeTogoMedsByVisitId,
    } = useGetDischargeTogoMedsByVisitIdQuery(visitId);
    const { data: instData, refetch: refetchGetInstructionsByVisitId } = useGetInstructionsByVisitIdQuery({ visitId });
    const [downloadDischargeDoc, { isLoading: isDownloadingDischargeDoc }] = useLazyDownloadDischargeDocQuery();
    const { data: currentVisit, refetch: refetchGetVisitById } = useGetVisitByIdQuery(visitId, BASE_QUERY_OPTIONS);

    const [validateEditDischargeNote] = useValidateEditDischargeNoteMutation();

    const isVisitFinalized = Boolean(currentVisit?.finalized_at);

    const ongoingMedications = togoMeds?.filter(
        (togoMed) => togoMed.discontinued_at === null,
    );

    useEffect(() => {
        if (dischargeNote) {
            setModalDischargeNote(dischargeNote.content);
        }
    }, [dischargeNote]);

    useEffect(() => {
        if (isSuccessToGoMeds && !isTogoMedsFetching) {
            getInstructions({visitId});
        }
    }, [isSuccessToGoMeds, isTogoMedsFetching]);

    useEffect(() => {
        if (props.isTabActive) {
            refetchGetDischargeTogoMedsByVisitId();
            refetchGetDischargeNoteByVisitId();
            refetchGetInstructionsByVisitId();
            refetchGetVisitById();
        }
    }, [props.isTabActive]);

    return (
        <div className='discharge-section'>
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <div id="cypressTag" className="normalRun" style={{display:'none'}} />
                    <Row style={{ marginBottom: '20px' }}>
                        <Col span={12}>
                            <Typography.Text
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    lineHeight: '28px',
                                }}
                            >
                                To Go Home
                            </Typography.Text>
                        </Col>
                        
                        <Col
                            span={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                icon={<DownloadOutlined />}
                                loading={isDownloadingDischargeDoc}
                                style={{ marginRight: '8px' }}
                                onClick={() => {
                                    downloadDischargeDoc({
                                        visitId,
                                        isDraft:
                                            isVisitFinalized ||
                                                ['discharged', 'checked_out'].includes(currentVisit?.status ?? '')
                                                    ? false
                                                    : true,
                                    });
                                }}
                            >
                                Discharge Summary
                            </Button>
                        </Col>
                    </Row>
                    <CustomCollapse ghost defaultActiveKey={[0, 2]}>
                        <Panel header={<div>To Go Medications</div>} key={0}>
                            <DischargeOngoingMedications
                                ongoingMedications={ongoingMedications || []}
                                orderSearchProps={props.orderSearchProps}
                                isVisitFinalized={isVisitFinalized}
                                currentVisit={currentVisit ?? ({} as PatientRecordVisit)}
                                updateIsInvoiceLocked={props.updateIsInvoiceLocked}
                            />
                        </Panel>
                        <Panel header={<div>Written Prescriptions</div>} key={1} style={{ marginTop: '32px' }}>
                            <WrittenPrescription isTabActive={props.isTabActive} />
                        </Panel>
                        <Panel
                            header={<div>Notes and Follow-up</div>}
                            key={2}
                            style={{ marginTop: '32px' }}
                        >
                            <Row>
                                <Col span={16}>
                                    <Typography.Text strong>
                                        Primary Vet Follow-up:
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text
                                        style={{ color: 'var(--gray-8' }}
                                    >
                                        {currentVisit?.recheck_time}
                                    </Typography.Text>
                                </Col>
                                <Col
                                    span={8}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        icon={<EditOutlined />}
                                        disabled={isDischargeNoteFetching}
                                        data-cy={'dischargeNoteEdit'}
                                        onClick={() => {
                                            if (isVisitFinalized) {
                                                closedErrorMsg(isVisitFinalized);
                                            } else {
                                                validateEditDischargeNote({ visitId })
                                                    .unwrap()
                                                    .then(() => setIsDischargeNotesModalVisible(true))
                                                    .catch((err) => {
                                                        if (err.originalStatus === 403) {
                                                            message.error(err.data);
                                                        }
                                                    });
                                            }
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '24px' }}>
                                <Col>
                                    <Typography.Text strong>
                                        Discharge Notes:
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text
                                        style={{ color: 'var(--gray-8' }}
                                    >
                                        <Markdown
                                            remarkPlugins={[remarkBreaks]}
                                            children={modalDischargeNote}
                                        />
                                    </Typography.Text>
                                </Col>
                            </Row>
                            <DischargeNotesModal
                                existingNote={dischargeNote}
                                recheckTime={currentVisit?.recheck_time ?? null}
                                currentVisit={currentVisit ?? ({} as PatientRecordVisit)}
                                isDischargeNotesModalVisible={
                                    isDischargeNotesModalVisible
                                }
                                setIsDischargeNotesModalVisible={
                                    setIsDischargeNotesModalVisible
                                }
                                modalDischargeNote={modalDischargeNote}
                                setModalDischargeNote={setModalDischargeNote}
                            />
                        </Panel>
                    </CustomCollapse>
                </Col>
            </Row>
        </div>
    );
};

interface DischargeOngoingMedicationsProps {
    ongoingMedications: DischargeExistingInstruction[];
    orderSearchProps: OrderSearchProps;
    isVisitFinalized: boolean;
    currentVisit: PatientRecordVisit;
    updateIsInvoiceLocked: () => boolean;
}
export const DischargeOngoingMedications = ({
    ongoingMedications,
    orderSearchProps,
    isVisitFinalized,
    currentVisit,
    updateIsInvoiceLocked,
}: DischargeOngoingMedicationsProps) => {
    let { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const [addTogoMed] =
        useAddDischargeTogoMedMutation();
    const [modifyTogoMed] =
        useModifyDischargeTogoMedMutation();
    const [discontinueInstruction, { isSuccess: isSuccessDelete }] =
        useDiscontinueInstructionMutation();
    const [finalizeTogoMed] =
        useModifyFinalizeDischargeTogoMedMutation();
    const {addComposeBox} = useComposeBoxContext();

    const [togoMedForm] = Form.useForm();

    const [getToGoMeds] = useLazyGetDischargeTogoMedsByVisitIdQuery();
    useEffect(() => {
        if (isSuccessDelete) {
            getToGoMeds(visitId);
        }
    }, [isSuccessDelete]);

    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );

    const togoMedFormBottomBar = ({
        fullSelectedItem,
        existingTogoMed,
        currentVisit
    }: {
        fullSelectedItem?: MedicineSearchOption,
        existingTogoMed?: DischargeExistingInstruction,
        currentVisit :PatientRecordVisit,
    }) => {
        return (
            <Row
                className='ant-modal-footer'
                justify='end'
                style={{
                    margin: '10px -24px -24px',
                }}
            >
                <Col span={24}>
                    <Dropdown.Button
                        type='primary'
                        icon={<UpOutlined style={{ fontSize: '10px' }} />}
                        placement='topRight'
                        htmlType='submit'
                        data-cy={'dropdownButton'}
                        onClick={() => {
                            togoMedForm
                                .validateFields()
                                .then(() => {
                                    onFinish({
                                        values: togoMedForm.getFieldsValue(),
                                        fullSelectedItem: fullSelectedItem,
                                        existingTogoMed: existingTogoMed,
                                        finalized: false,
                                    });
                                })
                                .catch(() => { });
                        }}
                        overlay={
                            <Menu>
                                <Menu.Item onClick={() => {
                                    togoMedForm
                                        .validateFields()
                                        .then(() => {
                                            togoMedForm.submit();
                                            onFinish({
                                                values: togoMedForm.getFieldsValue(),
                                                fullSelectedItem: fullSelectedItem,
                                                existingTogoMed: existingTogoMed,
                                                finalized: false,
                                            });
                                        })
                                        .catch(() => { });
                                }}>
                                    Save as Draft
                                </Menu.Item>
                                <Menu.Item
                                 disabled={!currentVisit.doctor_id}
                                 onClick={() => {
                                    togoMedForm
                                        .validateFields()
                                        .then(() => {
                                            if (!checkCookieExists('do_not_show_finalize_togomed_warning_modal')) {
                                                finalizeMedicationsModal(
                                                    'Save and Finalize Medication',
                                                    () => {
                                                        togoMedForm.submit();
                                                        onFinish({
                                                            values: togoMedForm.getFieldsValue(),
                                                            fullSelectedItem: fullSelectedItem,
                                                            existingTogoMed: existingTogoMed,
                                                            finalized: true,
                                                        });
                                                    }
                                                );
                                            }
                                            else {
                                                togoMedForm.submit();
                                                onFinish({
                                                    values: togoMedForm.getFieldsValue(),
                                                    fullSelectedItem: fullSelectedItem,
                                                    existingTogoMed: existingTogoMed,
                                                    finalized: true,
                                                });
                                            }
                                        })
                                        .catch(() => { });
                                }}>
                                    <Tooltip title={!currentVisit.doctor_id ? Tooltip_Message_No_Doctor : "" }>
                                        Save and Finalize Medication
                                    </Tooltip>
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        Save as Draft
                    </Dropdown.Button>
                </Col>
            </Row>
        )
    }

    const finalizeTogoMedFunction = (existingTogoMed: DischargeExistingInstruction) => {
        finalizeTogoMed({
            visitId,
            togomed_id: existingTogoMed.id,
        }).then((response) => {
            if ('data' in response) {
                const successMessage = existingTogoMed.controlled_drug
                    ? `${existingTogoMed.name} has been successfully transferred, printed, billed and sent to Cubex.`
                    : `${existingTogoMed.name} has been successfully transferred, printed, and billed.`;
                message.success(successMessage);
            }
            else {
                message.error({
                    className: 'multi-line-message',
                    content: errorMessage(`Unable to finalize ${existingTogoMed.name}.`),
                })
            }
        });
    };

    const finalize = (existingTogoMed: DischargeExistingInstruction) => {
        const isInvoiceLocked = updateIsInvoiceLocked();
        if (isInvoiceLocked) {
            showNoChargeModal(() => {
                finalizeTogoMedFunction(existingTogoMed);
            });
        } else {
            finalizeTogoMedFunction(existingTogoMed);
        }
    };

    const finalizeMedicationsModal = (
        okText: string,
        onOk: () => void,
    ) => {
        Modal.confirm({
            title: 'Finalize Medications',
            centered: true,
            maskClosable: true,
            autoFocusButton: "ok",
            okText: okText,
            onCancel: () => {
                deleteCookie('do_not_show_finalize_togomed_warning_modal=');
            },
            onOk: onOk,
            content: (
                <Space direction='vertical' size='large'>
                    <Row>
                        <Col>
                            <Typography.Paragraph>
                                Marking a medication as finalized will...
                            </Typography.Paragraph>

                            <ol style={{ margin: 0, padding: 0, listStylePosition: 'inside' }}>
                                <li>Send medication to treatment sheet.</li>
                                <li>Print Rx label.</li>
                                <li>Add charge to the invoice.</li>
                                <li>Pushes the request to Cubex if applicable.</li>
                            </ol>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        document.cookie = `do_not_show_finalize_togomed_warning_modal=1;max-age=${COOKIE_DURATION}`;
                                    }
                                    else {
                                        deleteCookie('do_not_show_finalize_togomed_warning_modal=');
                                    }
                                }}
                            >
                                Do not show me this again
                            </Checkbox>
                        </Col>
                    </Row>
                </Space>
            ),
        });

    };

    const onFinishTogoMedForm = ({
        values,
        fullSelectedItem,
        existingTogoMed,
        finalized,
    }: {
        values: any,
        fullSelectedItem?: MedicineSearchOption,
        existingTogoMed?: DischargeExistingInstruction,
        finalized: boolean,
    }) => {
        if (fullSelectedItem) {
            const newTogoMed = generateNewTogoMed(
                fullSelectedItem,
                visitId,
                {
                    dispense_value: parseFloat(
                        values.dispense_value,
                    ),
                    next_dose: values.next_dose,
                    morning_doses: parseFloat(values.morning_doses),
                    afternoon_doses: parseFloat(
                        values.afternoon_doses,
                    ),
                    night_doses: parseFloat(values.night_doses),
                    instructions: values.instructions,
                },
            );
            addTogoMed({
                visitId,
                body: {
                    ...newTogoMed,
                    userId: loggedInUserData?.user_id,
                    is_shown_on_tx_sheet: finalized,
                    controlled_drug: fullSelectedItem.controlled_drug,
                },
            }).then((response) => {
                if ('data' in response) {
                    const successMessage = finalized
                        ? fullSelectedItem.controlled_drug
                            ? `${newTogoMed.name} has been saved, transferred, printed, billed, and sent to Cubex.`
                            : `${newTogoMed.name} has been saved, transferred, printed, and billed.`
                        : `${newTogoMed.name} added as to go home medication.`;
                    message.success(successMessage);
                } else {
                    message.error({
                        className: 'multi-line-message',
                        content: errorMessage(`Unable to add ${newTogoMed.name}.`),
                    });
                }
            });
        } else if (existingTogoMed) {
            const { instruction_status, start_time, controlled_drug, ...rest } = existingTogoMed;
            modifyTogoMed({
                visitId,
                body: {
                    ...rest,
                    dispense_value: parseFloat(values.dispense_value),
                    next_dose: values.next_dose,
                    morning_doses: parseFloat(values.morning_doses),
                    afternoon_doses: parseFloat(
                        values.afternoon_doses,
                    ),
                    night_doses: parseFloat(values.night_doses),
                    instructions: values.instructions,
                    is_shown_on_tx_sheet: finalized,
                },
            }).then((response) => {
                if ('data' in response) {
                    const successMessage = finalized
                        ? existingTogoMed.controlled_drug
                            ? `${existingTogoMed.name} has been saved, transferred, printed, and billed and sent to Cubex.`
                            : `${existingTogoMed.name} has been saved, transferred, printed, and billed.`
                        : `${existingTogoMed.name} edited.`;
                    message.success(successMessage);
                } else {
                    message.error({
                        className: 'multi-line-message',
                        content: errorMessage(`Unable to edit ${existingTogoMed.name}.`),
                    });
                }
            });
        }
    };

    const onFinish = ({
        values,
        fullSelectedItem,
        existingTogoMed,
        finalized,
    }: {
        values: any,
        fullSelectedItem?: MedicineSearchOption,
        existingTogoMed?: DischargeExistingInstruction,
        finalized: boolean,
    }) => {
        const isInvoiceLocked = updateIsInvoiceLocked();
        if (finalized && isInvoiceLocked) {
            showNoChargeModal(() => {
                onFinishTogoMedForm({
                    values,
                    fullSelectedItem,
                    existingTogoMed,
                    finalized,
                });
            });
        } else {
            onFinishTogoMedForm({
                values,
                fullSelectedItem,
                existingTogoMed,
                finalized,
            });
        } 
    };

    const removeTogoMed = (existingTogoMed: DischargeExistingInstruction) => {
        discontinueInstruction(
            {
                visitId,
                instructionId:
                    existingTogoMed.related_instruction_id,
                on_behalf_of:
                    loggedInUserData?.user_id ??
                    0,
            },
        ).then((response) => {
            if ('data' in response) {
                message.success(
                    `${existingTogoMed.name} has been removed.`,
                );
            }
            else {
                message.error({
                    className: 'multi-line-message',
                    content: errorMessage(`Unable to remove ${existingTogoMed.name}.`),
                });
            }
        });
    };
    

    const getFinalizeTooltipMessage = (isOnTreatmentSheet: boolean, noDoctorOnVisit: boolean, isControlledDrug: boolean) => {
        if (isOnTreatmentSheet){
            return ''
        }else if (noDoctorOnVisit) {
            return Tooltip_Message_No_Doctor
        } else if (isControlledDrug){
            return 'Sends to tx sheet, adds to billing, prints label, and sends to Cubex.'
        } else {
            return 'Sends to tx sheet, adds to billing, and prints label.'
        }

    }

    const TogoMedDropdown = ({
        existingTogoMed,
        currentVisit,
    }: {
        existingTogoMed: DischargeExistingInstruction;
        currentVisit: PatientRecordVisit;
    }) => {
        return (
            <Dropdown
                placement='bottomRight'
                overlay={
                    <Menu>
                        <Menu.Item
                            disabled={existingTogoMed.is_shown_on_tx_sheet}
                            onClick={() => {
                                if (isVisitFinalized) {
                                    closedErrorMsg(
                                        isVisitFinalized,
                                    );
                                    return;
                                }
                                addComposeBox({
                                    formName: FormName.discharge_togo_med,
                                    content: (
                                        <ToGoMedForm
                                            form={togoMedForm}
                                            existingTogoMed={existingTogoMed}
                                        />
                                    ),
                                    id: FormName.discharge_togo_med,
                                    title: existingTogoMed.name,
                                    bottomBar: togoMedFormBottomBar({
                                        existingTogoMed: existingTogoMed,
                                        currentVisit
                                    }),
                                });
                            }}
                        >
                            Edit
                        </Menu.Item>
                        <Menu.Item
                            disabled={existingTogoMed.is_shown_on_tx_sheet || !currentVisit.doctor_id}
                            onClick={() => {
                                if (isVisitFinalized) {
                                    closedErrorMsg(
                                        isVisitFinalized,
                                    );
                                    return;
                                }
                                if (existingTogoMed.instructions === null || existingTogoMed.next_dose === null) {
                                    Modal.confirm({
                                        title: 'Missing Information',
                                        centered: true,
                                        maskClosable: true,
                                        autoFocusButton: "ok",
                                        okText: 'Edit Medication',
                                        onOk: () => {
                                            addComposeBox({
                                                formName: FormName.discharge_togo_med,
                                                content: (
                                                    <ToGoMedForm
                                                        form={togoMedForm}
                                                        existingTogoMed={existingTogoMed}
                                                    />
                                                ),
                                                id: FormName.discharge_togo_med,
                                                title: existingTogoMed.name,
                                                bottomBar: togoMedFormBottomBar({
                                                    existingTogoMed: existingTogoMed,
                                                    currentVisit
                                                }),
                                            });
                                        },
                                        content: (
                                            <Space direction='vertical' size='large'>
                                                <Row>
                                                    <Col>
                                                        <Typography.Paragraph>
                                                            Instructions and next dose are required prior to finalizing a medication.
                                                        </Typography.Paragraph>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        ),
                                    });
                                    return;
                                }
                                if (!checkCookieExists('do_not_show_finalize_togomed_warning_modal')) {
                                    finalizeMedicationsModal(
                                        'Finalize Medication',
                                        () => finalize(existingTogoMed)
                                    );
                                }
                                else {
                                    finalize(existingTogoMed);
                                }
                            }}
                        >
                            <Tooltip
                                title={getFinalizeTooltipMessage(!!existingTogoMed.is_shown_on_tx_sheet, !currentVisit.doctor_id, !!existingTogoMed.controlled_drug)}
                            >
                                Finalize Medication
                            </Tooltip>
                        </Menu.Item>
                        <Menu.Item
                            disabled={!existingTogoMed.is_shown_on_tx_sheet}
                            onClick={async () => {
                                const response =
                                    await fetch(
                                        `${TASKS_HOST}/visit/${visitId}/togomed/print/${existingTogoMed.id}`,
                                    );
                                if (response.ok) {
                                    const responseJson =
                                        await response.json();
                                    if (
                                        typeof responseJson
                                            .data
                                            .print_label_code ===
                                        'number'
                                    ) {
                                        message.success(
                                            `${existingTogoMed.name} label reprinted.`,
                                        );
                                        return;
                                    }
                                }
                                message.error({
                                    className: 'multi-line-message',
                                    content: errorMessage(`Unable to print ${existingTogoMed.name}.`),
                                });
                            }}
                        >
                            {existingTogoMed.is_shown_on_tx_sheet ? 'Reprint Rx Label' : 'Print Rx Label'}
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => {
                                if (isVisitFinalized) {
                                    closedErrorMsg(
                                        isVisitFinalized,
                                    );
                                    return;
                                }
                                if (existingTogoMed.is_shown_on_tx_sheet) {
                                    Modal.confirm({
                                        title: 'Remove To Go Home Medication',
                                        centered: true,
                                        maskClosable: true,
                                        autoFocusButton: null,
                                        okText: 'Remove Medication',
                                        onOk: () => {
                                            removeTogoMed(existingTogoMed);
                                        },
                                        content: (
                                            <>
                                                <Typography.Paragraph>
                                                    This medication will be marked as
                                                    Discontinue on the treatment sheet.
                                                </Typography.Paragraph>
                                                <Typography.Paragraph>
                                                    You <Typography.Text strong>MUST manually adjust the charges</Typography.Text> on the invoice.
                                                </Typography.Paragraph>
                                            </>
                                        ),
                                    });
                                }
                                else {
                                    removeTogoMed(existingTogoMed);
                                }

                            }}
                        >
                            Remove
                        </Menu.Item>
                    </Menu>
                }
            >
                <MoreOutlined className='plus-dropdown' />
            </Dropdown>
        )
    };

    return (
        <Row gutter={[12, 12]} justify='space-between'>
            <Col>
                <Row gutter={[12, 12]} align='middle'>
                    <Col>
                        <OrderSearch
                            {...orderSearchProps}
                            placeholder='Add medication ...'
                            handleSelect={(
                                fullSelectedItem: MedicineSearchOption,
                            ) => {
                                addComposeBox({
                                    formName: FormName.discharge_togo_med,
                                    content: (
                                        <ToGoMedForm
                                            fullSelectedItem={fullSelectedItem}
                                            form={togoMedForm}
                                        />
                                    ),
                                    id: FormName.discharge_togo_med,
                                    title: fullSelectedItem.name,
                                    bottomBar: togoMedFormBottomBar({
                                        fullSelectedItem: fullSelectedItem,
                                        currentVisit
                                    }),
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Table
                    tableLayout='fixed'
                    locale={{
                        emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description='No to go home medications prescribed.'
                            />
                        ),
                    }}
                    rowKey={(record) =>
                        `instruction_${record.related_instruction_id
                        }`
                    }
                    className='discharge-instructions-table'
                    pagination={false}
                    size='small'
                    dataSource={ongoingMedications
                        .filter((med) => med.togo)
                        .sort((a, b) => (a.created_at < b.created_at ? -1 : 1))}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            width: '25%',
                            sorter: (a, b) => a.name.localeCompare(b.name),
                            render: (text, record) => {
                                return (
                                    <>
                                        <Row>
                                            <Col>
                                                <b>{text}</b> {!record.is_shown_on_tx_sheet && <Tag color='volcano'>Draft</Tag>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div
                                                    style={{
                                                        marginTop: '8px',
                                                        fontSize: '12px',
                                                        fontWeight: 'normal',
                                                        color: 'var(--gray-7',
                                                    }}
                                                >
                                                    Instructions
                                                </div>
                                                {record.instructions}
                                            </Col>
                                        </Row>
                                    </>
                                );
                            },
                        },
                        {
                            title: 'Qty',
                            className: 'centered-column',
                            dataIndex: 'dispense_value',
                            key: 'dispense_value',
                            width: '5%',
                            render: (text, record) =>
                                `${record.dispense_value} ${record.dispense_unit}`,
                        },
                        {
                            title: 'Next Dose',
                            className: 'centered-column',
                            dataIndex: 'next_dose',
                            key: 'next_dose',
                            width: '7%',
                        },
                        {
                            title: () => (
                                <Tooltip title='Morning'>
                                    <img
                                        alt={'Morning'}
                                        className='dose-icon-morning'
                                        src={morning_icon}
                                    />
                                </Tooltip>
                            ),
                            className: 'centered-column',
                            dataIndex: 'morning_doses',
                            key: 'morning_doses',
                            width: '5%',
                            render: value => roundTo(value, 3)
                        },
                        {
                            title: () => (
                                <Tooltip title='Afternoon'>
                                    <img
                                        alt={'Afternoon'}
                                        className='dose-icon-afternoon'
                                        src={afternoon_icon}
                                    />
                                </Tooltip>
                            ),
                            className: 'centered-column',
                            dataIndex: 'afternoon_doses',
                            key: 'afternoon_doses',
                            width: '5%',
                            render: value => roundTo(value, 3)
                        },
                        {
                            title: () => (
                                <Tooltip title='Night'>
                                    <img
                                        alt={'Night'}
                                        className='dose-icon-night'
                                        src={night_icon}
                                    />
                                </Tooltip>
                            ),
                            className: 'centered-column',
                            dataIndex: 'night_doses',
                            key: 'night_doses',
                            width: '5%',
                            render: value => roundTo(value, 3)
                        },
                        {
                            title: '',
                            dataIndex: '',
                            key: 'dropdown',
                            width: '5%',
                            render: (text, record) => <TogoMedDropdown existingTogoMed={record} currentVisit={currentVisit} />,
                        },
                    ]}
                />
            </Col>
        </Row>
    );
};

export const errorMessage = (message: string) => (
    <div>
        <div className='title'>Something went wrong.</div>
        {message} Please try again later.
    </div>
);
