import { Modal, Popover, message } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { ConfirmModal } from 'components/Modals/ConfirmModal';
import { confirmDeceasedModalContent } from 'components/Modals/ConfirmModalContent';
import { ReconfirmModal } from 'components/Modals/ReconfirmModal';
import { reconfirmDeceasedModalContent } from 'components/Modals/ReconfirmModalContent';
import { FormWrapper } from 'components/forms/FormWrapper';
import PopoverTitle from 'components/popups/PopoverTitle';
import { useFormSubmission } from 'hooks';
import { useComposeBoxContext } from 'hooks/ComposeBoxProvider';
import { FormInstance } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDischargePatientInstructionsMutation, useGetInstructionsByVisitIdQuery } from 'services/instructionService';
import { FormName } from 'utils/constants';
import { escapeClose } from 'utils/formFuncs';
import { scrollToElement } from 'utils/htmlFunctions';
import { useGetAllConsentDocumentsQuery } from 'services/visitService';
import moment from 'moment';
import './PopoverClosableForm.css';
import { isInstanceOfMedicineInstruction } from '../../utils/dataTypes';
import { filterFluidsCri } from 'utils/filterFuncs';

interface PopoverClosableFormProps {
    initialContent?: Object;
    title?: string;
    onFinish?: (values: any) => void; // Optional finish function for form
    formName: keyof typeof FormName;
    placement: TooltipPlacement;
    children: any;
    additionalState?: Object;
    form: FormInstance<any>;
    formJSX: () => JSX.Element;
    disabled?: boolean;
    dataCy?: string;
    cancelDeceasedReverted?: Function;
}

const PopoverClosableForm: React.FC<PopoverClosableFormProps> = ({
    initialContent,
    title,
    onFinish,
    formName,
    placement,
    children,
    additionalState,
    form,
    formJSX,
    disabled,
    dataCy,
    cancelDeceasedReverted,
}) => {
    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);
    const [onFinishHook] = useFormSubmission();

    const [editing, setEditing] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
	const [isReconfirmModalVisible, setIsReconfirmModalVisible] = useState(false);
    const [keepVisible, setKeepVisible] = useState(false);
    const [formValues, setFormValues] = useState({ name: '' });
    const [confirmModalType, setConfirmModalType] = useState('');

    const { data: instData } = useGetInstructionsByVisitIdQuery({ visitId });

    const [discontinuePatientInstructions] = useDischargePatientInstructionsMutation();

    const criAndFluidInstructions = useMemo(
        () => instData?.filter(filterFluidsCri) ?? [],
        [instData],
    );
    const hasOngoingCriOrFluid = useMemo(
        () => criAndFluidInstructions.some((instruction) => instruction.discontinued_at === null),
        [criAndFluidInstructions],
    );

    const { addComposeBox } = useComposeBoxContext();

    const contentRef = useCallback((titleDiv: HTMLDivElement | null) => {
        if (titleDiv) {
            scrollToElement(titleDiv);
        }
    }, []);

    const confirmModalContent = confirmModalType === 'deceased patient' ? confirmDeceasedModalContent(formValues.name) : null;
	const reconfirmModalContent = reconfirmDeceasedModalContent(formValues.name)
    const deceasedMedication = instData?.some((instruction) => (
		isInstanceOfMedicineInstruction(instruction) && instruction?.required_before_discharge === true && instruction.actions.some((a) => a.status === 'complete') 
	));
	const { data: consentFormsList } = useGetAllConsentDocumentsQuery(visitId);
	const deceasedDocument = consentFormsList?.some((document) => (
		document?.status === "approved" && (document?.prefix_char === "a" || document?.prefix_char === "c")) 
	);

    const discontinueCriAndFluidInstructions = () => {
        if (visitId) {
            discontinuePatientInstructions({
                visitId,
                ids:
                    criAndFluidInstructions
                        ?.filter((instruction) => instruction.discontinued_at === null)
                        .map((instruction) => instruction.id) ?? [],
            })
                .unwrap()
                .then(() => {
                    criAndFluidInstructions.forEach((instr) => message.success(`${instr.name} has been successfully discontinued.`));
                });
        }
    };

	const handleOk = () => {
		if (!!deceasedMedication || !!deceasedDocument) {
			submitFormData(formValues)
			form.resetFields();
			setIsConfirmModalVisible(!isConfirmModalVisible)
			setFormValues({name: ""})
			if (hasOngoingCriOrFluid) {
				Modal.confirm({
					title: 'Discontinue All CRI, Fluids, and Oxygen',
					content: (
						<>
							<p>CRIs, Fluids and Oxygen are automatically billed at an hourly rate.</p>
							<p>
                                In the case of euthanasia and to get an accurate final invoice amount, all CRI, fluid and
                                oxygen orders should be discontinued.
							</p>
						</>
					),
					okText: 'Discontinue All',
					centered: true,
					maskClosable: true,
					onOk: discontinueCriAndFluidInstructions,
				});
			}
		} else {
			setIsConfirmModalVisible(!isConfirmModalVisible)
			setIsReconfirmModalVisible(!isReconfirmModalVisible)
		}
	}

    const handleCancel = () => {
        form.resetFields();
        setIsConfirmModalVisible(!isConfirmModalVisible);
        setFormValues({ name: '' });
    };


	const handleReconfirmOk = () => {
		submitFormData(formValues)
		form.resetFields();
		setIsReconfirmModalVisible(!isReconfirmModalVisible)
		setFormValues({name: ""})
		if (hasOngoingCriOrFluid) {
			Modal.confirm({
				title: 'Discontinue All CRI, Fluids, and Oxygen',
				content: (
					<>
						<p>CRIs, Fluids and Oxygen are automatically billed at an hourly rate.</p>
						<p>
                            In the case of euthanasia and to get an accurate final invoice amount, all CRI, fluid and
                            oxygen orders should be discontinued.
						</p>
					</>
				),
				okText: 'Discontinue All',
				centered: true,
				maskClosable: true,
				onOk: discontinueCriAndFluidInstructions,
			});
		}		
	}

	const handleReconfirmCancel = () => {
		form.resetFields();
		setIsReconfirmModalVisible(!isReconfirmModalVisible)
		setFormValues({name: ""})
	}



    const submitFormData = (values: any) => {
        if (onFinish) {
            return onFinish(values);
        } else {
            onFinishHook(values, FormName[formName], additionalState);
        }
    };

    useEffect(() => {
        const birthday =
            initialContent && 'birthday' in initialContent ? (initialContent.birthday ? moment(initialContent.birthday) : '') : undefined;

        form.setFieldsValue({
            ...initialContent,
            birthday: birthday,
        });
    }, [editing]);

    const handleOnFinish = (values: any) => {
        const result = submitFormData(values);
        Promise.resolve(result)
            .then(() => {
                form.resetFields();
                setEditing(false);
            })
            .catch((e) => {
                if (e.error_code === 'email_already_exists') {
                    form.setFields([
                        {
                            name: 'email',
                            errors: [e.msg],
                        },
                    ]);
                } else {
                    message.error('Something went wrong');
                }
            });
    };

    const displayTitle = title ?? String(FormName[formName]);

    return (
        <>
            <Popover
                visible={(editing && !disabled) || keepVisible}
                destroyTooltipOnHide={true}
                overlayClassName='popover-closable-form owner-search-popover'
                trigger='click'
                onVisibleChange={(show) => {
                    if (keepVisible) {
                        return;
                    }
                    if (!show && form.isFieldsTouched()) {
                        addComposeBox({
                            formName: displayTitle,
                            content: formJSX(),
                            id: FormName[formName],
                            additionalState: additionalState,
                        });
                    }
                    if (!disabled) {
                        setEditing(show);
                        escapeClose(editing, setEditing);
                    }
                }}
                title={
                    <PopoverTitle
                        title={displayTitle}
                        minimizeFunction={() => {
                            addComposeBox({
                                formName: displayTitle,
                                content: formJSX(),
                                id: FormName[formName],
                                additionalState: additionalState,
                            });
                            setEditing(false);
                        }}
                        closeFunction={() => {
                            setEditing(false);
                        }}
                    />
                }
                content={
                    <div ref={contentRef} data-cy={dataCy} id='test-my-id'>
                        <FormWrapper
                            form={form}
                            onFormChange={form.setFieldsValue}
                            getFormData={form.getFieldsValue}
                            onFinishFailed={(errorInfo: any) => {
                                console.error('Failed:', errorInfo);
                            }}
                            onFinish={(allValues: any) => {
                                const {is_deceased_reverted, ...values} = allValues;
                                if (formName === 'patient_info' && !!is_deceased_reverted) {
                                    setKeepVisible(true);
                                    Modal.confirm({
                                        title: 'Remove Deceased Date',
                                        content: (
                                            <>
                                                <p>By removing the deceased date for <strong>{values.name}</strong> the following will happen...</p>
                                                <p>1. Require checkout requirements like <strong>referral source</strong> at checkout.</p>
                                                <p>2. Allow the <strong>survey</strong> and <strong>follow-up text</strong> to send at close.</p>
                                                
                                            </>
                                        ),
                                        okText: 'Remove and Save',
                                        cancelText: 'Go Back',
                                        centered: true,
                                        maskClosable: true,
                                        onOk: () => {
                                            handleOnFinish(values);
                                            setKeepVisible(false);
                                        },
                                        onCancel: () => {
                                            setKeepVisible(false);
                                            if (cancelDeceasedReverted) {
                                                cancelDeceasedReverted();
                                            }                                      
                                        },
                                        className: 'remove-deceased-date-modal',
                                        zIndex: 1040,
                                    });
                                }
                                else if (formName === 'patient_info' && !!values.deceased_at && !!values.is_deceased) {
                                    setFormValues(values);
                                    setIsConfirmModalVisible(!isConfirmModalVisible);
                                    setConfirmModalType('deceased patient');
                                    setEditing(false);
                                } else {
                                    handleOnFinish(values);
                                }
                            }}
                        >
                            {formJSX()}
                        </FormWrapper>
                    </div>
                }
                placement={placement}
            >
                {/* Note this is wrapped in a span to make the arrow come from the item center rather than the corner */}
                {/* TODO Figure out cleaner way of doing this */}
                <span>{children}</span>
            </Popover>
            <ConfirmModal
                title={'Mark Patient as Deceased'}
                visible={isConfirmModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                formValues={formValues}
            >
                {confirmModalContent}
            </ConfirmModal>
			<ReconfirmModal 
				title={"Just Double Checking..."} 
				visible={isReconfirmModalVisible} 
				handleOk={handleReconfirmOk} 
				handleCancel={handleReconfirmCancel
			}>
				{reconfirmModalContent}
			</ReconfirmModal>
        </>
    );
};

export default PopoverClosableForm;