import { INSTRUCTION_TYPES } from "utils/dataTypes";
import { roundTo } from "utils/formatFuncs";
import { EstimateFormFields } from "./billingTypes";

export type EstimateStatus = 'draft' | 'sent' | 'declined' | 'approved';

interface BaseEstimateItem {

    visit_id: number;
    type_id: INSTRUCTION_TYPES;
    start_time?: number;
    end_time?: number;
    discontinued_at: number | null;


    name: string;
    unit_cost_cents: number;
    notes: string | null;
    unit: string;
    is_free: boolean;
    

    quantity: number;

    supplemental_cost_cents: number | null;
    is_recurring_supplemental: boolean | null;
    is_shown_on_tx_sheet: boolean;
    is_ordered: boolean;
    is_processed: number | null;
    serial?: boolean;  
    serial_hours?: number | null;  
}

export interface DiagnosticEstimateItemNew extends BaseEstimateItem {
    instruction_id: number;

    type_id: 'D';

    frequency: string;
    diagnostic_id: number;
    name: string;

    is_prn: boolean;
    prn_condition: string | null ;
    serial: boolean;
    serial_hours: number | null;

    priority: boolean;

};

export const isInstanceOfDiagnosticEstimateItemNew = (
    item: any,
): item is DiagnosticEstimateItemNew =>
    item.diagnostic_id !== undefined && item.type_id === "D";

export interface TaskEstimateItemNew extends BaseEstimateItem {
    instruction_id: number;

    type_id: 'T';

    frequency: string;
    task_id: number;
    name: string;

    is_prn: boolean;
    prn_condition: string | null ;
    serial: boolean;
    serial_hours: number | null;


    priority: boolean;
};

export const isInstanceOfTaskEstimateItemNew= (
    item: any,
): item is TaskEstimateItemNew =>
    item.task_id !== undefined && item.type_id === "T";

interface APIFluids {
    rate_mcl_per_hr: number | undefined //Rate of FLUID, sent to database
}

interface FrontendFluids {
    rate_ml_per_hr: number | undefined //Rate of FLUID infusion, set on pump
}

export interface APIFluidEstimateItemNew extends BaseEstimateItem, APIFluids {
    instruction_id: number;

    type_id: 'F';

    fluids_id: number;
    route_id: 'IV' | 'IO';
    name: string;
    fluids_volume_ml: number;

    is_prn: boolean;
    prn_condition: string | null ;
    supplemental_id: number;
    priority: boolean;

};

export interface FluidEstimateItemNew extends BaseEstimateItem, FrontendFluids {
    instruction_id: number;

    type_id: 'F';

    fluids_id: number;
    route_id: 'IV' | 'IO';
    name: string;
    fluids_volume_ml: number;
    oxygen_quantity?: number;

    is_prn: boolean;
    prn_condition: string | null ;
    supplemental_id: number;
    priority: boolean;

};

export interface OxygenTherapyEstimateItemNew extends BaseEstimateItem {
    instruction_id: number;

    type_id: 'OT';

    fluids_id: number;
    name: string;
    oxygen_quantity: number;

    is_prn: boolean;
    prn_condition: string | null ;
    supplemental_id: number;
    priority: boolean;

};

export const isInstanceOfAPIFluidEstimateItemNew = (
    item: any,
): item is APIFluidEstimateItemNew =>
    item.fluids_id !== undefined && item.type_id === "F" &&
    !!(item as APIFluidEstimateItemNew).rate_mcl_per_hr;

export const isInstanceOfFluidEstimateItemNew = (
    item: any,
): item is FluidEstimateItemNew =>
    item.fluids_id !== undefined && item.type_id === "F" &&
    !!(item as FluidEstimateItemNew).rate_ml_per_hr;

export const isInstanceOfOxygenTherapyEstimateItemNew = (
    item: any,
): item is OxygenTherapyEstimateItemNew =>
    item.fluids_id !== undefined && item.type_id === "OT" &&
    !!(item as FluidEstimateItemNew).quantity;

export const backendFluidEstimateItemtoFrontend = (fluid:APIFluidEstimateItemNew): FluidEstimateItemNew => {
    const {rate_mcl_per_hr, ...restofFluid} = fluid
    return {
        ...restofFluid,
        rate_ml_per_hr: rate_mcl_per_hr ? roundTo(rate_mcl_per_hr / 1000, 2) : undefined
    }
}   
export const frontendFluidEstimateItemtoApi = (fluid:FluidEstimateItemNew): APIFluidEstimateItemNew => {
    const {rate_ml_per_hr, ...restofFluid} = fluid
	return {
		...restofFluid,
		rate_mcl_per_hr: rate_ml_per_hr ? roundTo(rate_ml_per_hr * 100, 0) * 10 : undefined
	}
}   

interface APICri {
    rate_mcl_per_hr: number | undefined //Rate of FLUID, sent to database
}

export interface FrontendCri {
    rate_ml_per_hr: number | undefined //Rate of FLUID infusion, set on pump
}

export interface APICriEstimateItemNew extends BaseEstimateItem, APICri {
    instruction_id: number;

    type_id: 'C';

    name: string;
    label: string;
    medication_id: number;
    dose: number;
    dose_unit: string;

    fluids_id: number | null;
    fluids_volume_ml: number | null;

    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;

    is_prn: boolean;
    prn_condition: string | null;
    controlled_drug: boolean;
};

export interface CriEstimateItemNew extends BaseEstimateItem, FrontendCri {
    instruction_id: number;

    type_id: 'C';

    name: string;
    label: string;
    medication_id: number;
    dose: number;
    dose_unit: string;

    fluids_id: number | null;
    fluids_volume_ml: number | null;

    latest_patient_weight_kg: number | null;
    approx_patient_weight_kg: number | null;

    is_prn: boolean;
    prn_condition: string | null ;
    default_cri_unit?: string | null;
    controlled_drug: boolean;
};

export const isInstanceOfAPICriEstimateItemNew = (
    item: any,
): item is APICriEstimateItemNew =>
    item.medication_id !== undefined && item.type_id === "C" &&
    !!(item as APICriEstimateItemNew).rate_mcl_per_hr;

export const isInstanceOfCriEstimateItemNew = (
    item: any,
): item is CriEstimateItemNew =>
    item.medication_id !== undefined && item.type_id === "C" &&
    !!(item as CriEstimateItemNew).rate_ml_per_hr;

export const backendCriEstimateItemtoFrontend = (fluid:APICriEstimateItemNew): CriEstimateItemNew => {
    const {rate_mcl_per_hr, ...restofFluid} = fluid
    return {
        ...restofFluid,
        rate_ml_per_hr: rate_mcl_per_hr ? roundTo(rate_mcl_per_hr / 1000, 2) : undefined
    }
}  

export const frontendCriEstimateItemtoApi = (fluid:CriEstimateItemNew): APICriEstimateItemNew => {
    const {rate_ml_per_hr, ...restofFluid} = fluid
	return {
		...restofFluid,
		rate_mcl_per_hr: rate_ml_per_hr ? roundTo(rate_ml_per_hr * 100, 0) * 10 : undefined
	}
}  
   
    
export interface MedicalEstimateItemNew extends BaseEstimateItem {
    instruction_id: number;

    type_id: 'M';

    frequency: string;
    medication_id: number;
    name: string;
    route_id: string;
    dose: number;
    dose_unit: string;

    approx_patient_weight_kg: number | null;
    latest_patient_weight_kg: number | null;

    is_prn: boolean;
    prn_condition: string | null;

    low_dose_alert?: number;
    high_dose_alert?: number;
    controlled_drug: boolean;

    serial: boolean;
    serial_hours: number | null;
};

export const isInstanceOfMedicalEstimateItemNew = (
    item: any,
): item is MedicalEstimateItemNew =>
    item.medication_id !== undefined && item.type_id === "M";

export interface ToGoMedEstimateItemNew extends BaseEstimateItem {
    instruction_id: number;

    type_id: 'TGH';

    medication_id: number;
    name: string;
    dose: number;
    dose_unit: string;

    dispense_unit: string,
    ratio: number
    dispense_value: number

    is_prn: boolean;
    prn_condition: string | null ;
    controlled_drug: boolean;

};

export const isInstanceOfToGoMedEstimateItemNew = (
    item: any,
): item is ToGoMedEstimateItemNew =>
    item.medication_id !== undefined && item.type_id === "TGH";

export interface  NonMedicalEstimateItemNew extends BaseEstimateItem {
    visit_id: number;
    type_id: 'N';
    
    non_medical_order_id: number;
    non_medical_id: number;
    name: string;

    unit_cost_cents: number;
    unit: string;

    is_free: boolean;
    
    reason:  string | null;
    has_reason: boolean;
};
    
export const isInstanceOfNonMedicalEstimateItemNew = (
    item: any,
): item is NonMedicalEstimateItemNew =>
    item.non_medical_order_id !== undefined && item.type_id === "N";

export type EstimateItemWithOutNonMedicalNew =
    | DiagnosticEstimateItemNew
    | TaskEstimateItemNew
    | FluidEstimateItemNew
    | CriEstimateItemNew
    | ToGoMedEstimateItemNew
    | MedicalEstimateItemNew
    | OxygenTherapyEstimateItemNew;

export type EstimateItemNew =
    | EstimateItemWithOutNonMedicalNew
    | NonMedicalEstimateItemNew;

export interface Estimate {
    estimate_id: number;
    form_link_id: string | null;
    estimate_status: EstimateStatus;
    approved_at: number | null;
    estimate_name: string | null;
    duration_hours: number;
    estimate_items : EstimateItemNew[]
}

export interface EstimateCreate {
    name: string | null;
    duration_hours: number;
    estimate_items : EstimateFormFields[]
    send?: boolean;
}

export interface MedicalDiscontinuedItems {
    instruction_id: number;
    unit: string;
    quantity: number;
    reason:  string | null;
    on_behalf_of: number;
    dose: number | null;
    type_id: string
    serial: boolean;

}
export interface NonMedicalDiscontinuedItems {
    non_medical_order_id: number;
    unit: string;
    quantity: number;
    reason: string | null;
    on_behalf_of: number;
    dose: number | null;
    type_id: "N"

}
export interface MedicalFreeItems {
    instruction_id: number;
    reason: string | null;
    type_id: string

}
export interface NonMedicalFreeItems {
    non_medical_order_id: number;
    reason: string | null;
    type_id: "N"

}
export type EstimateFreeItems = NonMedicalFreeItems | MedicalFreeItems

export type EstimateDiscontinuedItems = NonMedicalDiscontinuedItems | MedicalDiscontinuedItems
export interface EstimateEdit {
    name: string | null;
    new_items : EstimateFormFields[];
    discontinued_items: EstimateDiscontinuedItems[];
    free_items: EstimateFreeItems[];
    send?: boolean;
}