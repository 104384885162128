import {
    DownOutlined,
    ExclamationCircleOutlined,
    MoreOutlined,
    UpOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Modal,
    Row,
    Typography,
    message,
} from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from 'services/admissionService';
import { useGetUserDataQuery } from 'services/authService';
import { useVerballyApproveEstimateFormMutation } from 'services/consentService';
import {
    useDeclineEstimateMutation,
    useGetEstimateTaxQuery,
    useLazyGetLatestEstimateFormLinkQuery,
} from 'services/estimateService';
import {
    useCreateEstimateFormAndSendSMSMutation,
    useCreateEstimateFormMutation,
    useGetVisitByIdQuery,
} from 'services/visitService';
import { BASE_QUERY_OPTIONS } from 'utils/constants';
import { closedErrorMsg } from 'utils/disableFuncs';
import { appendRouteId, formatCurrency } from 'utils/formatFuncs';
import { getLedgerValues } from 'utils/miscFuncs';
import {
    CriEstimateFormFields,
    DiagnosticEstimateFormFields,
    EstimateFormFields,
    FluidEstimateFormFields,
    MedicineEstimateFormFields,
    NonMedicalEstimateFormFields,
    OxygenTherapyEstimateFormFields,
    TaskEstimateFormFields,
    ToGoMedEstimateFormFields,
    VisitDiscount,
} from 'utils/types/billingTypes';
import { ROUTES } from 'utils/types/enums';
import {
    Estimate,
    EstimateCreate,
    isInstanceOfCriEstimateItemNew,
    isInstanceOfDiagnosticEstimateItemNew,
    isInstanceOfFluidEstimateItemNew,
    isInstanceOfNonMedicalEstimateItemNew,
    isInstanceOfOxygenTherapyEstimateItemNew,
    isInstanceOfTaskEstimateItemNew,
    isInstanceOfToGoMedEstimateItemNew,
} from 'utils/types/estimateTypesNew';
import { EstimateTag } from '../EstimateTag';
import { SendEstimateModal } from '../SendEstimateModal';
import { calculateTotalEstimateCost } from '../utils';
import { BasicMenuItem, ConfirmDropDown, ConfirmDropdownItems, ConfirmMenuItem, MENU_DIVIDER } from './ConfirmDropdown';
import { instructionApi } from 'services/instructionApi';
import { useAppDispatch } from 'hooks/useRedux';
import { TagType } from 'utils/types/enums';

interface EstimateTableHeaderProps {
    estimate: Estimate;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    openEstimateModal: (
        estimate?: Estimate,
        newEstimate?: EstimateCreate,
    ) => void;
    visitDiscount?: VisitDiscount;
}
export const EstimateTableHeader = ({
    estimate,
    isExpanded,
    setIsExpanded,
    openEstimateModal,
    visitDiscount
}: EstimateTableHeaderProps) => {
    let estimateNameSection = <></>;
    if (estimate.estimate_name) {
        estimateNameSection = (
            <>
                <Col style={{ color: '#8C8C8C' }}>|</Col>
                <Col>{`${estimate.estimate_name}`}</Col>
            </>
        );
    }
    const totalCost = estimate.estimate_items.reduce(calculateTotalEstimateCost, 0);
    
    const basis = visitDiscount?.basis ?? 0;
    const { total } = getLedgerValues(totalCost, 0, 0, basis, visitDiscount?.cap_amount_cents);

    const { data: taxAmountCents } = useGetEstimateTaxQuery(estimate.estimate_id, { skip: !estimate?.estimate_id });

    return (
        <Row
            align='middle'
            justify='space-between'
            className='estimate-table-header'
        >
            <Col span={18}>
                <Row align='middle' gutter={8}>
                    <Col style={{ padding: '  0 var(--spacing-sm) ' }}>
                        {isExpanded ? (
                            <UpOutlined
                                onClick={() => setIsExpanded(!isExpanded)}
                            />
                        ) : (
                            <DownOutlined
                                onClick={() => setIsExpanded(!isExpanded)}
                            />
                        )}
                    </Col>
                    <Col>
                        <b>{`#${estimate.estimate_id}`}</b>
                    </Col>
                    <Col>{` (${estimate.duration_hours} hrs)`}</Col>
                    {estimateNameSection}
                    <Col>
                        <EstimateTag
                            status={estimate.estimate_status}
                            approvedDate={estimate.approved_at}
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify='end'>
                    <b>{formatCurrency(total + (taxAmountCents ?? 0))}</b>
                </Row>
            </Col>
            <Col span={1}>
                <Row justify='end'>
                    <EstimateTableHeaderDropdown
                        estimate={estimate}
                        openEstimateModal={openEstimateModal}
                    />
                </Row>
            </Col>
        </Row>
    );
};

interface EstimateTableHeaderDropdown {
    estimate: Estimate;
    openEstimateModal: (
        estimate?: Estimate,
        newEstimate?: EstimateCreate,
    ) => void;
}
const EstimateTableHeaderDropdown = ({
    estimate,
    openEstimateModal,
}: EstimateTableHeaderDropdown) => {
    const dispatch = useAppDispatch();
    const { estimate_status } = estimate;

    const { urlVisitId } = useParams<{ urlVisitId: string }>();
    const visitId = parseInt(urlVisitId);

    const { data: currentVisit } = useGetVisitByIdQuery(
        visitId,
        BASE_QUERY_OPTIONS,
    );
    const isVisitFinalized = Boolean(currentVisit?.finalized_at);

    const [isOpen, setIsOpen] = useState(false);

    const { data: existingCustomer } = useGetCustomerByIdQuery(
        currentVisit?.customer_id ?? '',
        BASE_QUERY_OPTIONS,
    );

    const { data: loggedInUserData } = useGetUserDataQuery(
        null,
        BASE_QUERY_OPTIONS,
    );

    const [getLatestEstimateFormLink, { data: latestEstimateFormLink }] =
        useLazyGetLatestEstimateFormLinkQuery();
    useEffect(() => {
        if (estimate.estimate_status === 'sent') {
            getLatestEstimateFormLink({
                visitId,
                estimateId: estimate.estimate_id,
            });
        }
    }, [estimate, visitId]);

    const [declineEstimatePost] = useDeclineEstimateMutation();
    const [verballyApproveEstimatePost] = useVerballyApproveEstimateFormMutation();
    const [createAndSendEstimateForm] =
        useCreateEstimateFormAndSendSMSMutation();

    const [createEstimateForm] = useCreateEstimateFormMutation();

    // These functions will expand once the backend is hooked up
    const copyEstimate = () => {
        if (isVisitFinalized) {
            return closedErrorMsg(isVisitFinalized);
        }
        const name = estimate.estimate_name
            ? `${estimate.estimate_name} copy`
            : null;
        const estimate_items: EstimateFormFields[] = estimate.estimate_items
            .filter((est) => est.quantity > 0)
            .map((est) => {
                const timestamp =  Math.floor(Math.random() * 1000000000);
                const baseFields = {
                    is_free: false,
                    unit: est.unit,
                    name: est.name,
                    quantity: est.quantity,
                    unit_cost_cents: est.unit_cost_cents,
                    reason: null,
                    timestamp,
                };
                if (isInstanceOfNonMedicalEstimateItemNew(est)) {
                    const estimateItem: NonMedicalEstimateFormFields = {
                        non_medical_id: est.non_medical_id,
                        type_id: 'N',
                        supplemental_cost_cents: null,
                        is_recurring_supplemental: null,
    
                        ...baseFields,
                    };
                    return estimateItem;
                } else if (isInstanceOfDiagnosticEstimateItemNew(est)) {
                    const estimateItem: DiagnosticEstimateFormFields = {
                        frequency: est.frequency,
                        diagnostic_id: est.diagnostic_id,
                        type_id: 'D',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        notes: null,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        serial: est.serial,
                        serial_hours: est.serial_hours,
                        ...baseFields,
                    };
                    return estimateItem;
                } else if (isInstanceOfCriEstimateItemNew(est)) {
                    const estimateItem: CriEstimateFormFields = {
                        medication_id: est.medication_id,
                        type_id: 'C',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        label: est.label,
                        dose: est.dose,
                        dose_unit: est.dose_unit,
                        rate_ml_per_hr: est.rate_ml_per_hr ?? 0,
                        fluids_id: est.fluids_id,
                        fluids_volume_ml: est.fluids_volume_ml,
                        latest_patient_weight_kg: est.approx_patient_weight_kg,
                        approx_patient_weight_kg: est.latest_patient_weight_kg,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        notes: null,
                        controlled_drug: est.controlled_drug,
                        ...baseFields,
                    };
                    return estimateItem;
                } else if (isInstanceOfTaskEstimateItemNew(est)) {
                    const estimateItem: TaskEstimateFormFields = {
                        frequency: est.frequency,
                        task_id: est.task_id,
                        type_id: 'T',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        notes: null,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        serial: est.serial,
                        serial_hours: est.serial_hours,
                        ...baseFields,
                    };
                    return estimateItem;
                } else if (isInstanceOfFluidEstimateItemNew(est)) {
                    const estimateItem: FluidEstimateFormFields = {
                        fluids_id: est.fluids_id,
                        type_id: 'F',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        route_id: est.route_id,
                        fluids_volume_ml: est.fluids_volume_ml,
                        rate_ml_per_hr: est.rate_ml_per_hr ?? null,
                        notes: null,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        ...baseFields,
                    };
                    return estimateItem;
                } else if (isInstanceOfOxygenTherapyEstimateItemNew(est)) {
                    const estimateItem: OxygenTherapyEstimateFormFields = {
                        fluids_id: est.fluids_id,
                        type_id: 'OT',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        oxygen_quantity: est.oxygen_quantity,
                        notes: null,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        ...baseFields,
                    };
                    return estimateItem;
                } else if (isInstanceOfToGoMedEstimateItemNew(est)){
                    const estimateItem: ToGoMedEstimateFormFields = {
                        medication_id: est.medication_id,
                        type_id: 'TGH',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        dispense_value: est.dispense_value,
                        dispense_unit:  est.dispense_unit,
                        ratio: est.ratio,
                        dose_unit: est.dose_unit,
                        notes: null,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        instructions: null,
                        next_dose: null,
                        controlled_drug: est.controlled_drug,
                        ...baseFields,
                    };
                    return estimateItem;

                } else {
                    const estimateItem: MedicineEstimateFormFields = {
                        frequency: est.frequency,
                        medication_id: est.medication_id,
                        type_id: 'M',
                        is_prn: est.is_prn,
                        prn_condition: est.prn_condition,
                        priority: false,
                        ordered_by: loggedInUserData?.user_id ?? 0,
                        route_id: est.route_id,
                        dose: est.dose,
                        dose_unit: est.dose_unit,
                        approx_patient_weight_kg: est.approx_patient_weight_kg,
                        latest_patient_weight_kg: est.latest_patient_weight_kg,
                        notes: null,
                        supplemental_cost_cents: est.supplemental_cost_cents,
                        is_recurring_supplemental: est.is_recurring_supplemental,
                        controlled_drug: est.controlled_drug,
                        serial: est.serial,
                        serial_hours: est.serial_hours,
                        ...baseFields,
                    };
                    return estimateItem;
                }
            });
        const newEstimate: EstimateCreate = {
            name: name,
            duration_hours: estimate.duration_hours,
            estimate_items,
        };
        openEstimateModal(undefined, newEstimate);
    };
    const declineEstimate = () => {
        if (isVisitFinalized) {
            return closedErrorMsg(isVisitFinalized);
        }
        declineEstimatePost({ visitId, estimateId: estimate.estimate_id });
    };

    const shareEstimate = () => {
        if (isVisitFinalized) {
            return closedErrorMsg(isVisitFinalized);
        }
        createAndSendEstimateForm({
            visitId,
            estimateId: estimate.estimate_id,
        }).then(() => {
            dispatch(instructionApi.util.invalidateTags([{ type: TagType.Estimate, id: visitId}]))
        });
    };

    const verballyApproveEstimate =  () => {
        const success = () => message.success('Estimate #' + estimate.estimate_id + ' has been marked verbally approved.');

        if (estimate.estimate_status === 'draft'){
            createEstimateForm({visitId, estimateId: estimate.estimate_id}).then(
                res => {
                    if ('data' in res){
                        return res.data
                    }
                }
            ).then(
                (data) => {
                    verballyApproveEstimatePost({FormId: String(data)}).then(
                        () => {
                            success();
                        }
                    ).then(() => {
                        dispatch(instructionApi.util.invalidateTags([{ type: TagType.Estimate, id: visitId}]))
                    })
                }
            )
        } else {
            if (latestEstimateFormLink?.form_link_id) {
                verballyApproveEstimatePost(
                    {FormId: String(latestEstimateFormLink?.form_link_id)}
                ).then(
                    () => {
                        success();
                    }
                ).then(() => {
                    dispatch(instructionApi.util.invalidateTags([{ type: TagType.Estimate, id: visitId}]))
                })
            }
        }
    }


    const viewEstimateForm = () => {
        const cypressTestElement = document.getElementById("cypressTag");
        let cypressTestElementClassName = cypressTestElement?.getAttribute("class");

        if (estimate.estimate_status === 'draft'){
            createEstimateForm({visitId, estimateId: estimate.estimate_id}).then(
                res => {
                    if ('data' in res){
                        if (cypressTestElementClassName === "cypressRun") {
                            window.open(
                                appendRouteId(
                                    ROUTES.consentForms,
                                    res.data,
                                ),
                                "_self"
                            )
                        } else {
                            const url = appendRouteId(
                                ROUTES.consentForms, res.data
                            );
                            if (cypressTestElementClassName !== 'cypressRun') {
                                window.open(url, '_blank');
                            }
                        }
                    }
                }
            )
        } else if (estimate.estimate_status === 'approved') {
            window.open(`/printEstimate/${visitId}/${estimate.form_link_id}`);
        } else {
            if (latestEstimateFormLink?.form_link_id) {
                if (cypressTestElementClassName === "cypressRun") {
                    window.open(
                        appendRouteId(
                            ROUTES.consentForms,
                            String(latestEstimateFormLink?.form_link_id),
                        ),
                        "_self"
                    )
                } else {
                    const url = appendRouteId(
                        ROUTES.consentForms, String(latestEstimateFormLink?.form_link_id)
                    );
                    if (cypressTestElementClassName !== 'cypressRun') {
                        window.open(url, '_blank');
                    }
                }
            }

        }
    }

    let menuItems: ConfirmDropdownItems[] = []

    const viewMenuItem: BasicMenuItem = {
        text: "View Consent Form",
        onClick: viewEstimateForm,
    }
    
    const verballyApproveMenuItem: ConfirmMenuItem = {
        text: "Verbally Approve",
        confirmProps: {
            placement:"left",
            title:"Are you sure you want to mark this estimate approved?",
            onConfirm: verballyApproveEstimate,
            okButtonProps: {
                autoFocus: true
            }
        },
    }
    const copyMenuItem: BasicMenuItem = {
        text: "Copy Estimate",
        onClick: copyEstimate,
    }
    const shareMenuItem: BasicMenuItem = {
        text: "Share",
        onClick: () => setIsOpen(true),
        menuItemProps: {
            disabled: existingCustomer === undefined,
        }
    }
    const decline: BasicMenuItem = {
        text: (
            <Typography.Text style={{ color: '#FF4D4F' }}>
                Decline
            </Typography.Text>
        ),
        onClick: () => declineModal(false, declineEstimate),
    };
    const declineAndCopy: BasicMenuItem = {
        text: (
            <Typography.Text style={{ color: '#FF4D4F' }}>
                Decline and Copy{' '}
            </Typography.Text>
        ),
        onClick: () => {
            declineModal(true, () => {
                declineEstimate();
                copyEstimate();
            });
        }
    };

    if (estimate_status === 'draft') {
        const editEstimateMenuItem: BasicMenuItem = {
            text: "Edit Estimate",
            onClick: () => openEstimateModal(estimate),
        }
        menuItems = [
            editEstimateMenuItem,
            viewMenuItem,
            verballyApproveMenuItem,
            shareMenuItem,
            MENU_DIVIDER,
            copyMenuItem,
            MENU_DIVIDER,
            decline,
            declineAndCopy,
        ]
    } else if (estimate_status === 'sent') {
        menuItems = [
            viewMenuItem,
            verballyApproveMenuItem,
            shareMenuItem,
            MENU_DIVIDER,
            copyMenuItem,
            MENU_DIVIDER,
            decline,
            declineAndCopy,
        ]
    } else if (estimate_status === 'approved') {
        menuItems = [
            copyMenuItem,
            MENU_DIVIDER,
            viewMenuItem,
            MENU_DIVIDER,
            decline,
            declineAndCopy,
        ]
    } else {
        menuItems = [copyMenuItem]
    }

    return (
        <>
            <ConfirmDropDown items={menuItems}>
                <Button icon={<MoreOutlined />} data-cy={'estimateHeaderDropdownButton'}/>
            </ConfirmDropDown>
            <SendEstimateModal
                onOk={shareEstimate}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contact={existingCustomer}
            />
        </>
    );
};

const declineModal = (copy: boolean, onOk: () => void) => {
    let title, body;
    if (copy) {
        title = 'Decline and Copy Estimate';
        body =
            'This estimate will no longer be accepted and a new estimate will be created with the current contents which can then be adjusted and re-shared.';
    } else {
        title = 'Decline Estimate';
        body =
            'By declining the estimate, the customer can no longer accept the estimate agreement.';
    }
    Modal.confirm({
        icon: <ExclamationCircleOutlined style={{ color: '#CF1322' }} />,
        title: title,
        maskClosable: false,
        onOk: () => {
            onOk();
        },
        okText: 'Decline',
        cancelText: 'Cancel',
        centered: true,
        autoFocusButton: "ok",
        content: (
            <>
                <Typography.Paragraph>{body}</Typography.Paragraph>
                <Typography.Paragraph>
                    Are you sure you want to decline this estimate?
                </Typography.Paragraph>
            </>
        ),
    });
};
