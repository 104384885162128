import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Cascader, Form, FormInstance, Modal } from 'antd';
import useCloseVisit from 'hooks/useCloseVisit';
import { CascaderValueType, CascaderOptionType } from 'antd/lib/cascader';
import { useGetUserDataQuery } from 'services/authService';
import {
    useDischargePatientInstructionsMutation,
    useLazyGetInstructionsByVisitIdQuery,
    usePostCheckinTprInstructionsMutation,
} from 'services/instructionService';
import { useGetPatientByIdQuery, useMarkPatientDeceasedMutation } from 'services/patientService';
import {
    useFinalizeVisitMutation,
    useGetVisitByIdQuery,
    useTransitionStatusMutation,
} from 'services/visitService';
import {
    VISIT_STATUS_OPTIONS,
    VISIT_STATUS_TRANSITION_MAP,
} from 'utils/constants';
import { ExpandedPatient, HOSPITALIZATION_LEVELS, VISIT_STATUSES } from 'utils/dataTypes';
import { DASHBOARD_STATUS_MAP } from 'utils/stringFormatting';
import {
    checkIfUserHasNecessaryPermission,
    USER_PERMISSIONS,
} from 'utils/userPermissions';
import {
    PromptPatientDeceasedDateModal,
    reviewAndFinalizeFlow, reviewAndFinalizeFlowRelock, showDischargeModal,
} from '../DischargeTab/utils';
import { useLazyDownloadDischargeDocQuery } from 'services/taskService';
import moment from 'moment';
import {
    useGetBillingDataQuery,
    useGetVisitDiscountQuery,
    useLazyGetBillingDataQuery,
    useLazyGetVisitDiscountQuery,
} from 'services/billingService';
import { getIsInvoiceLocked, getLedgerValues } from 'utils/miscFuncs';
import { filterFluidsCri } from 'utils/filterFuncs';

const { confirm } = Modal;

interface VisitStatusTransitionWidgetProps {
    visit_id: number;
    visit_status: VISIT_STATUSES;
    hospitalization_level?: HOSPITALIZATION_LEVELS;
    overrideTransitionMap?: boolean;
    modalForm: FormInstance<any>;
    setModalState: Function;
    patient: ExpandedPatient;
}

export const VisitStatusTransitionWidget = ({
    visit_id,
    visit_status,
    hospitalization_level,
    overrideTransitionMap,
    modalForm,
    setModalState,
    patient,
}: VisitStatusTransitionWidgetProps) => {
    const [transitionStatus] = useTransitionStatusMutation();
    const [postCheckinTprInstructions] =
        usePostCheckinTprInstructionsMutation();
    const [finalizeVisit] = useFinalizeVisitMutation();
    const [confirmCloseVisit] = useCloseVisit({
        visitId: visit_id,
        modalForm,
        setModalState,
    });
    const [getInstructions] = useLazyGetInstructionsByVisitIdQuery();
    
    const [downloadDischargeDoc] = useLazyDownloadDischargeDocQuery();

    const { data: billingData, refetch: refetchBillingData  } = useGetBillingDataQuery(
        {visitId: visit_id, isRefresh: false},
    );

    const [getBillingDataLazy] = useLazyGetBillingDataQuery();
    const [getVisitDiscountLazy] = useLazyGetVisitDiscountQuery();

    const { data: visitDiscount, refetch: refetchVisitDiscount } = useGetVisitDiscountQuery(
        visit_id
    );

    const { total } = getLedgerValues(
        billingData?.subtotal,
        billingData?.amount_paid,
        billingData?.tax,
        visitDiscount?.basis,
        visitDiscount?.cap_amount_cents,
    )

    const [dischargePatientInstructions] =
		useDischargePatientInstructionsMutation();
    
    const [markPatientDeceased] = useMarkPatientDeceasedMutation();

    const [form] = Form.useForm();

    const onFinishReviewAndFinalize = (ids: number[]) => {
        return dischargePatientInstructions({
            visitId: visit_id,
            ids: ids
        })
        .unwrap()
        .then((resp) => {
            transitionStatus({ visitId: visit_id, status: 'discharged' })
                .unwrap().then(() => {
                    if (resp.linked_actions.map(item => item.identifier).includes('prompt_for_deceased_at')) {
                        PromptPatientDeceasedDateModal(form, patient.pet_id, patient.name, markPatientDeceased, moment(patient.birthday, 'YYYY-MM-DD'));
                    }
                });
            const cypressTestElement = document.getElementById("cypressTag");
            let cypressTestElementClassName = cypressTestElement?.getAttribute("class");
            if (cypressTestElementClassName !== 'cypressRun') {
                downloadDischargeDoc({ visitId: visit_id });
            }
        })
        .catch((err) => {
            confirm({
                title: 'Unable to Discharge',
                content: err.data,
                cancelButtonProps: { hidden: true }
            });
        });
    };

    const { data: loggedInUserData } = useGetUserDataQuery(null);
    const { data: currentVisit } = useGetVisitByIdQuery(visit_id);
    const { data: patientData } = useGetPatientByIdQuery(
        currentVisit?.pet_id ?? '',
        { skip: !currentVisit?.pet_id },
    );

    const [discontinuePatientInstructions] = useDischargePatientInstructionsMutation();

    const userHasPermissionToCloseVisit = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.visit_close,
    );

	const displayRender = (labels: string[], selectedOptions?: CascaderOptionType[]) => {
		if (selectedOptions) {
			return labels.map((label, i) => {
				const option = selectedOptions[i];
				const separator = (i === labels.length - 1) ? "" : " / ";
				return <span key={option.value}>{option.shortLabel ?? label}{separator}</span>;
			});
		}

		return null;
	}

	const getStatusTransitionOptions = () => {
		const hospitalization_levels = [
			{
				value: 'Observation',
				label: 'Level 1 - Observation',
				shortLabel: 'Observation',
			},
			{
				value: 'Standard',
				label: 'Level 2 - Standard',
				shortLabel: 'Standard',
			},
			{
				value: 'Critical',
				label: 'Level 3 - Critical',
				shortLabel: 'Critical',
			},
		];

        const status = VISIT_STATUS_OPTIONS.map((status) => {
			return {
				value: status,
				label: DASHBOARD_STATUS_MAP[status],
				disabled:
					!overrideTransitionMap &&
					!VISIT_STATUS_TRANSITION_MAP[visit_status].includes(status),
				key: `status_transition_visit_${visit_id}_${status}`,
				children:
					DASHBOARD_STATUS_MAP[status] === 'Hospitalized'
						? hospitalization_levels
						: [],
			};
		});

		return [
			...status,
			{
				value: 'close',
				label: 'Close',
				key: `status_transition_visit_close`,
				disabled: !['checked_out', 'noshow'].includes(visit_status) || !userHasPermissionToCloseVisit,
			},
		];
	};

    const showConfirmCloseChartOnNoShow = (status: VISIT_STATUSES) => {
        confirm({
            title: 'Change Status to No Show',
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <p data-cy="markAsNoShowModalTitle">Marking this visit as a “No Show” will...</p>
                    <ol style={{ paddingLeft: '16px' }}>
                        <li>Automatically Close the visit</li>
                        <li>Remove option to email records</li>
                        <li>Remove option to send surveys</li>
                    </ol>
                    <p>
                        Are you sure you want to change {patientData?.name}’s
                        visit as a “No Show”?
                    </p>
                </>
            ),
            okText: 'Yes, Change Status',
            onOk() {
                finalizeVisit({ visitId: visit_id });
                transitionStatus({
                    status: status,
                    visitId: visit_id,
                });
            },
        });
    };

	return (
        <>
            <Cascader
                popupClassName='visit-status-selector'
                value={[visit_status, hospitalization_level ?? '']}
                allowClear={false}
                style={{ width: '100%' }}
                options={getStatusTransitionOptions()}
                displayRender={displayRender}
                data-cy="visitStatusDropdown"
                onChange={(value: CascaderValueType) => {
                    const status = value[0] as string;
                    const hospitalization_level = value[1] as
                        | HOSPITALIZATION_LEVELS
                        | undefined;

                    if (status === 'close') {
                        confirmCloseVisit();
                        return;
                    }

                    if (status === 'noshow') {
                        showConfirmCloseChartOnNoShow(status);
                        return;
                    }

                    if (status === 'arrived') {
                        postCheckinTprInstructions({ visitId: visit_id });
                    }

                    if (status === 'discharged') {
                        const isInvoiceFinalized = Boolean(currentVisit?.invoice_finalized_at);
                        const isInvoiceLocked = getIsInvoiceLocked(currentVisit?.invoice_finalized_at, currentVisit?.invoice_unlocked_at)

                        getInstructions({visitId: visit_id}).then((response)=>{
                            const ongoingInstructions = response.data?.filter(
                                (instruction) =>
                                    !instruction.discontinued_at && instruction.type_id !== 'TGH',
                            );

                            const fluidCriOxygenInstructions = ongoingInstructions?.filter(filterFluidsCri) ?? [];

                            const discontinueCriAndFluidInstructions = () => {
                                return discontinuePatientInstructions({
                                    visitId: visit_id,
                                    ids: fluidCriOxygenInstructions?.filter(instruction => instruction.discontinued_at === null).map((instruction) => instruction.id) ?? [],
                                }).then((resp) => {
                                    if ("data" in resp) {
                                        refetchBillingData();
                                        refetchVisitDiscount();
                                    }
                                }
                                );
                            };

                            if (!isInvoiceFinalized) {
                                reviewAndFinalizeFlow(
                                    ongoingInstructions,
                                    discontinueCriAndFluidInstructions,
                                    patientData!.name,
                                    onFinishReviewAndFinalize,
                                    getBillingDataLazy,
                                    getVisitDiscountLazy,
                                    visit_id,
                                    null,
                                );
                            }

                            if (isInvoiceFinalized && !isInvoiceLocked) {
                                reviewAndFinalizeFlowRelock(
                                    ongoingInstructions,
                                    discontinueCriAndFluidInstructions,
                                    onFinishReviewAndFinalize,
                                    getBillingDataLazy,
                                    getVisitDiscountLazy,
                                    visit_id,
                                    (isRelocking: boolean) => {},
                                    patientData!.name,
                                )
                            }

                            if (isInvoiceFinalized && isInvoiceLocked) {
                                showDischargeModal(
                                    ongoingInstructions,
                                    onFinishReviewAndFinalize,
                                );
                            }
                        });
                        return;
                    }

                    transitionStatus({
                        status: status as VISIT_STATUSES,
                        hospitalization_level,
                        visitId: visit_id,
                    });
                }}
                placeholder='Please select'
            />
        </>
    );
};
