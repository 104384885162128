import { CheckOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { PackageDiagnosticInstruction } from 'utils/types/InstructionOrderTypes';
import OrderFrequency from 'views/visit/TreatmentSheet/OrderFrequency';
import { PackageFormInstance } from '.';
import { HiddenInput } from '../fields/HiddenInput';
import EditLineButton from './EditLineButton';
import FrequencyFormItem from './FrequencyFormItem';
import InstructionFormItem from './InstructionFormItem';
import StartEndTimeFormItems from './StartEndTimeFormItems';
import { checkIfSerialCheaper } from 'utils/formFuncs';
import AlertSerialPrice from 'components/alerts/AlertSerialPrice';

interface DiagnosticsFormProps {
    diagnostic: PackageDiagnosticInstruction;
    form: FormInstance<PackageFormInstance>;
    index: number;
    hiddenSectionClass?: string;
    fromEstimate?: boolean;
    durationHours?: number;
    setActiveLineItem: (item: string) => void;
    activeLineItem: string;
}

const DiagnosticsForm: React.FC<DiagnosticsFormProps> = ({
    diagnostic,
    form,
    hiddenSectionClass,
    index,
    fromEstimate,
    durationHours,
    setActiveLineItem,
    activeLineItem,
}) => {
    const [hasError, setHasError] = useState(false);
    const lineId = `D-${index}`;
    const isEditing = activeLineItem === lineId || hasError;

    const diagFields = [
        ['diagnostics', index, 'frequency'],
        ['diagnostics', index, 'end_time'],
        ['diagnostics', index, 'start_time'],
        ['diagnostics', index, 'instruction'],
    ];
    const diagsErrorArray = form.getFieldsError(diagFields);

    const [isSerialCheaper, setIsSerialCheaper] = useState(false)


    const setSerial = (changes: {[key in string]: string}) => {     
        const fieldsValue = form.getFieldsValue();
        const diagnosticFields = {
            ...fieldsValue.diagnostics[index],
            ...changes
        }
        let startTime, endTime;
        if (fromEstimate) {
            startTime = 0
            endTime = (durationHours ?? 0) * 3600
    
        } else {
            startTime = (diagnosticFields?.start_time ?? fieldsValue.start_time)?.unix()
            endTime = (diagnosticFields?.end_time ?? fieldsValue.end_time)?.unix()  
        }
        
        const serial = checkIfSerialCheaper(diagnostic, diagnosticFields.frequency, startTime,endTime)
        const unit_cost_cents = serial ? diagnostic.price_cents_serial ?? 0 : diagnostic.cents ?? 0;

        setIsSerialCheaper(serial)

        form.setFieldsValue({
            ...fieldsValue,
            diagnostics: fieldsValue.diagnostics.map((diag: any, indx: number) => {
                return indx === index ? { ...diag, ...changes, serial, unit_cost_cents } : diag;
            }),
        });

    }


    const handleShouldUpdate = (prev: PackageFormInstance, curr: PackageFormInstance) => {
        return (
            prev.diagnostics[index].checked !== curr.diagnostics[index].checked ||
            prev.diagnostics[index].frequency !== curr.diagnostics[index].frequency ||
            prev.diagnostics[index].start_time !== curr.diagnostics[index].start_time ||
            prev.diagnostics[index].end_time !== curr.diagnostics[index].end_time
        );
    };

    useEffect(() => {
        if (isEditing) {
            form.getFieldInstance(['diagnostics', index, 'frequency'])?.focus();
        }
    }, [isEditing, form, index]);

    useEffect(() => {
        if (diagsErrorArray.some((e) => e.errors.length)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [form, diagsErrorArray]);

    useEffect(() => { 
        setSerial({})
    }, [])

    return (
        <Form.Item shouldUpdate={handleShouldUpdate} noStyle>
            {({ getFieldValue }) => {
                const checked =
                    getFieldValue(['diagnostics', index, 'checked']) ??
                    (diagnostic.required ? diagnostic.required : diagnostic.toggled) ??
                    true;
                const disabledClass = !checked ? 'disabled-line' : '';
                const initialError = !(getFieldValue(['diagnostics', index, 'frequency']) ?? diagnostic.frequency);
                const serialName = isSerialCheaper ? `${diagnostic.name} - Serial ${diagnostic.serial_hours} Hours` :   diagnostic.name;

                return (
                    <div
                        className={`grid-table__row ${hiddenSectionClass} ${disabledClass}`}
                        onClick={() => {
                            if (!isEditing) setActiveLineItem(lineId);
                        }}
                    >
                        <div style={{ display: 'none' }}>
                            <HiddenInput fieldName={['diagnostics', index, 'id']} initialValue={diagnostic.diagnostic_id} />
                            <HiddenInput fieldName={['diagnostics', index, 'unit_cost_cents']} initialValue={diagnostic.cents} />
                            <HiddenInput fieldName={['diagnostics', index, 'name']} initialValue={diagnostic.name} />
                            <HiddenInput fieldName={['diagnostics', index, 'required']} initialValue={diagnostic.required} />
                            <HiddenInput fieldName={['diagnostics', index, 'serial']} initialValue={isSerialCheaper} />
                            <HiddenInput fieldName={['diagnostics', index, 'serial_hours']}  initialValue={diagnostic.serial_hours} />

                        </div>

                        <Form.Item
                            className='grid-table__sticky-column'
                            initialValue={diagnostic.required ? diagnostic.required : diagnostic.toggled}
                            valuePropName='checked'
                            name={['diagnostics', index, 'checked']}
                        >
                            {diagnostic.required ? (
                                <Tooltip title='Required order. Cannot be removed.'>
                                    <CheckOutlined />
                                </Tooltip>
                            ) : (
                                <Checkbox />
                            )}
                        </Form.Item>

                        <div className='grid-table__sticky-column'>
                            <Tooltip title={serialName.length > 45 ? serialName : null}>
                                <span className='treatment-sheet-side-column__title'>{serialName}</span>
                            </Tooltip>

                            {!fromEstimate && checked && (
                                <div className='diagnostic-data'>
                                    <OrderFrequency>
                                        {getFieldValue(['diagnostics', index, 'frequency']) ?? diagnostic.frequency}
                                    </OrderFrequency>
                                </div>
                            )}
                        </div>

                        {checked ? (
                            <>
                                <div className='empty-element' />
                                <div className='empty-element' />

                                <div className='package-modal__mobile-header'>
                                    <span style={{ color: 'var(--veg-red)' }}>* </span>
                                    Frequency
                                </div>
                                <FrequencyFormItem
                                    formFieldName={['diagnostics', index, 'frequency']}
                                    initialValue={diagnostic.frequency}
                                    isEditing={isEditing}
                                    formFieldValue={form.getFieldValue(['diagnostics', index, 'frequency']) ?? diagnostic.frequency}
                                    onFormChange={(changes) => {
                                        setSerial(changes)
                                    }}
                                />

                                {!fromEstimate && (
                                    <>
                                        <StartEndTimeFormItems
                                            endTimeFormFieldName={['diagnostics', index, 'end_time']}
                                            endTimeFormFieldValue={getFieldValue(['diagnostics', index, 'end_time'])}
                                            isEditing={isEditing}
                                            startTimeFormFieldName={['diagnostics', index, 'start_time']}
                                            startTimeFormFieldValue={getFieldValue(['diagnostics', index, 'start_time'])}
                                        />

                                        <InstructionFormItem
                                            formFieldName={['diagnostics', index, 'instruction']}
                                            initialValue={null}
                                            isEditing={isEditing}
                                            formFieldValue={form.getFieldValue(['diagnostics', index, 'instruction'])}
                                        />
                                    </>
                                )}
                                {isEditing && isSerialCheaper && 
                                    <div
                                        className={`serial-alert-column ${!isEditing || !isSerialCheaper ? 'serial-alert-column--hide' : ''}`}
                                    >
                                        <AlertSerialPrice serialHours={diagnostic.serial_hours ?? 0} />
                                    </div>
                                }

                                <EditLineButton
                                    disabled={isEditing}
                                    hasError={hasError || initialError}
                                    onClick={() => {
                                        if (!hasError) {
                                            setActiveLineItem(lineId);
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            <div className='disabled-line__form-inputs' />
                        )}
                    </div>
                );
            }}
        </Form.Item>
    );
};

export default DiagnosticsForm;
